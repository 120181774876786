//react
import React from "react";

//router
import { Link } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CourseSelected } from 'redux/actions';

//components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
//import Button from "components/CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";

class CourseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toCoursePage: false,
            backgroundImage: this.props.course.Photo.URL
        };
        
        this.getCoursePage = this.getCoursePage.bind(this);
      }


trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
}

getCoursePage() { 
    return "/course/" + this.props.course.id;
}

render() {
    const { classes } = this.props;
    //


    return (
        <GridItem xs={12} sm={6} md={4} lg={3}>
            <Link to={this.getCoursePage()}>
            <Card>
                <div style={{backgroundSize: "cover", height:"200px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                    backgroundImage:`url(${this.state.backgroundImage})`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}>
                </div>
                <CardBody>
                    <h4 className={classes.cardTitle}>
                        {this.trim(this.props.course.English,31)}
                    </h4>
                    <p className={classes.description} style={{minHeight: "123px" }}>
                        {this.trim(this.props.course.Description,500)}
                    </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                    
                </CardFooter>
            </Card>
            </Link>
        </GridItem>
    )}
}

const mapStateToProps = state => {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({onCourseSelected: CourseSelected }, dispatch);  
}

CourseCard = connect(mapStateToProps, mapDispatchToProps)(CourseCard);
export default withStyles(cardStyle)(CourseCard);
