
import AltLoginPage from "../views/AltLoginPage/AltLoginPage.jsx";
import BlogPage from "../views/BlogPage/BlogPage.jsx";
import BlogPost from "../views/BlogPostPage/BlogPostPage.jsx";
import CoursePage from "../views/CoursePage/CoursePage.jsx";
import CoursesPage from "../views/CoursesPage/CoursesPage.jsx";
import DictionaryPage from "../views/DictionaryPage/DictionaryPage.jsx";
import HSKPage from "../views/HSKPage/HSKPage.jsx";
import LandingPage from "../views/LandingPage/LandingPage.jsx";
import LessonPage from "../views/LessonPage/LessonPage.jsx";
import LessonsPage from "../views/LessonsPage/LessonsPage.jsx";
import LoginPage from "../views/LoginPage/LoginPage.jsx";
import LogoutPage from "../views/LogoutPage/LogoutPage.jsx";
import PracticeDecksPage from "../views/PracticeDecksPage/PracticeDecksPage.jsx";
import PrivacyPage from "../views/PrivacyPage/PrivacyPage.jsx";
import ProfilePage from "../views/ProfilePage/ProfilePage.jsx";
import SearchPage from "../views/SearchPage/SearchPage.jsx";
import SignupPage from "../views/SignupPage/SignupPage.jsx";
import TermsPage from "../views/TermsPage/TermsPage.jsx";
import TempPage from "../views/TempPage/TempPage.jsx";

var indexRoutes = [
  { path: "/gavilanPeak", name: "AltLoginPage", component: AltLoginPage },
  { path: "/profile", name: "ProfilePage", component: ProfilePage },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/logout", name: "LogoutPage", component: LogoutPage },
  { path: "/signup", name: "SignupPage", component: SignupPage },
  { path: "/privacy", name: "PrivacyPage", component: PrivacyPage },
  { path: "/search", name: "SearchPage", component: SearchPage },
  { path: "/blog", name: "Blog", component: BlogPage },
  { path: "/blogpost/:id", name: "BlogPost", component: BlogPost },
  { path: "/course/:id", name: "CoursePage", component: CoursePage },
  { path: "/courses", name: "CoursesPage", component: CoursesPage },
  { path: "/lesson/:id", name: "LessonPage", component: LessonPage },
  { path: "/lessons", name: "LessonsPage", component: LessonsPage },
  { path: "/decks", name: "PracticeDecksPage", component: PracticeDecksPage },
  { path: "/dictionary", name: "DictionaryPage", component: DictionaryPage },
  { path: "/hsk", name: "HSKPage", component: HSKPage },
  { path: "/terms", name: "TermsPage", component: TermsPage },
  { path: "/landing", name: "LandingPage", component: LandingPage },
  { path: "/", name: "TempPage", component: TempPage }
];

export default indexRoutes;
