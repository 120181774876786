//react
import React from "react";

//router
import { Link } from "react-router-dom";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";

class BlogPostCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toBlogPostPage: false
        };

        db.getPhoto(this.props.blogPost.PhotoID).then((photo) => {
            if(photo) {
                this.setState({backgroundImage: photo.URL, opacity: 1});
            }
        })
        
        this.getBlogPostPage = this.getBlogPostPage.bind(this);
    }


trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
}

getBlogPostPage() { 
    return "/blogpost/" + this.props.blogPost.id;
}

render() {
    
    return (
        <GridItem xs={12} sm={6} md={4} lg={4}>
            <Link to={this.getBlogPostPage()}>
            <Card>
                    <CardHeader style={{ backgroundImage:`url(${this.state.backgroundImage})`, height:"217px", borderRadius:"10px", backgroundRepeat:"no-repeat", backgroundSize:"cover",boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"}}>

                    </CardHeader>
                    <CardBody style={{textAlign:'justify'}}>
                        <div style={{color:"#498e30",fontSize:"13px",fontWeight:"400",marginTop:"17px"}}>{this.props.blogPost.Category}</div>
                        <div style={{fontFamily:"Roboto Slab", fontWeight:"500", fontSize: "21px", marginTop:"17px", marginBottom:"17px", height:"54px", textAlign:"left"}}>
                            {this.trim(this.props.blogPost.Title,37)}
                        </div>
                        <div style={{color:"#696969", fontWeight:"300"}}>
                            {this.trim(this.props.blogPost.Subtitle,500)}
                            &nbsp;<span style={{color:"green"}}>Read More.</span>
                        </div>
                        <br />
                    </CardBody>
                </Card>
            </Link>
        </GridItem>
    )}
}

const mapStateToProps = state => {
    return state;
}

BlogPostCard = connect(mapStateToProps)(BlogPostCard);
export default withStyles(cardStyle)(BlogPostCard);
