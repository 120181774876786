import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";


class BenefitsSection extends React.Component {
  
  componentDidMount() {
    this.getStrokeAnimation('divSimplified', '汉', 89, 69, "#898989", false);
    this.getStrokeAnimation('divTraditional', '漢', 89, 69, "#232323", false);
    this.getStrokeAnimation('divSpeakers', '说', 89, 89, "#3fa31b", false);
    this.getStrokeAnimation('divStrokeOrderAnimation', '爱', 89, 89, "#000000", true);
  }

  getStrokeAnimation(div, char, height, width, color, animate) {

    const script = document.createElement("script");

    var js = "var target = document.getElementById('" + div + "'); ";
    js = js + "var d = document.createElement('span');";
    js = js + "var w = HanziWriter.create(d, '" + char + "', { width: " + width + ", height: + " + height + ", padding: 0, showCharacter: true, strokeColor:'" + color + "' });";    
    js = js + "target.appendChild(d);";    
    if(animate)
      js = js + "w.loopCharacterAnimation();"; 
    
    script.innerHTML = js;
    document.body.appendChild(script);
}

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section} >
        
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Chinese Only</h2>
            <h5 className={classes.description}>
              Everything we build is specifically tailored to learning Mandarin Chinese, 
              so we offer features generic tools just don't have.
            </h5>
          </GridItem>
        </GridContainer>
        <br />
        
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <div id='divSimplified' style={{display:"inline-block" }}></div>
              <div id='divTraditional' style={{display:"inline-block" }}></div>
              <h4 className={classes.title}>Simplified and Traditional</h4>
              <div style={{color:"#898989", fontSize:"14px", marginTop:"-10px"}}>
              Lessons, practice decks, worksheets and everything else is available in Simplified and Traditional scripts.
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div id='divSpeakers' style={{display:"inline-block", color:"black"}}></div>
              <h4 className={classes.title}>Native Speakers</h4>
              <div style={{color:"#898989", fontSize:"14px", marginTop:"-10px"}}>
              Over five thousand vocabulary items and hundreds of our sample dialogs have been professionally recorded by native speakers.
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{textAlign:"center"}}>
              <div id='divStrokeOrderAnimation'></div>
              <h4 className={classes.title}>Stroke Order</h4>
              <div style={{color:"#898989", fontSize:"14px", marginTop:"-10px"}}>
                Users can see stroke order animations on the website and stroke by stroke guidance on practice worksheets.
              </div>
            </GridItem>
          </GridContainer>
        
      </div>
    );
  }
}

export default withStyles(productStyle)(BenefitsSection);
