//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia/index.js';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import classNames from "classnames";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Table from "@material-ui/core/Table";
//import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from '@material-ui/core/Hidden';
import VocabularyCell from "components/VocabularyRow/VocabularyRow.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

//icons
import Search from "@material-ui/icons/Search";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbsDown from "@material-ui/icons/ThumbDown";

//images
import coverPhoto from "assets/img/library.jpg"

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const blackLogo = require("assets/img/blackLogo.png");
const dashboardRoutes = [];


class DictionaryPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        opacity: 1,
        results: []
    };

    this.search = this.search.bind(this);
    this.logActivity("Dictionary-PageView");
  }

  logActivity(title, params) {
    var userID, orgID, schoolID, classID = "anonymous";
    if(this.props.user) {
      userID = this.props.user.id;
      if(this.props.user.schoolID)
        schoolID = this.props.user.schoolID;
      if(this.props.user.classID)
        classID = this.props.user.classID;
    }

    db.logActivity(userID, orgID, schoolID, classID, title, params);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  setSearchTimer(query) {
    clearTimeout(this.searchProducts);
    this.setState({query}, () => {
        setTimeout(this.search,2300);
    });
  }

  search() {
    var query = this.state.query;

    algolia.searchVocabulary(query).then(vocabulary => {

        var promises = [];
        
        for(var i = 0; i < vocabulary.length; i++) {          
          var promise = db.getVocabulary(vocabulary[i].ID, this.props.user.id);
          promises.push(promise);
        }
        
        Promise.all(promises).then((dbResults) => {
            
          var results = [];
          
          for(var j = 0; j < dbResults.length; j++) {

            if(dbResults[j]) {
              var v = dbResults[j];
              
              for(var x = 0; x < vocabulary.length; x++) { // v/d IDs from the deck
                if(vocabulary[x].ID === v.id) { //got the vocab identified
                  
                  for(var k = 0; k < v.Definitions.length; k++) {
                    if(vocabulary[x].DefinitionID === v.Definitions[k].id) {
                      
                      var d = v.Definitions[k];

                      // sort decomp
                      if(d.WordDecomp) {
                        var sortedDecomp = [];
                        for(var a = 0; a < v.Simplified.length; a++) {
                          for(var b = 0; b < d.WordDecomp.length; b++) {
                            var length = d.WordDecomp[b].Simplified.length;
                            if(v.Simplified.substr(a,length) === d.WordDecomp[b].Simplified) {
                              d.WordDecomp[b].id = a;
                              sortedDecomp.push(d.WordDecomp[b]);
                              break;
                            }
                          }
                        }
                        d.WordDecomp = sortedDecomp;
                      } 
                      else d.WordDecomp=[];

                      results.push(new this.vocabResult(v.id, v.Simplified, v.Traditional, d.id, d.Pinyin, d.English, d.AudioURL, d.Progress, d.WordDecomp, v));
                    }
                  }
                }
              }
            }
          }
          
          this.setState({results: results});
          //this.logActivity("Dictionary-Search", "query: " + this.state.query + " - results: " + results.length);
        });
    })
  }

  vocabResult(id, simplified, traditional, definitionID, pinyin, english, audioURL, progress, decomp, obj) {
    this.ID = id;
    this.Simplified = simplified;
    this.Traditional = traditional;
    this.DefinitionID = definitionID;
    this.Pinyin = pinyin;
    this.English = english;
    this.AudioURL = audioURL;
    this.Progress = progress;
    this.WordDecomp = decomp;
    this.obj = obj;
  }

  trim(value, maxLength) {
    if(value) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    } 
  }

  getConfidenceIcon(confidence) {
    if(confidence === 3) {
      return <div style={{color:"#3fa31b", marginTop:"10px" }}><ThumbsUp style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 2) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.5 }}><ThumbsUpDown style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 1) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.2 }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    } else {
      return <div style={{color:"#eeeeee", marginTop:"10px" }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    
    return (
      <div>
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',right:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity, transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "dark"
            }}
            {...rest}
          />
          <Parallax filter image={coverPhoto}>
            
          </Parallax>
          
          <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#ffffff", minHeight:"89px", marginTop:"-123px"}}>
            <div className={classes.container}>
              
              <div className={classes.section} >

                  <div className={classes.photoDesc}>
                      The National Library of China in Beijing. Photo by Ken Marshall, CC-BY-20.
                  </div>

                  <GridContainer>
                
                    <GridItem xs={12} sm={12} md={12}>
                        
                        <br />

                        <h3 classes={[classes.title, classes.black]}>Dictionary</h3>

                        <CustomInput
                          labelText="Search"
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControl
                          }}
                          inputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <Search className={classes.icon} />
                              </InputAdornment>
                              ),
                              placeholder: "Search"
                          }}
                          onChange={(e) => this.setSearchTimer(e.target.value)}
                        />

                        <Table>
                          <TableBody>
                            {this.state.results.map((value,index) => (
                              <TableRow key={index} className={classes.tableRow}>
                                  <TableCell className={classes.tableActions} style={{padding:"0px", width:"123px"}}>
                                      <Tooltip
                                        id="tooltip-top"
                                        title="Play Vocabulary"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                      >
                                        <VocabularyCell vocabulary={value.obj} decomp={value.WordDecomp} audioURL={value.AudioURL} />
                                      </Tooltip>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{padding:"3px", fontSize:"24px"}}>
                                    {value.Simplified}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{padding:"3px", color:"#545454"}}>
                                    <div style={{color:"#3fa31b", marginBottom:"-6px"}}>
                                        {value.Pinyin}
                                    </div>
                                    {this.trim(value.English,76)}
                                  </TableCell>
                                  <Hidden smDown>
                                      <TableCell style={{width:"24px"}}>
                                          {this.getConfidenceIcon(value.Progress.ConfidenceReading)}
                                      </TableCell>
                                      <TableCell style={{width:"24px"}}>
                                          {this.getConfidenceIcon(value.Progress.ConfidenceListening)}
                                      </TableCell>
                                  </Hidden>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>                       

                        
                        <br /><br /><br /><br />
                    </GridItem>
                  </GridContainer>
              </div>
            </div>
          </div>
          
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

DictionaryPage = connect(mapStateToProps)(DictionaryPage);
export default withStyles(landingPageStyle)(DictionaryPage);
