import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

class PrivacyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
    
  }

  render() {
    const { classes, ...rest } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax  image={require("assets/img/printingBlock.jpg")}>
          
        </Parallax>
        <div style={{backgroundImage:"linear-gradient(black, white)"}}>
        <div className={classNames(classes.main, classes.mainRaisedHigh)}>
          <div className={classes.container}>
            
            <div className={classes.section} >

                <div className={classes.photoDesc}>
                  Carving a Chinese printing block. Photo by Jonathan Kos-Read, CC-BY-ND.
                </div>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        
                        <br />

                        <h2 classes={[classes.title, classes.black]}>Privacy Policy</h2>

                        <br />
                        
Welcome to the HANYU.CO website (the “Site”). HANYU.CO is owned and developed by Spiffy Ventures, L.L.C., an Arizona limited liability company (“Spiffy”). 

This page explains the policy governing online data collection and usage adopted by Spiffy (the “Privacy Policy”), which includes the software, website and online services that make up HANYU.CO (collectively the "Services"). This Privacy Policy does not govern collection of data offline. By using the Services you consent to the data practices described in this Privacy Policy. 
<br /><br />
<h4>Collection of your Personal Information </h4>
Spiffy may collect personally identifiable information, such as your name and email address. If you purchase Spiffy's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. We may gather additional personal or non-personal information in the future. 
<br /><br />
Information about your computer hardware and software may be automatically collected by Spiffy. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the Services, to maintain quality of the Services, and to provide general statistics regarding use of the Spiffy website. 
<br /><br />
The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). Any information that is disclosed in these areas becomes public information and you should exercise caution when deciding whether to disclose your personal information. Any material, information, or idea you transmit to or post on this Site via the Communications Services or otherwise will be treated as non-confidential and non-proprietary, and may be disseminated or used by Spiffy or its affiliates for any purpose whatsoever, including, but not limited to, developing and marketing its products and services. 
<br /><br />
Spiffy has no obligation to monitor the Communication Services. However, please keep in mind that Spiffy reserves the right to review materials posted to the Communication Services and to remove any materials in its sole discretion which Spiffy, in its sole discretion, deems is profane, offensive, slanderous, libelous, or may violate applicable laws. Further, Spiffy reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever in Spiffy’s sole discretion. 
<br /><br />
Links to Other Sites 
This Site may contain links to other sites that are not owned or controlled by Spiffy. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave our Site and to read the privacy statements of each and every website that collects personally identifiable information. This Privacy Policy applies only to information collected by this Site. 
<br /><br />
<h4>Use of your Personal Information </h4>
Spiffy collects and uses your personal information to operate its Site and deliver the Services you have requested. 
<br /><br />
Spiffy may also use your personally identifiable information to inform you of other products or services available from Spiffy and its affiliates. Spiffy may also contact you via surveys to conduct research about your opinion of current Services or of potential new Services that may be offered. 
<br /><br />
Spiffy does not sell, rent or lease its customer lists to third parties. 
<br /><br />
Spiffy may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Spiffy, and they are required to maintain the confidentiality of your information. 
<br /><br />
Spiffy may keep track of the websites and pages our users visit within our sites in order to determine what Spiffy Services are the most popular. This data is used to deliver customized content and advertising within our websites and applications to customers whose behavior indicates that they are interested in a particular subject area. 
<br /><br />
Spiffy will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Spiffy or the site; (b) protect and defend the rights or property of Spiffy; and, (c) act under exigent circumstances to protect the personal safety of users of Spiffy, or the public. 
<br /><br />
<h4>Use of Cookies </h4>
The Site may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. 
<br /><br />
One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Spiffy pages, or register on the Site or for Services, a cookie helps Spiffy to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the Site, the information you previously provided can be retrieved, so you can easily use the Spiffy features that you customized. 
<br /><br />
You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you may have the ability to modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Spiffy services or websites you visit. 
<br /><br />
<h4>Security of Your Personal Information </h4>
We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. 
<br /><br />
We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. 
<br /><br />
After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. 
<br /><br />
<h4>California Online Privacy Protection Act Compliance </h4>
Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent. 
<br /><br />
<h5>Terms and Conditions </h5>
Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at https://www.hanyu.co/terms.
<br /><br />
<h5>Changes to this Privacy Policy </h5>
Spiffy will occasionally update this Privacy Policy. Spiffy encourages you to periodically review this Privacy Policy to be informed of how Spiffy is protecting your information. 
<br /><br />
<h5>Contact Us</h5>
<br />Spiffy Ventures, L.L.C.
<br />info@SpiffyVentures.com 
<br /><br />
This Privacy Policy was published on August 31, 2018. 

                        <br /><br /><br /><br />
                    </GridItem>
                </GridContainer>
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(PrivacyPage);
