
import { combineReducers } from 'redux';
import { 
    CLASSROOM_LOADED,
    LESSONS_LOADED,
    ORG_LOADED,
    USER_LOGGEDIN, USER_LOGGEDOUT,
    VIDEOS_LOADED
} from './actions';


// generic sort comparer
function compareName(a,b) {

    var aName = a.Name;
    var bName = b.Name;
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
}


function classroom(state = null, action) {

    switch (action.type) {
        case CLASSROOM_LOADED:

            state = action.classroom;
            return state;
        default:
            return state;
    }
}

function products(state = [], action) {
    
    switch (action.type) {
        
        default:
            return state;
    }
}

function org(state = null, action) {
    
    switch (action.type) {
        case ORG_LOADED:

            console.log('ORG_LOADED: ' + action.org.name);

            return {
                id: action.org.id,
                name: action.org.name,
                description: action.org.description,
                website: action.org.website
            }

        default:
            return state;
    }
}


function lessons(state = [], action) {
    
    switch (action.type) {
        
        case LESSONS_LOADED:
            state = action.lessons;
            return state;

        default:
            return state;
    }
}

function locations(state = [], action) {
    
    switch (action.type) {
        
        default:
            return state;
    }
}

function user(state = {authenticated: false}, action) {
    
    switch (action.type) {
        case USER_LOGGEDIN:
            
            return {
                authenticated: true,
                id: action.user.id,
                email: action.user.email,
                firstName: action.user.firstName,
                lastName: action.user.lastName,
                orgId: action.user.orgID,
                schoolID: action.user.schoolID,
                classID: action.user.classID
            }

        case USER_LOGGEDOUT:

            return {
                authenticated: false
            }
    
        default:
            return state;
    }
}


function videos(state = [], action) {
    
    switch (action.type) {
        
        case VIDEOS_LOADED:
            return action.videos;

        default:
            return state.sort(compareName);
    }
}

const Reducers = combineReducers({ 
    
    classroom,
    lessons,
    locations,
    org, 
    products,
    user,
    videos
})

const appState = (Reducers)

export default appState
