//react
import React from "react";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//
import Parser from 'html-react-parser';

// nodejs library that concatenates classes
import classNames from "classnames";

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// core
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

//components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

//hanyu
import VocabularyCell from "components/VocabularyRow/VocabularyRow.jsx";
import Flashcards from "components/Flashcards/Flashcards.jsx";
import Worksheet from "components/Worksheet/Worksheet.jsx";
import GlimpsePlayer from "components/GlimpsePlayer/GlimpsePlayer.jsx";
import Dialog from "components/Dialog/Dialog.jsx";

// @material-ui/icons
import Video from "@material-ui/icons/OndemandVideo";
import Content from "@material-ui/icons/Subject";
import Chat from "@material-ui/icons/Chat";
import Glimpse from "@material-ui/icons/ArtTrack";
import Translate from "@material-ui/icons/Translate";
import Hearing from "@material-ui/icons/Hearing";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbsDown from "@material-ui/icons/ThumbDown";

//video player
import ReactPlayer from 'react-player'

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const blackLogo = require("assets/img/blackLogo.png");

const dashboardRoutes = [];

class LessonPage extends React.Component {

  constructor(props) {
    super(props);

    this.loadVocabulary = this.loadVocabulary.bind(this);
    this.videoPlayed = this.videoPlayed.bind(this);

    this.state = {
      dialog: {Sentences: []},
      featuredImage: {URL: null},
      glimpse: {Sentences: []},
      video: {URL: null},
      vocabulary: [],
      lesson: { Name: "" },
      className: "",
      opacity: 0
    };

    if(props.classroom) 
      this.state.className = props.classroom.Name;

    db.getLesson(this.props.match.params.id).then(doc => {
        
      var lesson = doc.data();
      lesson.id = doc.id;
      if(!lesson.Photos)
        lesson.Photos = [];
        
      this.state.lesson = lesson;

      if(this.state.lesson.Video) {
        db.getVideo(this.state.lesson.Video).then(videoDoc => {
          var video = videoDoc.data();
          video.id = videoDoc.id;
          this.state.video = video;
        });
      }

      if(this.state.lesson.PhotoID) {
        db.getPhoto(this.state.lesson.PhotoID).then((photo) => {
          this.state.featuredImage = photo;
          this.state.opacity = 1;
        });
      } else for(var j = 0; j < this.state.lesson.Photos.length; j++) {
        if(this.state.lesson.Photos[j].isCover) { 
            this.state.featuredImage = this.state.lesson.Photos[j];
            this.state.opacity = 1;
            break;
        }
      }
  
      this.loadVocabulary();
      this.loadDialog();
      this.loadGlimpse();
    });  
      

    var userID, orgID, schoolID, classID = "anonymous";
    if(this.props.user) {
      userID = this.props.user.id;
      if(this.props.user.schoolID)
        schoolID = this.props.user.schoolID;
      if(this.props.user.classID)
        classID = this.props.user.classID;
    }

    db.logActivity(userID, orgID, schoolID, classID, "Lesson-Viewed", this.state.lesson.id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  loadDialog() {
    if(this.state.lesson.DialogID) {
      db.getDialog(this.state.lesson.DialogID).then(doc => {
        var dialog = doc.data();

        var promises = [];
        
        for(var i = 0; i < dialog.Sentences.length; i++) {
          for(var j = 0; j < dialog.Sentences[i].Definitions.length; j++) {
            var promise = db.getVocabulary(dialog.Sentences[i].Definitions[j].VocabularyID, this.props.user.id);
            promises.push(promise);
          }
        }

        Promise.all(promises).then((dbResults) => {
          
          for(var z = 0; z < dbResults.length; z++) {

            var v = dbResults[z];
            
            for(var x = 0; x < dialog.Sentences.length; x++) { 
              for(var y = 0; y < dialog.Sentences[x].Definitions.length; y++) {

                if(!v) {
                  console.log('dialog def not found:');
                  
                } else {

                  if(dialog.Sentences[x].Definitions[y].VocabularyID === v.id) { //got the vocab identified
                  
                    for(var k = 0; k < v.Definitions.length; k++) {
                      if(dialog.Sentences[x].Definitions[y].id === v.Definitions[k].id) {
                        
                        var d = v.Definitions[k];
                        /*
                        // sort decomp
                        if(d.WordDecomp) {
                          var sortedDecomp = [];
                          for(var a = 0; a < v.Simplified.length; a++) {
                            for(var b = 0; b < d.WordDecomp.length; b++) {
                              var length = d.WordDecomp[b].Simplified.length;
                              if(v.Simplified.substr(a,length) === d.WordDecomp[b].Simplified) {
                                d.WordDecomp[b].id = b;
                                sortedDecomp.push(d.WordDecomp[b]);
                                break;
                              }
                            }
                          }
                          d.WordDecomp = sortedDecomp;
                          
                        } 
                        else d.WordDecomp=[];
                        */

                        dialog.Sentences[x].Definitions[y] = new this.vocabResult(v.id, v.Simplified, v.Traditional, d.id, d.Pinyin, d.English, d.AudioURL, d.Progress, d.WordDecomp, v)
                      }
                    }
                  }
                }
              }
            }
          }

          this.setState({dialog});
        });
      });
    }
  }

  loadGlimpse() {
    if(this.state.lesson.GlimpseID) {
      db.getGlimpse(this.state.lesson.GlimpseID).then(doc => {
        var glimpse = doc.data();

        console.log(glimpse)

        var promises = [];
        
        for(var i = 0; i < glimpse.Sentences.length; i++) {
          for(var j = 0; j < glimpse.Sentences[i].Definitions.length; j++) {
            var promise = db.getVocabulary(glimpse.Sentences[i].Definitions[j].VocabularyID, this.props.user.id);
            promises.push(promise);
          }
        }

        Promise.all(promises).then((dbResults) => {
          
          for(var z = 0; z < dbResults.length; z++) {

            var v = dbResults[z];
            
            for(var x = 0; x < glimpse.Sentences.length; x++) { 
              
              for(var y = 0; y < glimpse.Sentences[x].Definitions.length; y++) {
                if(glimpse.Sentences[x].Definitions[y])
                if(glimpse.Sentences[x].Definitions[y].VocabularyID === v.id) { //got the vocab identified
                
                  for(var k = 0; k < v.Definitions.length; k++) {
                     if(glimpse.Sentences[x].Definitions[y].id === v.Definitions[k].id) {
                       
                      var d = v.Definitions[k];
                      glimpse.Sentences[x].Definitions[y] = this.vocabResult(v.id, v.Simplified, v.Traditional, d.id, d.Pinyin, d.English, d.AudioURL, d.Progress, d.WordDecomp, v)
                     }
                  }
                }
              }
            }
          }

          this.setState({glimpse});
        });
      });
    }
  }

  loadVocabulary() {

    if(this.state.lesson.PracticeDeck) {
      
      db.getPracticeDeck(this.state.lesson.PracticeDeck).then(deck => {
        
        console.log(deck)
        // multiple definitions will result in multiple vocab calls
        // so we filter to the unique vocabulary items
        var uniqueVocab = [];
        var promises = [];
        deck.Vocabulary.forEach(row => {
          var isFound = false;
          for(var z = 0; z < uniqueVocab.length; z++) {
            if(uniqueVocab[z] === row.VocabularyID) {
              isFound = true;
              break;
            }
          }
          if(!isFound) {
            uniqueVocab.push(row.VocabularyID);
            promises.push(db.getVocabulary(row.VocabularyID, this.props.user.id));
          }
        })

        Promise.all(promises).then((dbResults) => {
          
          var vocabulary = [];
          
          for(var j = 0; j < dbResults.length; j++) {

            if(dbResults[j]) {
              var v = dbResults[j];
              
              for(var x = 0; x < deck.Vocabulary.length; x++) { // v/d IDs from the deck
                if(deck.Vocabulary[x].VocabularyID === v.id) { //got the vocab identified
                  
                  for(var k = 0; k < v.Definitions.length; k++) {
                    if(deck.Vocabulary[x].DefinitionID === v.Definitions[k].id) {
                      
                      var d = v.Definitions[k];

                      // sort decomp
                      if(d.WordDecomp) {
                        var sortedDecomp = [];
                        for(var a = 0; a < v.Simplified.length; a++) {
                          for(var b = 0; b < d.WordDecomp.length; b++) {
                            var length = d.WordDecomp[b].Simplified.length;
                            if(v.Simplified.substr(a,length) === d.WordDecomp[b].Simplified) {
                              d.WordDecomp[b].id = a;
                              sortedDecomp.push(d.WordDecomp[b]);
                              break;
                            }
                          }
                        }
                        d.WordDecomp = sortedDecomp;
                      } 
                      else d.WordDecomp=[];

                      vocabulary.push(this.vocabResult(v.id, v.Simplified, v.Traditional, d.id, d.Pinyin, d.English, d.AudioURL, d.Progress, d.WordDecomp, v));
                    }
                  }
                }
              }
            }
          }

          vocabulary.sort(this.compareConfidence);
          this.setState({vocabulary});
        });
      });
    }
  }

  compareConfidence(a,b) {

    var aConfidence = a.Progress.ConfidenceListening + a.Progress.ConfidenceReading;
    var bConfidence = b.Progress.ConfidenceListening + b.Progress.ConfidenceReading;
  
    if (aConfidence < bConfidence)
      return -1;
    if (aConfidence > bConfidence)
      return 1;
    return 0;
  }
  

  vocabResult(id, simplified, traditional, definitionID, pinyin, english, audioURL, progress, decomp, obj) {
    this.ID = id;
    this.Simplified = simplified;
    this.Traditional = traditional;
    this.DefinitionID = definitionID;
    this.Pinyin = pinyin;
    this.English = english;
    this.AudioURL = audioURL;
    this.Progress = progress;
    this.WordDecomp = decomp;
    this.obj = obj;
  }

  trim(value, maxLength) {
    if(value) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    } 
  }

  getConfidenceIcon(confidence) {
    if(confidence === 3) {
      return <div style={{color:"#3fa31b", marginTop:"10px" }}><ThumbsUp style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 2) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.5 }}><ThumbsUpDown style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 1) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.2 }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    } else {
      return <div style={{color:"#eeeeee", marginTop:"10px" }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    }
  }

  lessonText() {
    if(this.state.lesson.Content)
      return Parser(this.state.lesson.Content);
    else 
      return "";
  }

  videoPlayed() {
    db.logActivity(this.props.user.id, this.props.org.id, this.props.user.schoolID, this.props.user.classID, "Video-Played");
  }

  lessonContent() {
    if(this.state.lesson.Content)
      return Parser(this.state.lesson.Content);
    else 
      return "";
  }

  hideContentTab () {
    var hideContent = true;
    if(this.state.lesson.Content) {
      if(this.state.lesson.Content !== "") {
        hideContent = false;
      }
    }
    return hideContent;
  }

  hideDialogTab() {
    var hideDialog = true;
    if(this.state.dialog.Sentences.length > 0) {
      hideDialog = false;
    }
    return hideDialog;
  }

  hideGlimpseTab() {
    var hideGlimpse = true;
    if(this.state.glimpse.Sentences.length > 0) {
      hideGlimpse = false;
    }
    return hideGlimpse;
  }

  hideVideoTab() {
    var hideVideo = true;
    if(this.state.video.URL) {
      if(this.state.video.URL !== "") {
        hideVideo = false;
      }
    }
    return hideVideo;
  }

  getClassDescription() {
    if(this.state.lesson.Quarter)
      return this.state.className + ", Quarter: " + this.state.lesson.Quarter + ", Week: " + this.state.lesson.Week; 
    else
      return "";
  }

  showQuarterWeek() {
    if(this.state.lesson.Quarter) {
      return "block";
    } else {
      return "none";
    }
  }

  render() {
    const { classes, ...rest } = this.props;
  
    return (
      <div>
        <CircularProgress color="inherit" style={{position:'fixed',top:0,bottom:0,left:0,right:0,width:'54px',height:'54px',opacity:0.5,margin:'auto'}} />
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',left:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity,transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 300,
              color: "dark"
            }}
            {...rest}
          />
          
          <Parallax image={this.state.featuredImage.URL} >
            
          </Parallax>
          <div style={{backgroundImage:"linear-gradient(#000000, #ffffff)",zIndex:23}}>
            <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#efefef"}}>
              <div className={classes.container}>
                <div className={classes.section} >
                    <div className={classes.photoDesc}>
                      {this.state.featuredImage.Description} Photo by {this.state.featuredImage.Attribution}.
                    </div>

                    <GridContainer justify="center"  >
                      <GridItem xs={12} sm={12} md={12} style={{marginTop:"50px"}}>
                            
                            <br />
                            <h2 className={[classes.title, classes.black]}>{this.state.lesson.Name}</h2>
                            <div style={{color:"#898989",fontSize:"12px", marginTop:"-17px", display:this.showQuarterWeek()}}>
                              Quarter: {this.state.lesson.Quarter}, Week: {this.state.lesson.Week}
                            </div>
                            <div className={classes.black}>
                              {this.state.lesson.Description}
                            </div>
                            
                            <br />
                            <CustomTabs
                              headerColor="success"
                              tabs={[
                                {
                                  tabName: "Vocabulary",
                                  tabIcon: Translate,
                                  tabContent: (
                                    
                                      <GridContainer direction="row">
                                        <GridItem xs={12} sm={8} md={8} style={{textAlign:"left"}}>
                                          <Flashcards className={classes.tableActionButtonIcon + " " + classes.edit}
                                              vocabulary={this.state.vocabulary} onComplete={this.loadVocabulary}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} style={{textAlign:"right"}}>
                                          <Hidden xsDown>
                                            <Worksheet className={classes.tableActionButtonIcon + " " + classes.edit}
                                                vocabulary={this.state.vocabulary} footer={this.getClassDescription()}
                                            />
                                          </Hidden>
                                        </GridItem>
                                        
                                        <GridItem xs={12} sm={12} md={12}>
                                          <div className={classes.black}>

                                          <Table className={classes.table}>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell style={{padding:"3px"}}>

                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                  Simplified
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Pinyin / English
                                                </TableCell>
                                                <Hidden smDown>
                                                  <TableCell style={{width:"24px"}}>
                                                    <Tooltip placement='left' title='Confidence Reading'>
                                                      <Translate style={{fontSize:"17px", marginTop:"16px"}} />
                                                    </Tooltip>
                                                  </TableCell>
                                                  <TableCell style={{width:"24px"}}>
                                                    <Tooltip placement='left' title='Confidence Listening'>
                                                      <Hearing style={{fontSize:"17px", marginTop:"16px"}} />
                                                    </Tooltip>
                                                  </TableCell>
                                                </Hidden>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {this.state.vocabulary.map((value,index) => (
                                                <TableRow key={index} className={classes.tableRow}>
                                                  <TableCell className={classes.tableActions} style={{padding:"0px"}}>
                                                    <Tooltip
                                                      id="tooltip-top"
                                                      title="Play Vocabulary"
                                                      placement="top"
                                                      classes={{ tooltip: classes.tooltip }}
                                                    >
                                                      <VocabularyCell vocabulary={value.obj} decomp={value.WordDecomp} audioURL={value.AudioURL} />
                                                    </Tooltip>
                                                  </TableCell>
                                                  <TableCell className={classes.tableCell} style={{padding:"3px", fontSize:"24px"}}>
                                                    {value.Simplified}
                                                  </TableCell>
                                                  <TableCell className={classes.tableCell} style={{padding:"3px", color:"#545454"}}>
                                                    <div style={{color:"#3fa31b", marginBottom:"-6px"}}>
                                                      {value.Pinyin}
                                                    </div>
                                                    {this.trim(value.English,76)}
                                                  </TableCell>
                                                  <Hidden smDown>
                                                    <TableCell style={{width:"24px"}}>
                                                      {this.getConfidenceIcon(value.Progress.ConfidenceReading)}
                                                    </TableCell>
                                                    <TableCell style={{width:"24px"}}>
                                                      {this.getConfidenceIcon(value.Progress.ConfidenceListening)}
                                                    </TableCell>
                                                  </Hidden>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                          </div>
                                        </GridItem>
                                      </GridContainer>
                                    
                                  )
                                },
                                {
                                  tabName: "Content",
                                  tabIcon: Content,
                                  tabHidden: this.hideContentTab(),
                                  tabContent: (
                                    
                                      <GridContainer direction="row">
                                        <GridItem xs={12} sm={12} md={12} style={{minHeight:"489px",marginBottom:"17px" }}>
                                          {this.lessonContent()}
                                        </GridItem>
                                      </GridContainer>
                                  )
                                },
                                {
                                  tabName: "Dialog",
                                  tabIcon: Chat,
                                  tabHidden: this.hideDialogTab(),
                                  tabContent: (
                                    
                                    <Dialog dialog={this.state.dialog} />
                                  )
                                },
                                {
                                  tabName: "Glimpse",
                                  tabIcon: Glimpse,
                                  tabHidden: this.hideGlimpseTab(),
                                  tabContent: (
                                    
                                      <GlimpsePlayer glimpse={this.state.glimpse} />
                                  )
                                },
                                {
                                  tabName: "Video",
                                  tabIcon: Video,
                                  tabHidden: this.hideVideoTab(),
                                  tabContent: (
                                    
                                      <GridContainer direction="row">
                                        <GridItem xs={12} sm={12} md={12} style={{minHeight:"489px",marginBottom:"17px" }}>
                                          <ReactPlayer url={this.state.video.URL} onStart={this.videoPlayed} controls={true} width='100%' height='100%' />
                                        </GridItem>
                                      </GridContainer>
                                  )
                                }
                              ]}
                              />

                          <br /><br />
                        </GridItem>
                    </GridContainer>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

LessonPage = connect(mapStateToProps)(LessonPage);
export default withStyles(landingPageStyle)(LessonPage);
