import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

//icons
import Teacher from "@material-ui/icons/AssignmentInd"
import Content from "@material-ui/icons/Movie"
import Translate from "@material-ui/icons/Translate"

//import laoshi from "assets/img/icons/laoshi.jpg"
//import blackLogo from "assets/img/logoBlackSmall.png"

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
  
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>What We Do</h2>
            <h5 className={classes.description}>
              
            </h5>
          </GridItem>
        </GridContainer>
        <br />
        <div>
          <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                  title="Tools for Study"
                  description="We offer tools and resources you can use to build productive study habits."
                  icon={Translate}
                  iconColor="success"
                  vertical
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                  title="Engaging Content"
                  description="Tons of content formatted to make practicing your Chinese fun and effective."
                  icon={Content}
                  iconColor="gray"
                  vertical
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                  title="Work with Teachers"
                  description="Designed to work in classrooms, allowing teachers to customize their classes and track student progress."
                  icon={Teacher}
                  iconColor="success"
                  vertical
                />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
