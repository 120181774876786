//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import checkboxStyle from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";
//import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
//import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Print from "@material-ui/icons/Print";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

  class Worksheet extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        vocabulary: [],
        downloading: false
      };

      this.showFile = this.showFile.bind(this);
      this.handleToggle = this.handleToggle.bind(this);
    }

    handleClickOpen(modal) {
      var vocabulary = [];
      for(var i = 0; i < this.props.vocabulary.length; i++) {
        var v = this.props.vocabulary[i];
        v.selected = true;
        vocabulary.push(v);
      }
      this.setState({vocabulary});

      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    getPDF() {
      //var url = "https://us-central1-kauai-7b54d.cloudfunctions.net/getVocabPDF";
      var url = "https://us-central1-kauai-7b54d.cloudfunctions.net/getVocabPDFWithStrokeOrder";
      
      var vocab = [];
      for(var x = 0; x < this.state.vocabulary.length; x++) {
        if(this.state.vocabulary[x].selected)
          vocab.push(this.state.vocabulary[x]);
      }
      
      var html = "";
      for(var i = 0; i < vocab.length; i++) {
        
          html = html + "<div style='page-break-inside: avoid'>";
          html = html + "<div style='width: 100%; border-style:solid; border-width:1px; border-color:#3fa31b; background-color:#3fa31b; border-top-right-radius: 6px; border-top-left-radius: 6px; margin-top:11px;'>";
          html = html + "<div style='font-family: arial, sans-serif; font-size:16px; color:white; padding:6px; margin-left:6px;'>" + vocab[i].Simplified + " - <i>" + vocab[i].Pinyin + "</i> - " + vocab[i].English + "</div>";
          html = html + "</div>";
          for(var j = 0; j < vocab[i].Simplified.length; j++) {
            html = html + "<div id='v" + i + "c" + j + "' style='width: 100%; border-style:solid; border-width:1px; border-top:0px; border-color:#ababab; background-color:#efefef;'>";
            html = html + "</div>";
            html = html + "<div style='width: 100%; border-style:solid; border-width:1px; border-top:0px; border-color:#ababab; '>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#111111; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>"
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#cdcdcd; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#dddddd; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#dedede; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#ffffff; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#ffffff; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center; background-image: url(\"https://app.hanyu.co/grid.jpg\"); background-size: contain; background-position: center; background-repeat: no-repeat;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#ffffff; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#ffffff; border-style:solid; border-width:0px; border-right-width:1px; border-color:#ababab; display: inline-block; text-align:center;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "<div class='chinese' style='height:99px; width:91.5px; color:#ffffff; border-style:solid; border-width:0px; border-right-width:0px; border-color:#ffffff; display: inline-block; text-align:center;'>" + vocab[i].Simplified[j] + "</div>";
            html = html + "</div>";
          }
          html = html + "</div>";
        
      }

      html = html + "<div id='pageFooter' style='width:100%'>";
      var footer = "";
      if(this.props.footer) {
        footer = this.props.footer;
      }
      html = html + "<div style='color:#898989; font-family: arial, sans-serif; font-size:13px; display:inline-block; width:740px;'>" + footer + "</div>";
      html = html + "<div style='display:inline-block;'><img src='http://app.hanyu.co/logoBlackSmall.jpg' style='height:34px;' /></div></div>"

      var data = {
        vocabulary: vocab,
        html: html
      }
      
      fetch(url, {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
          if(response.ok) {
              return response.blob();
          } else {
            console.log("error1");  
            console.log(response);
          }
      })
      .then((blob) => {
          
        this.showFile(blob);

        db.logActivity(this.props.user.id, this.props.org.id, this.props.user.schoolID, this.props.user.classID, "Worksheet-PDF-Created");

        ReactGA.event({
          category: 'Worksheet',
          action: 'Worksheet PDF Created'
        });
        
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error2: " + error);
      });

    }

    showFile(blob){
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([blob], {type: "application/pdf"})

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //} 

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      //window.open(data);
      
      
      var link = document.createElement('a');
      //link.target = '_blank';
      link.href = data;
      link.download="worksheet.pdf";
      link.click();
      
      
    }

    handleToggle(vocab) {
      var vocabulary = this.state.vocabulary;

      for(var i = 0; i < vocabulary.length; i++) {
        if(vocabulary[i].DefinitionID === vocab.DefinitionID) {
          vocabulary[i].selected = !vocabulary[i].selected;
          break;
        }
      }

      this.setState({vocabulary: vocabulary});
      console.log(this.state.vocabulary);
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          <Button
            aria-label="Practice"
            onClick={() => this.handleClickOpen("modal")}>
            <Print
                className={
                    classes.tableActionButtonIcon + " " + classes.edit
                }
            /><div style={{fontSize:"13px"}}>Writing Practice</div>
          </Button>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12} >
                  <h3 className={classes.modalTitle}>Print Practice Worksheet</h3>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#ffffff"}}>

                <br />

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"3px"}}>

                      </TableCell>
                      <TableCell style={{padding:"3px", paddingLeft:"17px"}}>
                        Simplified
                      </TableCell>
                      <TableCell style={{padding:"3px", paddingLeft:"17px"}}>
                          Pinyin
                      </TableCell>
                      <TableCell style={{padding:"3px", paddingLeft:"17px"}}>
                          English
                      </TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.vocabulary.map(value => (
                      <TableRow key={value.ID} className={classes.tableRow}>
                        <TableCell style={{padding:"3px"}}>
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(value)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{ checked: classes.checked }}
                            checked={value.selected}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"3px", paddingLeft:"17px"}}>
                          {value.Simplified}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"3px", paddingLeft:"17px"}}>
                          {value.Pinyin}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"3px", paddingLeft:"23px"}}>
                          {value.English}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
                <GridContainer>
                  <GridItem xs={12} style={{textAlign: "right"}}>
                    <Button
                      onClick={() => {
                          // ?
                          this.handleClose("modal");
                        }}
                      color="transparent">
                      Cancel
                    </Button>&nbsp;
                    <Button
                      onClick={() => {
                          this.getPDF();
                          this.handleClose("modal");
                        }}
                      color="success">
                      Print
                    </Button>
                  </GridItem>
                </GridContainer>
            </DialogContent>
          </Dialog>
        </div>
      );
    }
  }
  
export default withStyles([modalStyle,checkboxStyle])(Worksheet);