

//algolia full text search
import algoliaSearch from 'algoliasearch';

var searchClient = algoliaSearch('EJD0BH4WJ7', 'cb4ab08334432efad17d517a8c50ba97');
//var searchClient = algoliaSearch('EJD0BH4WJ7', 'c77ff65a130f3492ce3dabdd58e14dd1');
var vocabIndex = searchClient.initIndex('vocabulary');
var dialogsIndex = searchClient.initIndex('dialogs');


export const applySettings = () => {
    vocabIndex.setSettings({
        distinct: 1,
        attributeForDistinct: 'id',
        attributesForFaceting: ['filterOnly(Definitions.HSK)']
      });
}

function comparePinyin(a,b) {

    var aName = a.Pinyin;
    var bName = b.Pinyin;
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

function vocabResult(id, simplified, traditional, definitionID, pinyin, english, audioURL, obj) {
    this.ID = id;
    this.Simplified = simplified;
    this.Traditional = traditional;
    this.DefinitionID = definitionID;
    this.Pinyin = pinyin;
    this.English = english;
    this.AudioURL = audioURL;
    this.obj = obj;
    this.Progress = {
        ConfidenceListening: 0,
        ConfidenceReading: 0
    };
}

function trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
}

export const getVocabularyByHSK = (level) => {

    return new Promise(function(resolve, reject) {
        
        var filter = 'Definitions.HSK:' + level;
        vocabIndex.search( "", {filters: filter, hitsPerPage: 1000 }).then(result => {
        
            var vocabulary = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                delete obj._highlightResult;
                
                for(var j = 0; j < obj.Definitions.length; j++) {
                    if(obj.Definitions[j].HSK === level)   {
                        if(!obj.Definitions[j].Progress) obj.Definitions[j].Progress = { };
                        vocabulary.push(new vocabResult(obj.id, obj.Simplified, obj.Traditional, obj.Definitions[j].id, obj.Definitions[j].Pinyin, trim(obj.Definitions[j].English,34), obj.Definitions[j].AudioURL, obj));
                    }   
                }
            }

            vocabulary.sort(comparePinyin);
            resolve(vocabulary);
        });
    });
}

export const searchVocabulary = (query) => {

    return new Promise(function(resolve, reject) {
        
        vocabIndex.search(query).then(result => {
                
            var vocabulary = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                for(var j = 0; j < obj.Definitions.length; j++) {        
                    vocabulary.push(new vocabResult(obj.id, obj.Simplified, obj.Traditional, obj.Definitions[j].id, obj.Definitions[j].Pinyin, trim(obj.Definitions[j].English,34), obj.Definitions[j].AudioURL, obj));
                }
            }

            resolve(vocabulary);
        });
    });
}

export const searchVocabularyBySimplified = (query) => {

    return new Promise(function(resolve, reject) {
        
        vocabIndex.search( query, {restrictSearchableAttributes: ['Simplified']}).then(result => {
                
            var vocabulary = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                for(var j = 0; j < obj.Definitions.length; j++) {
                    var isFound = false;
                    for(var k = 0; k < vocabulary.length; k++) {
                        if(vocabulary[k].DefinitionID === obj.Definitions[j].id) {
                            isFound = true;
                            break;
                        }
                    }   
                    if(!isFound)
                        vocabulary.push(new vocabResult(obj.id, obj.Simplified, obj.Traditional, obj.Definitions[j].id, obj.Definitions[j].Pinyin, trim(obj.Definitions[j].English,34), obj.Definitions[j].AudioURL, obj));
                }
            }

            resolve(vocabulary);
        });
    });
}

export const searchVocabularyByPinyin = (query, reference) => {

    return new Promise(function(resolve, reject) {
        
        vocabIndex.search(query, {restrictSearchableAttributes: ['Definitions.Pinyin']}).then(result => {
                
            var vocabulary = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                for(var j = 0; j < obj.Definitions.length; j++) {        
                    vocabulary.push(new vocabResult(obj.id, obj.Simplified, obj.Traditional, obj.Definitions[j].id, obj.Definitions[j].Pinyin, trim(obj.Definitions[j].English,34), obj.Definitions[j].AudioURL, obj));
                }
            }

            resolve({vocabulary: vocabulary, reference:reference});
        });
    });
}

export const searchDialogs = (query) => {

    return new Promise(function(resolve, reject) {
        
        dialogsIndex.search(query).then(result => {
                
            var results = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                results.push(obj);
            }

            resolve(results);
        });
    });
}
