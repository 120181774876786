import { db } from './firebase';

const settings = {timestampsInSnapshots: true};
db.settings(settings);


export const deleteProgress = (id) =>
  db.collection('Progress').doc(id).delete();



export const getActivityByUser = (userID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Activity');
    var queryRef = baseRef.where('UserID','==',userID);
    queryRef.orderBy("modified","desc").get().then(snapshot => {
      var activity = [];
      snapshot.forEach((doc) => {
        var a = doc.data();
        a.id = doc.id;
        activity.push(a);
      });
      resolve(activity);
    });
  });
}

export const getBlogPost = (id) => 
  db.collection('BlogPosts').doc(id).get();

export const getBlogPosts = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('BlogPosts');
    baseRef.orderBy("PublishDate","desc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var a = doc.data();
        a.id = doc.id;
        objs.push(a);
      });
      resolve(objs);
    });
  });
}

export const getClass = (id) =>
  db.collection('Classes').doc(id).get();

export const getCourse = (id) => {
  return new Promise(function(resolve, reject) {
    
    db.collection('Courses').doc(id).get().then(doc => {
      var course = doc.data();
      course.id = doc.id;
      getPhoto(course.PhotoID).then((photo) => {
        course.Photo = photo;
        resolve(course);
      });
    });
  });
}

export const getCourses = () => {
  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('Courses');
    var queryRef = baseRef.where('IsVisible','==',true);
    queryRef.orderBy("SortIndex","asc").get().then(snapshot => {
      
      var courses = [];
      var promises = [];

      snapshot.forEach((doc) => {
        var course = doc.data();
        course.id = doc.id;
        courses.push(course);
        
        var promise = getPhoto(course.PhotoID);
        promises.push(promise);
      });

      Promise.all(promises).then((dbResults) => {
      
        for(var i = 0; i < courses.length; i++) {
          for(var j = 0; j < dbResults.length; j++) {
            if(courses[i].PhotoID === dbResults[j].id) {
              courses[i].Photo = dbResults[j];
              break;
            }
          }
        }

        resolve(courses);
      });
    });
  });
}

export const getDialog = (id) => 
  db.collection('Dialogs').doc(id).get();

export const getGlimpse = (id) => 
  db.collection('Glimpses').doc(id).get();

export const getLesson = (id) => 
  db.collection('Lessons').doc(id).get();

export const getLessons = (classID) => {
  var baseRef = db.collection('Lessons');
  var queryRef = baseRef.where('ClassID','==',classID).where('IsVisible','==',true);
  return queryRef.orderBy("Quarter","desc").orderBy("Week","desc").get();
}

export const getLessonsByCourse = (courseID) => {

  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('Lessons');
    var queryRef = baseRef.where('CourseID','==',courseID).where('IsVisible','==',true);
    queryRef.orderBy("SortIndex","asc").get().then(snapshot => {
      
      var lessons = [];
      var promises = [];

      snapshot.forEach((doc) => {
        var lesson = doc.data();
        lesson.id = doc.id;
        lessons.push(lesson);
        
        var promise = getPhoto(lesson.PhotoID);
        promises.push(promise);
      });

      Promise.all(promises).then((dbResults) => {
      
        for(var i = 0; i < lessons.length; i++) {
          for(var j = 0; j < dbResults.length; j++) {
            if(lessons[i].PhotoID === dbResults[j].id) {
              lessons[i].Photo = dbResults[j];
              break;
            }
          }
        }

        resolve(lessons);
      });
    });
  });
}

export const getOrganization = (id) =>
  db.collection('Organizations').doc(id).get();

export const getPhoto = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Photos').doc(id).get().then((doc) => {
      var photo = doc.data();
      if(!photo) {
        console.log("missing id:" + id);
      } else photo.id = doc.id;
      resolve(photo);
    }); ;
  });
}
  
export const getPracticeDeck = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('PracticeDecks').doc(id).get().then(doc => {

      var deck = doc.data();
      deck.id = doc.id;
      resolve(deck);
    });
  });
}

export const getPracticeDecksByUser = (userID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('PracticeDecks');
    var queryRef = baseRef.where('UserID','==',userID);
    queryRef.orderBy("Name","asc").get().then(snapshot => {
        
      var decks = [];

      snapshot.forEach((doc) => {
        var p = doc.data();
        p.id = doc.id;
        decks.push(p);
      });

      resolve(decks);
    });
  });
}

export const getProgress = (id) => 
  db.collection('Progress').doc(id).get();

export const getProgressAll = () => {

  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('Progress');
    baseRef.get().then(snapshot => {
        
      var progress = [];

      snapshot.forEach((doc) => {
        var p = doc.data();
        p.id = doc.id;
        progress.push(p);
      });

      resolve(progress);
    });
  });
}  

export const getProgressByUser = (userID) => {

  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('Progress');
    var queryRef = baseRef.where('UserID','==',userID);
    queryRef.get().then(snapshot => {
        
      var progress = [];

      snapshot.forEach((doc) => {
        var p = doc.data();
        p.id = doc.id;
        progress.push(p);
      });

      resolve(progress);
    });
  });
}  

export const getProgressByUserVocabulary = (userID, vocabularyID) => {

  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('Progress');
    var queryRef = baseRef.where('UserID','==',userID).where('VocabularyID','==',vocabularyID);
    queryRef.get().then(snapshot => {
        
        snapshot.forEach((doc) => {
          var progress = doc.data();
          progress.id = doc.id;
          resolve(progress);
        });

        resolve(null);
    });
  });
}  

export const getProgressHistoryByUser = (userID) => {

  return new Promise(function(resolve, reject) {

    var baseRef = db.collection('ProgressHistory');
    var queryRef = baseRef.where('userID','==',userID).orderBy('created', 'desc').limit(30);
    queryRef.get().then(snapshot => {
        
      var results = [];
      snapshot.forEach((doc) => {
        var progress = doc.data();
        progress.id = doc.id;
        results.unshift(progress);
      });

      resolve(results);
    });
  });
} 

export const GetPurchases = (uid) =>
  db.collection('Purchases').orderBy("created","desc").get();

export const getUser = (id) => 
  db.collection('Users').doc(id).get();

export const getUserByUserName = (userName) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.where('UserName','==',userName);
    queryRef.get().then(snapshot => {
      snapshot.forEach((doc) => {
        var user = doc.data();
        user.id = doc.id;
        resolve(user);
      });
    });
  });
}

export const getLocations = (uid) =>
  db.collection('Locations').orderBy("Name").get();

export const getVideo = (id) => 
  db.collection('Videos').doc(id).get();

export const getVideos = () => {
  return db.collection('Videos').get();
}

export const getVocabulary = (id, userID) => {
  
  return new Promise(function(resolve, reject) {
    
    db.collection('Vocabulary').doc(id).get().then(doc => {
    
      try {
        var vocabulary = doc.data();
        vocabulary.id = doc.id;

        if(userID) {
          getProgressByUserVocabulary(userID, vocabulary.id).then(progress => {
            
            if(progress)
              vocabulary.Progress = progress;
  
            for(var i = 0; i < vocabulary.Definitions.length; i++) {
              if(progress) {
                for(var j = 0; j < progress.Definitions.length; j++) {
                  if(vocabulary.Definitions[i].id === progress.Definitions[j].DefinitionID) {
                    vocabulary.Definitions[i].Progress = progress.Definitions[j];
                    break;
                  }
                }
              } else {
                vocabulary.Definitions[i].Progress = { ConfidenceReading: 0, ConfidenceListening: 0};
              }
            }
  
            resolve(vocabulary);
          });
        } else {
  
          for(var i = 0; i < vocabulary.Definitions.length; i++)
            vocabulary.Definitions[i].Progress = {ConfidenceListening: 0, ConfidenceReading: 0}
          
          resolve(vocabulary);
        }
      }
      catch(exception) {
        console.log('EXCEPTION - getVocabulary():')
        console.log(id)
        resolve(null);
      }
    });
  });
}

export const getVocabularyByPage = (startAfter) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Vocabulary');
    var queryRef = baseRef.limit(100).orderBy('id');
    if(startAfter)
      queryRef = queryRef.startAfter(startAfter);
    queryRef.get().then(snapshot => {
        
      var vocabulary = [];

      snapshot.forEach((doc) => {
        var vocab = doc.data();
        vocab.id = doc.id;
        vocabulary.push(vocab);
      });

        resolve(vocabulary);
    });
  });
};

export const getVocabularyByHSKLevel = (level) => {

  console.log('level: ' + level)

  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Vocabulary');
    var queryRef = baseRef.where("hskLevels", "array-contains", level)
    queryRef.get().then(snapshot => {
      
      var vocabulary = [];

      snapshot.forEach((doc) => {
        var vocab = doc.data();
        vocab.id = doc.id;
        vocabulary.push(vocab);
      });

        resolve(vocabulary);
    });
  });
}

export const getVocabularyByPinyin = (pinyin) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Vocabulary');
    var queryRef = baseRef.where('Pinyin','==',pinyin);
    queryRef.get().then(snapshot => {
        
      console.log(snapshot);
      var vocabulary = [];

      snapshot.forEach((doc) => {
        var vocab = doc.data();
        vocab.id = doc.id;
        vocabulary.push(vocab);
      });

        resolve(vocabulary);
    });
  });
}

export const logActivity = (userID, orgID, schoolID, classID, activityType, objectID) => {

  if(typeof userID === 'undefined')
    userID = null;

  if(typeof schoolID === 'undefined')
    schoolID = null;

  if(typeof classID === 'undefined')
    classID = null;

  if(typeof objectID === 'undefined')
    objectID = null;

    if(typeof orgID === 'undefined')
    orgID = null;

  var activity = {
    UserID: userID,
    SchoolID: schoolID,
    ClassID: classID,
    ActivityType: activityType,
    ObjectID: objectID,
    orgID: orgID,
    modified: Math.round((new Date()).getTime() / 1000)
  }

  return db.collection('Activity').add(activity);
}

export const updateFeedback = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Feedback').add(value);
  else 
    return db.collection('Feedback').doc(id).set(value);
}

export const updateLead = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Leads').add(value);
  else 
    return db.collection('Leads').doc(id).set(value);
}

export const updateProgress = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Progress').add(value);
  else 
    return db.collection('Progress').doc(id).set(value);
}

export const updateUser = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Users').add(value);
  else 
    return db.collection('Users').doc(id).set(value);
}

export const updateVocabulary = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Vocabulary').add(value);
  else 
    return db.collection('Vocabulary').doc(id).set(value);
}