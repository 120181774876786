//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Play from "@material-ui/icons/PlayCircleFilled";
import Detail from "@material-ui/icons/MoreHoriz";
import Translate from "@material-ui/icons/Translate";
import Hearing from "@material-ui/icons/Hearing";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbsDown from "@material-ui/icons/ThumbDown";

//hanyu
import Flashcards from "components/Flashcards/Flashcards.jsx";
import Worksheet from "components/Worksheet/Worksheet.jsx";
import VocabularyCell from "components/VocabularyRow/VocabularyRow.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');


function Transition(props) {
    return <Slide direction="down" {...props} />;
}
  
  class PracticeDeck extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        deck: props.deck,
        vocabulary: [],
        sortBy: ''
      };
      
      this.getVocabulary = this.getVocabulary.bind(this);
      this.playAudio = this.playAudio.bind(this);
      this.sortVocabulary = this.sortVocabulary.bind(this);
      
    }

    handleClickOpen(modal) {
      this.getVocabulary();

      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    vocabResult(id, simplified, traditional, definitionID, pinyin, english, audioURL, progress, decomp, obj) {
      this.ID = id;
      this.Simplified = simplified;
      this.Traditional = traditional;
      this.DefinitionID = definitionID;
      this.Pinyin = pinyin;
      this.English = english;
      this.AudioURL = audioURL;
      this.Progress = progress;
      this.WordDecomp = decomp;
      this.obj = obj;
    }

    getVocabulary() {

      var promises = [];
      var vocabulary = [];

      for(var j = 0; j < this.state.deck.Vocabulary.length; j++) {
          var promise = db.getVocabulary(this.state.deck.Vocabulary[j].VocabularyID, this.props.user.id);
          promises.push(promise);
      }

      Promise.all(promises).then((dbResults) => {
        
        for(var z = 0; z < dbResults.length; z++) {

          var v = dbResults[z];

          for(var k = 0; k < this.state.deck.Vocabulary.length; k++) {
            if(v.id === this.state.deck.Vocabulary[k].VocabularyID) {
              for(var d = 0; d < v.Definitions.length; d++) {
                if(v.Definitions[d].id === this.state.deck.Vocabulary[k].DefinitionID) {

                  var definition = v.Definitions[d];
                  vocabulary.push(new this.vocabResult(v.id, v.Simplified, v.Traditional, definition.id, definition.Pinyin, definition.English, definition.AudioURL, definition.Progress, definition.WordDecomp, v));

                  break;
                }
              }
            }
          }
        }

        this.sortVocabulary('pinyin', vocabulary);
      });
    }


    compareConfidenceListening(a,b) {

      var aConfidence = a.Progress.ConfidenceListening;
      var bConfidence = b.Progress.ConfidenceListening;
    
      if (aConfidence < bConfidence)
        return -1;
      if (aConfidence > bConfidence)
        return 1;
      return 0;
    }

    compareConfidenceReading(a,b) {

      var aConfidence = a.Progress.ConfidenceReading;
      var bConfidence = b.Progress.ConfidenceReading;
    
      if (aConfidence < bConfidence)
        return -1;
      if (aConfidence > bConfidence)
        return 1;
      return 0;
    }

    compareEnglish(a,b) {

      var x = a.English.toLowerCase();
      var y = b.English.toLowerCase();
    
      if (x < y)
        return -1;
      if (x > y)
        return 1;
      return 0;
    }

    comparePinyin(a,b) {

      var aPinyin = a.Pinyin;
      var bPinyin = b.Pinyin;
    
      if (aPinyin < bPinyin)
        return -1;
      if (aPinyin > bPinyin)
        return 1;
      return 0;
    }

    sortVocabulary(sortBy, vocabulary) {

      if(!vocabulary)
        vocabulary = this.state.vocabulary;

      if(sortBy === this.state.sortBy) {// then reverse sort
        
        var v = [];
        for(var i = 0; i < vocabulary.length; i++)
          v.unshift(vocabulary[i]);

        vocabulary = v;
      }
      else if(sortBy === 'english')
        vocabulary.sort(this.compareEnglish);
      else if(sortBy === 'pinyin')
        vocabulary.sort(this.comparePinyin);
      else if(sortBy === 'reading')
        vocabulary.sort(this.compareConfidenceReading);
      else if(sortBy === 'listening')
        vocabulary.sort(this.compareConfidenceListening);

      this.setState({vocabulary:vocabulary, sortBy: sortBy});
      console.log(vocabulary);
    }

    playAudio(url) {
        if(!url)
            url = this.props.audioURL;

        this.audio = new Audio(url);
        this.audio.play();
    }

    getConfidenceIcon(confidence) {
      if(confidence === 3) {
        return <div style={{color:"#3fa31b", marginTop:"10px" }}><ThumbsUp style={{fontSize: "21px"}} /></div>;
      } else if (confidence === 2) {
        return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.5 }}><ThumbsUpDown style={{fontSize: "21px"}} /></div>;
      } else if (confidence === 1) {
        return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.2 }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
      } else {
        return <div style={{color:"#eeeeee", marginTop:"10px" }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
      }
    }

    trim(value, maxLength) {
      if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
      } 
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          
          <IconButton
            aria-label="More" 
            onClick={() => this.handleClickOpen("modal")}>
            <Detail className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="md"
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
               >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.modalTitle}>{this.props.deck.Name}</h2>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.props.deck.Description}
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              >
                <GridContainer>
                    <GridItem xs={12}>
                        <h3>Vocabulary</h3>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell style={{padding:"3px", cursor: "pointer"}} onClick={() => this.sortVocabulary('pinyin')} >
                                        Simplified
                                    </TableCell>
                                    <TableCell style={{padding:"3px", display:"none"}}>
                                        Traditional
                                    </TableCell>
                                    <TableCell style={{padding:"3px", cursor: "pointer"}} onClick={() => this.sortVocabulary('english')}>
                                        Pinyin / English
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      <Tooltip placement='left' title='Confidence Reading' >
                                        <Translate style={{fontSize:"17px", marginTop:"16px", cursor:"pointer"}} onClick={() => this.sortVocabulary('reading')}/>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      <Tooltip placement='left' title='Confidence Listening'>
                                        <Hearing style={{fontSize:"17px", marginTop:"16px", cursor:"pointer"}} onClick={() => this.sortVocabulary('listening')} />
                                      </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.vocabulary.map((value,index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableActions} style={{padding:"3px"}}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Play Vocabulary"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                          <VocabularyCell vocabulary={value.obj} decomp={value.WordDecomp} audioURL={value.AudioURL} /> 
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding:"3px", fontSize:"24px"}}>
                                      {value.Simplified}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding:"3px", fontSize:"24px", display:"none"}}>
                                      {value.Traditional}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding:"3px", color:"#545454"}}>
                                      <div style={{color:"#3fa31b", marginBottom:"-6px"}}>
                                        {value.Pinyin}
                                      </div>
                                      {this.trim(value.English,76)}
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      {this.getConfidenceIcon(value.Progress.ConfidenceReading)}
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      {this.getConfidenceIcon(value.Progress.ConfidenceListening)}
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </GridItem>
                </GridContainer>
            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter } >
                  <GridContainer style={{width:"100%"}}>
                      <GridItem xs={12} sm={12} md={6} style={{textAlign:"left"}}>
                        <Flashcards className={classes.tableActionButtonIcon + " " + classes.edit}
                            vocabulary={this.state.vocabulary} onComplete={ () => { } }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button
                            onClick={() => {
                                // ?
                                this.handleClose("modal");
                            }}
                            color="success">
                            OK
                        </Button>
                      </GridItem>
                  </GridContainer>
                  
            </DialogActions>
          </Dialog>
          
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

PracticeDeck = connect(mapStateToProps)(PracticeDeck);
export default withStyles(modalStyle)(PracticeDeck);