//react
import React from 'react';

//materialkit
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

//hanyu
import Sentence from "components/Sentence/Sentence.jsx";

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";

class Dialog extends React.Component {


    render() {
        const { classes } = this.props;

        return (
            <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12} style={{minHeight:"489px",marginBottom:"17px" }}>
                    <h4>{this.props.dialog.English}</h4>
                    <Table className={classes.table}>
                      <TableBody>
                        {this.props.dialog.Sentences.map(value => (
                        <TableRow key={value.id} className={classes.tableRow}>
                            <TableCell className={classes.tableActions} style={{padding:"0px"}}>
                            
                                <Sentence sentence={value} audioURL={value.AudioURL} />
                            
                            </TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(cardStyle)(Dialog);
