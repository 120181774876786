import React from "react";


//firebase
import { db } from 'assets/firebase';

// nodejs library that concatenates classes
import classNames from "classnames";

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

// Sections for this page
import BodyTextSection from "./Sections/BodyTextSection.jsx";
//import CommentsSection from "./Sections/CommentsSection.jsx";
import RelatedPostsSection from "./Sections/RelatedPostsSection.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

//images
const blackLogo = require("assets/img/blackLogo.png");

const dashboardRoutes = [];

class BlogPost extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      blogPost: {Title: ""},
      featuredImage: {URL:""},
      opacity: 0
    }

    this.loadBlogPost = this.loadBlogPost.bind(this);
    this.loadBlogPost();    

    var userID, orgID, schoolID, classID = "anonymous";
    if(this.props.user) {
      userID = this.props.user.id;
      if(this.props.user.schoolID)
        schoolID = this.props.user.schoolID;
      if(this.props.user.classID)
        classID = this.props.user.classID;
    }

    db.logActivity(userID, orgID, schoolID, classID, "BlogPost-Viewed", this.props.match.params.id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  loadBlogPost() {
    db.getBlogPost(this.props.match.params.id).then(doc => {

      var blogPost = doc.data();
      blogPost.id = doc.id;
      if(!blogPost.Photos)
        blogPost.Photos = [];

      db.getPhoto(blogPost.PhotoID).then((photo) => {
        this.setState({featuredImage: photo, opacity: 1});
      })
        
      this.setState({blogPost: blogPost});
    });
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',right:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity,transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 300,
              color: "dark"
            }}
            {...rest}
          />
          <Parallax filter image={this.state.featuredImage.URL}>
            <div className={classes.container} style={{display:"none"}}>
              <GridContainer alignItems="flex-end" style={{height:"250px"}}>
                <GridItem xs={12} sm={12} md={12} >
                  <div style={{textAlign:"center"}}>
                      <h2 className={classes.title} style={{fontFamily:'Roboto Slab',fontWeight:800}}>{this.state.blogPost.Title}</h2>
                      <h4>{this.state.blogPost.Subtitle}</h4>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:"-50px"}}>
            <div className={classes.photoDesc}>
                {this.state.featuredImage.Description} Photo by {this.state.featuredImage.Attribution}
            </div>
            <div className={classes.container}>     
              <BodyTextSection blogPost={this.state.blogPost} />
            </div>
          </div>
          
          <div className={classes.container} style={{display:"none"}}>
              <RelatedPostsSection />
          </div>
          
          <Footer />
        </div>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(BlogPost);
