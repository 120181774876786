//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

// nodejs library that concatenates classes
import classNames from "classnames";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';

//hanyu
import LessonCard from "components/LessonCard/LessonCard.jsx";
//import StudentProfile from "components/StudentProfile/StudentProfile.jsx";

//images
import blackLogo from "assets/img/blackLogo.png";

import Parallax from "components/Parallax/Parallax.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');


const dashboardRoutes = [];

class CoursePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      course: { English: ' '},
      coverPhoto: "",
      opacity: 0,
      featuredImage: { Description:"", Attribution:""}
    }

    
    db.getCourse(this.props.match.params.id).then((course) => {
      this.state.course = course;
      this.state.coverPhoto = course.Photo.URL;
      this.state.featuredImage = course.Photo;
      this.state.opacity = 1;
    });
    db.getLessonsByCourse(this.props.match.params.id).then(lessons => {
           
        this.setState({lessons: lessons});
    });
  }

  render() {
    const { classes, ...rest } = this.props;
    
    return (

      <div>
        <CircularProgress color="inherit" style={{position:'fixed',top:0,bottom:0,left:0,right:0,width:'54px',height:'54px',opacity:0.5,margin:'auto'}} />
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',left:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity,transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "dark"
            }}
            {...rest}
          />
          <Parallax image={this.state.coverPhoto}>
            <div className={classes.container} style={{position: "static", marginBottom:"89px"}}>
              
            </div>
          </Parallax>
          <div style={{backgroundImage:"linear-gradient(black, white)"}}>
          <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#efefef", marginTop:"-150px"}}>
            <div className={classes.container}>
              
              <div className={classes.section} >

                  <div className={classes.photoDesc}>
                    {this.state.featuredImage.Description} Photo by {this.state.featuredImage.Attribution}.
                  </div>

                  <GridContainer justify="center" >
                      <GridItem xs={12} sm={12} md={12} style={{marginTop:"50px"}}>
                          
                          <br />

                          <h3 classes={[classes.title, classes.black]}>{this.state.course.English}</h3>
                          <div>{this.state.course.Description}</div>

                          <GridContainer>
                          {this.state.lessons.map(lesson => (
                              <LessonCard key={lesson.id} lesson={lesson} />
                          ))}
                          </GridContainer>
                          <br /><br /><br /><br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} style={{display:"none"}}>
                          <br />
                          <h3 classes={[classes.title, classes.black]}>Profile</h3>

                          
                      </GridItem>
                  </GridContainer>
              </div>
            </div>
          </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

CoursePage = connect(mapStateToProps)(CoursePage);
export default withStyles(landingPageStyle)(CoursePage);
