//react
import React from "react";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux'

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//charts
import Flashcards from './Charts/Flashcards.jsx';
import HSKProgress from './Charts/HSKProgress.jsx';
import SiteVisits from './Charts/SiteVisits.jsx';

//icons
import Translate from "@material-ui/icons/Translate";
import Hearing from "@material-ui/icons/Hearing";
import Total from "@material-ui/icons/ShowChart";

  

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);
  
    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate());
  }

class StudentProfile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            reading: 0,
            listening: 0,
            total: 0,
            imageURL: '',
            siteVisits: {data: {labels: [], series:[] }, highValue: 20},
            visitsThisWeek: 0,
            flashcardExercises: {data: {labels: [], series:[] }, highValue: 20},
            flashcardExercisesThisWeek: 0,
            history: [],
            l1: {data: {labels: [], series:[] }, lastValue: 0},
            l2: {data: {labels: [], series:[] }, lastValue: 0},
            l3: {data: {labels: [], series:[] }, lastValue: 0},
            l4: {data: {labels: [], series:[] }, lastValue: 0},
            estimates: { 
                l1: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
                l2: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
                l3: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
                l4: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
                l5: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
                l6: {  points: 0, avgGain: 0, daysToMaster: 0, target: 0},
            }
        };

        
        this.refreshProgress = this.refreshProgress.bind(this);
    }

    handleClickOpen(modal) {
        this.refreshProgress();

        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    refreshProgress() {

        db.getActivityByUser(this.props.user.id).then(activity => {
            this.summarizeActivityRecords(activity);
        });

        db.getProgressByUser(this.props.user.id).then(progressReports => {
           
            var reading = 0;
            var listening = 0;

            for(var j = 0; j < progressReports.length; j++) {
                for(var i = 0; i < progressReports[j].Definitions.length; i++) {
                    reading = reading + progressReports[j].Definitions[i].ConfidenceReading;
                    listening = listening + progressReports[j].Definitions[i].ConfidenceListening;
                }
            };

            this.setState({reading: reading, listening: listening, total: reading + listening});
        });

        db.getProgressHistoryByUser(this.props.user.id).then(history => {
            
            var estimates = {
                
            }

            const l1points = 900;
            const l2points = 900;
            const l3points = 1800;
            const l4points = 3600;
            const l5points = 7800;
            const l6points = 15000;

            if(history.length >0) {

                var firstDay = new Date(history[0].date);
                var lastDay = new Date(history[history.length - 1].date);
                var timeDiff = lastDay.getTime() - firstDay.getTime();
                var numDays = timeDiff / (1000 * 3600 * 24);

                estimates.l1 = this.getEstimates(history, numDays, 'l1', l1points);
                estimates.l2 = this.getEstimates(history, numDays, 'l2', l2points);
                estimates.l3 = this.getEstimates(history, numDays, 'l3', l3points);
                estimates.l4 = this.getEstimates(history, numDays, 'l4', l4points);
                estimates.l5 = this.getEstimates(history, numDays, 'l5', l5points);
            }

            history.forEach(item => {
                item.l1.percentage = ((item.l1.listening + item.l1.reading) / l1points * 100).toFixed(1);
                item.l2.percentage = ((item.l2.listening + item.l2.reading) / l2points * 100).toFixed(1);
                item.l3.percentage = ((item.l3.listening + item.l3.reading) / l3points * 100).toFixed(1);
                item.l4.percentage = ((item.l4.listening + item.l4.reading) / l4points * 100).toFixed(1);
                item.l5.percentage = ((item.l5.listening + item.l5.reading) / l5points * 100).toFixed(1);
                item.l6.percentage = ((item.l6.listening + item.l6.reading) / l6points * 100).toFixed(1);
            })

            this.setState({history: history, estimates: estimates}, () => this.summarizeHSKProgress());
        });
    }

    getEstimates(history, numDays, level, target) {

        const firstScore = history[0][level].listening + history[0][level].reading;
        const lastScore = history[history.length -1][level].listening + history[history.length -1][level].reading;

        var estimates = {
            points: lastScore,
            avgGain: (lastScore - firstScore) / numDays,
            daysToMaster: 0,
            target: target
        }
        
        if(estimates.avgGain > 0)
            estimates.daysToMaster = ((target - lastScore) / estimates.avgGain).toFixed(0);

        return estimates;
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    incrementArray(array, labels, date) {
        for(var i = 0; i < labels.length; i++) {
          if(labels[i].getMonth() === date.getMonth()
            && labels[i].getFullYear() === date.getFullYear()
            && labels[i].getDate() === date.getDate()
          ) {
            array[i] = array[i] + 1;
          }
        }
    }

    summarizeHSKProgress() {

        var labels = [];
        var dates = [];
        var l1series = [];
        var l2series = [];
        var l3series = [];
        var l4series = [];

        var today = Date();
        for(var h = 7; h > -1; h--) {
          var calculatedDate = this.addDays(today, -h);
          labels.push(calculatedDate.getMonth() + 1 + "/" + calculatedDate.getDate());
          dates.push(calculatedDate);
    
          var dateString = getFormattedDate(calculatedDate);
          
          var isFound = false;
          for(var i = 0; i < this.state.history.length; i++) {
            if(this.state.history[i].date === dateString) {
                l1series.push(this.state.history[i].l1.percentage);
                l2series.push(this.state.history[i].l2.percentage);
                l3series.push(this.state.history[i].l3.percentage);
                l4series.push(this.state.history[i].l4.percentage);
                isFound = true;
                break;
            }
          }
          if(!isFound) {
              l1series.push(0);
              l2series.push(0);
              l3series.push(0);
              l4series.push(0);
          }
            
          
        }

        var l1 = this.state.l1;
        l1.lastValue = l1series[l1series.length -1];
        l1.data.series = [l1series];
        l1.data.labels = labels;

        var l2 = this.state.l2;
        l2.lastValue = l2series[l2series.length -1];
        l2.data.series = [l2series];
        l2.data.labels = labels;

        var l3 = this.state.l3;
        l3.lastValue = l3series[l3series.length -1];
        l3.data.series = [l3series];
        l3.data.labels = labels;

        var l4 = this.state.l4;
        l4.lastValue = l4series[l4series.length -1];
        l4.data.series = [l4series];
        l4.data.labels = labels;

        this.setState({l1: l1, l2: l2, l3: l3, l4: l4});

    }


    summarizeActivityRecords(records) {

        var labels = [];
        var dates = [];
        var siteVisits = [];
        var flashcardExercises = [];
        var lessonViews = [];
        var worksheets = [];
        var glimpseViews = [];
        var videoViews = [];
    
        var oneWeekAgo = this.addDays(new Date(), -7).getTime();
        var totalVisits = 0;
        var totalVideoViews = 0;
        var totalGlimpseViews = 0;
        var totalFlashcardExercises = 0;
    
        var today = Date();
        for(var h = 6; h > -1; h--) {
          var calculatedDate = this.addDays(today, -h);
          labels.push(calculatedDate.getMonth() + 1 + "/" + calculatedDate.getDate());
          dates.push(calculatedDate);
    
          siteVisits.push(0);
          flashcardExercises.push(0);
          lessonViews.push(0);
          worksheets.push(0);
          glimpseViews.push(0);
          videoViews.push(0);
        }

        for(var i = 0; i < records.length; i++) {
          var ts = new Date(records[i].modified*1000);

          if((records[i].modified * 1000) > oneWeekAgo) {
    
            if(records[i].ActivityType === 'Lesson-Viewed') {
                this.incrementArray(lessonViews, dates, ts);
              
            } else if(records[i].ActivityType === 'Flashcards-Listening' 
                || records[i].ActivityType === 'Flashcards-Reading') {
                this.incrementArray(flashcardExercises, dates, ts);
                totalFlashcardExercises = totalFlashcardExercises + 1;

            } else if(records[i].ActivityType === 'Login-App'
                || records[i].ActivityType === 'Login-App-Alt') {
                this.incrementArray(siteVisits, dates, ts);
                totalVisits = totalVisits + 1;
    
            } else if(records[i].ActivityType === 'Worksheet-PDF-Created') {
                this.incrementArray(worksheets, dates, ts);
    
            } else if(records[i].ActivityType === 'Video-Played') {
                this.incrementArray(videoViews, dates, ts);
                totalVideoViews = totalVideoViews + 1;
            } else if(records[i].ActivityType === 'Glimpse-Played') {
              this.incrementArray(glimpseViews, dates, ts);
              totalGlimpseViews = totalGlimpseViews + 1;
            }
          }
        }
    
        this.setState({visitsThisWeek: totalVisits});
        this.setState({videoViewsThisWeek: totalVideoViews});
        this.setState({glimpseViewsThisWeek: totalGlimpseViews});
        this.setState({flashcardExercisesThisWeek: totalFlashcardExercises});
    
        var lessonViewsHigh = 0;
        for(var w = 0; w < lessonViews.length; w++)
          if(lessonViews[w] > lessonViewsHigh)
            lessonViewsHigh = lessonViews[w];
    
        var flashcardsHigh = 0;
        for(var x = 0; x < flashcardExercises.length; x++)
          if(flashcardExercises[x] > flashcardsHigh)
            flashcardsHigh = flashcardExercises[x];
    
        var visitsHigh = 0;
        for(var y = 0; y < siteVisits.length; y++)
          if(siteVisits[y] > visitsHigh)
            visitsHigh = siteVisits[y];
    
        var worksheetsHigh = 0;
        for(var z = 0; z < worksheets.length; z++)
          if(worksheets[z] > worksheetsHigh)
            worksheetsHigh = worksheets[z];
    
        var videoViewsHigh = 0;
        for(var v = 0; v < videoViews.length; v++)
          if(videoViews[v] > videoViewsHigh)
            videoViewsHigh = videoViews[v];
          
        var glimpseViewsHigh = 0;
        for(var u = 0; u < glimpseViews.length; u++)
          if(glimpseViews[u] > glimpseViewsHigh)
            glimpseViewsHigh = glimpseViews[u];
                
        this.setState({
            siteVisits:          {data: { labels: labels, series: [siteVisits] }, highValue: visitsHigh * 1.1 }, 
            flashcardExercises:  {data: { labels: labels, series: [flashcardExercises] }, highValue: flashcardsHigh * 1.1 },
            lessonViews:         {data: { labels: labels, series: [lessonViews] }, highValue: lessonViewsHigh * 1.1 },
            worksheets:          {data: { labels: labels, series: [worksheets] }, highValue: worksheetsHigh * 1.1 },
            videoViews:          {data: { labels: labels, series: [videoViews] }, highValue: videoViewsHigh * 1.1 },
            glimpseViews:        {data: { labels: labels, series: [glimpseViews] }, highValue: glimpseViewsHigh * 1.1 }
        });
    }

    render() {

        const { classes } = this.props;
        
        return (
            <div>
                <div aria-label="Profile" 
                    style={{cursor:"pointer"}}
                    onClick={() => this.handleClickOpen("modal")}>
                    <div >Progress</div>
                </div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.state.modal}
                    keepMounted
                    fullWidth
                    maxWidth="md"
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        style={{backgroundColor:"#efefef"}} >

                        <GridContainer direction="row">
                            <GridItem xs={12} sm={12} md={6}>
                            <h4 className={classes.modalTitle}>{this.props.user.firstName}&nbsp;{this.props.user.lastName} </h4>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                            
                            </GridItem>
                        </GridContainer>
                    </DialogTitle>
                    
                    <DialogContent style={{backgroundColor:"#efefef"}}>
                        <GridContainer>

                            <GridItem xs={12} sm={6}>
                                <HSKProgress level={1} data={this.state.l1.data} percentComplete={this.state.l1.lastValue} estimates={this.state.estimates.l1} />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <HSKProgress level={2} data={this.state.l2.data} percentComplete={this.state.l2.lastValue}  estimates={this.state.estimates.l2} />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <HSKProgress level={3} data={this.state.l3.data} percentComplete={this.state.l3.lastValue}  estimates={this.state.estimates.l3} />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <HSKProgress level={4} data={this.state.l4.data} percentComplete={this.state.l4.lastValue}  estimates={this.state.estimates.l4} />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={4} > 
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                        <CardIcon color="warning">
                                            <Translate style={{color:"white"}} />
                                        </CardIcon>
                                        <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Reading</p>
                                        <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                            {this.state.reading}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter >
                                        <div className={classes.stats}>
                                            
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={4} > 
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                        <CardIcon color="warning">
                                            <Hearing style={{color:"white"}} />
                                        </CardIcon>
                                        <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Listening</p>
                                        <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                            {this.state.listening}
                                        </h3>
                                    </CardHeader>
                                    <CardFooter >
                                        <div className={classes.stats}>
                                            
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={4} > 
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                        <CardIcon color="warning">
                                            <Total style={{color:"white"}} />
                                        </CardIcon>
                                        <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Total Points</p>
                                        <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                            {this.state.listening + this.state.reading} 
                                        </h3>
                                    </CardHeader>
                                    <CardFooter >
                                        <div className={classes.stats}>
                                            
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6}>
                                <SiteVisits data={this.state.siteVisits.data} highValue={this.state.siteVisits.highValue} thisWeek={this.state.visitsThisWeek} />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <Flashcards data={this.state.flashcardExercises.data} highValue={this.state.flashcardExercises.highValue} thisWeek={this.state.flashcardExercisesThisWeek} />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    
                    <DialogActions style={{ paddingRight:"20px" }}
                        className={classes.modalFooter } >
                            <Button
                                onClick={() => {
                                    this.handleClose("modal");
                                }}
                                color="success">
                                OK
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

StudentProfile = connect(mapStateToProps)(StudentProfile);
export default withStyles([modalStyle,dashboardStyle])(StudentProfile);
