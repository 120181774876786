//react
import React from 'react';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//hanzi-writer
//import HanziWriter from 'hanzi-writer';

class ChineseWord extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true,
            showDetail: false,
        }
        
        this.playAudio = this.playAudio.bind(this);

        this.state.popoverWidth = "106px";
        if(this.props.vocabulary.Pinyin && this.props.vocabulary.English) {
            if(this.props.vocabulary.Pinyin.length > this.props.vocabulary.English.length) {
                this.state.popoverWidth = (this.props.vocabulary.Pinyin.length * 16) + "px";
            } else {
            this.state.popoverWidth = (this.props.vocabulary.English.length * 16) + "px";
            }
        }
    }

    playAudio(url) {
        if(!url)
            url = this.props.vocabulary.AudioURL;

        this.audio = new Audio(url);
        this.audio.play();
        
        //var confidence = this.props.vocabulary.Progress.ConfidenceReading + this.props.vocabulary.Progress.ConfidenceListening;
        
    }

    colorCoding() {
        if(this.props.vocabulary.Progress) {
            var confidence = this.props.vocabulary.Progress.ConfidenceReading + this.props.vocabulary.Progress.ConfidenceListening;
            if(confidence > 4) {
                return "#3fa31b";
            } else if (confidence > 2) {
                return "#898989";
            } else {
                return "#232323";
            }
        } else return "#000000";
    }

    mouseOver() {
        this.setState({showDetail: true})
    }

    mouseOut() {
        this.setState({showDetail: false})
    }

    isPunctuation() {
        if(
            this.props.vocabulary.English === '.' ||
            this.props.vocabulary.English === ',' ||
            this.props.vocabulary.English === '?' ||
            this.props.vocabulary.English === '!' ||
            this.props.vocabulary.English === ':' ||
            this.props.vocabulary.English === ';' ||
            this.props.vocabulary.English === '%' 
        ) return true;
        else return false;
    }

    showMouseOver() {
        if(this.state.showDetail && !this.isPunctuation())
            return "block";
        else
            return "none";
    }

    showPinyin() {
        if((this.props.showPinyin || this.props.highlight) && !this.isPunctuation())
            return "block";
        else
            return "none";
    }

    hanziColor() {
        if(this.props.highlight)
            return "#ffffff";
        else
            return "#000000"; 
    }

    pinyinColor() {
        if(this.props.highlight)
            return "#aef293";
        else
            return "#3fa31b"; 
    }

    render() {

      return (
        <div style={{display:"inline-block", marginRight:"0px", textAlign:"center", padding:"6px", verticalAlign: "top" }} >
        {/*}
            <Card style={{padding:"6px", marginTop:"-10px", , 
                boxShadow: "0 16px 38px -12px rgba(0, 200, 0, 0.56), 0 4px 25px 0px rgba(0, 100, 0, 0.12), 0 8px 10px -5px rgba(0, 100, 0, 0.2)"}} >
      */}     
                
                <div style={{fontSize:"23px", color:this.hanziColor(), cursor:"pointer" }} 
                    onClick={() => this.playAudio()}
                    onMouseOver={() => this.mouseOver()}
                    onMouseOut={() => this.mouseOut()}
                    >
                    {this.props.vocabulary.Simplified}
                </div>
                <div style={{fontSize:"13px", fontWeight:400, color:this.pinyinColor(), marginTop:"-3px", display:this.showPinyin() }} >
                    {this.props.vocabulary.Pinyin}
                </div>
                

                <div style={{backgroundColor:"#75bb78", position:"absolute", padding:"17px", zIndex:1024000,
                        borderColor:'#3fa31b', borderWidth:"1px", borderStyle:"solid", borderRadius:'6px', 
                        minWidth:"106px", maxWidth:"369px", width:this.state.popoverWidth, transform: "translate(-40%, 0%)",
                        display:this.showMouseOver()}}>
                    <div style={{fontSize:"34px", color:"#ffffff" }} >
                        {this.props.vocabulary.Simplified}
                    </div>
                    <div style={{fontSize:"13px", color:"#ffffff", marginTop:"6px" }} >
                        {this.props.vocabulary.Pinyin}
                    </div>
                    <div style={{fontSize:"13px", color:"#efefef", marginTop:"-3px", lineHeight:"15px"}} >
                        {this.props.vocabulary.English}
                    </div>
                </div>
                
            
        </div>
      );
    }
  }
 
export default withStyles(modalStyle)(ChineseWord);