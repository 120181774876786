import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
  apiKey: "AIzaSyDXfUajH7w7MJ3-3h1AElD6Xwnu6FSR5hg",
  authDomain: "kauai-7b54d.firebaseapp.com",
  databaseURL: "https://kauai-7b54d.firebaseio.com",
  projectId: "kauai-7b54d",
  storageBucket: "kauai-7b54d.appspot.com",
  messagingSenderId: "556586326340"
};
  
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  const auth = firebase.auth();
  const db = firebase.firestore();

export {
  auth,
  db
};