/*eslint-disable*/
import React from "react";

//redux
import { connect } from 'react-redux'

// react components for routing our app without refresh
import { Link } from "react-router-dom";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

// core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

//components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

//hanyu
import Feedback from "components/Feedback/Feedback.jsx";
import StudentProfile from "components/StudentProfile/StudentProfile.jsx";

//icons
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
import Translate from "@material-ui/icons/Translate";
import Flashcard from "@material-ui/icons/Description";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
  }
  

  coursesLink() {
    if (this.props.user.authenticated) {
      return "/courses";
    } else {
      return "/login"
    }
  }

  coursesText() {
    if (this.props.user.authenticated && !this.props.user.classID) {
      return "Courses";
    } else {
      return ""
    }
  }

  showLogin() {
    if(!this.props.user.authenticated) {
      return "block";
    } else {
      return "none";
    }
  }

  showUserMenu() {
    if(this.props.user.authenticated) {
      return "block";
    } else {
      return "none";
    }
  }

  showProgress() {
    if (this.props.user.authenticated) {
      return "inline-block";
    } else {
      return "none"
    }
  }

  render() {
    const { classes, ...rest } = this.props;
 
    return (
      
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link to="/blog" className={classes.navLink}>
            Blog
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to={this.coursesLink()} className={classes.navLink}>
            {this.coursesText()}
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/dictionary" className={classes.navLink}>
            Dictionary
          </Link>
        </ListItem>
        {/*
        <ListItem className={classes.listItem}>
          <div className={classes.navLink}>
            <Feedback />
          </div>
        </ListItem>
        */}
        <ListItem className={classes.listItem} style={{display:this.showLogin()}}>
          <Link to="/login" className={classes.navLink}>
            Login
          </Link>
        </ListItem>
        <ListItem className={classes.listItem} style={{display:this.showUserMenu()}}>
          <div className={classes.navLink} >
            <StudentProfile />
          </div>
        </ListItem>
{/*}
        <ListItem className={classes.listItem} style={{display:this.showUserMenu()}}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor="success"
            buttonText={this.props.user.firstName}
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Person}
            dropdownList={[
              <Link to="/decks" className={classes.dropdownLink} style={{display:"none"}}>
                <Flashcard className={classes.dropdownIcons} />
                <div style={{display:"inline-block", position:"relative", top:"-6px", left:"6px"}}>Practice Decks</div>
              </Link>,
              <div className={classes.dropdownLink} >
                <StudentProfile />
              </div>,
              <Link to="/vocabulary" className={classes.dropdownLink} style={{display:"none"}} >
                <Translate className={classes.dropdownIcons} /> 
                <div style={{display:"inline-block", position:"relative", top:"-6px", left:"6px"}}>Vocabulary</div>
              </Link>,
              <Link to="/logout" className={classes.dropdownLink}>
                <Lock className={classes.dropdownIcons} />
                <div style={{display:"inline-block", position:"relative", top:"-6px", left:"6px"}}>Logout</div>
              </Link>
            ]}
          />
        </ListItem>*/}
      </List>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

HeaderLinks = connect(mapStateToProps)(HeaderLinks);
export default withStyles(headerLinksStyle)(HeaderLinks);