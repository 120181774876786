import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import coverPhoto from "assets/img/bg.jpg"
import logo from "assets/img/logo.png"

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

class TempPage extends React.Component {

  render() {
    const { classes } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);
    
    return (
      <div style={{
        backgroundImage: `url(${coverPhoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom:"0px"

      }}>
        
          <div className={classes.container} style={{position: "static", marginTop:"100px"}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <img src={logo} style={{height:"50px"}} alt="logo" />
                <h4 style={{color:"#efefef", display:"none"}}>
                  Learn a new language.<br />
                  Discover an entirely new world.
                </h4>
                <br />
                
              </GridItem>
              <br /><br />
            </GridContainer>
          </div>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(TempPage);