//react
import React from 'react';

//redux
//import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Play from "@material-ui/icons/PlayCircleFilled";
import Detail from "@material-ui/icons/MoreHoriz";

//hanzi-writer
//import HanziWriter from 'hanzi-writer';
import ChineseWord from "components/ChineseWord/ChineseWord.jsx";

//google Analytics
//import ReactGA from 'react-ga';
//ReactGA.initialize('UA-54225103-1');


function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  
  class Sentence extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            vocabulary: props.vocabulary,
            sentence: props.sentence,
            showTranslation: false
        };
      
        this.playAudio = this.playAudio.bind(this);
        
        this.containerDiv = React.createRef();
    }

    componentDidUpdate() {
       // if(this.props.sentence.highlight)
         //   this.containerDiv.current.scrollIntoViewIfNeeded(true);
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    playAudio(url) {
        if(this.props.onPlay) {
            this.props.onPlay(this.state.sentence);
        } else {
            if(!url)
                url = this.props.audioURL;

            this.audio = new Audio(url);
            this.audio.play();
        }
    }

    backgroundColor() {
        if(this.highlighted())
            return "#898989";//"#75bb78";
        else
            return this.props.backgroundColor;
    }

    promptColor() {
        if(this.highlighted())
            return "#ffffff";
        else
            return "#3fa31b";
    }

    englishColor() {
        if(this.highlighted())
            return "#eeeeee";
        else
            return "#696969";
    }

    highlighted() {
        if(this.props.sentence.highlight || this.props.alwaysHighlight) 
            return true;
        else
            return false;   
    }

    hideTranslation() {
        this.setState({showTranslation: false})
    }

    showTranslation() {
        this.setState({showTranslation: true})
    }

    translation() {
        if(this.state.showTranslation || this.highlighted() )
            return "block";
        else
            return "none";
    }

    translationPrompt() {
        if(this.state.showTranslation || this.highlighted() )
            return "none";
        else
            return "block";
    }

    showPlayButton() {
        if(this.props.hidePlayButton)
            return "none";
        else
            return "block";
    }

    render() {

      const { classes } = this.props;

      return (
        <div ref={this.containerDiv} style={{borderRadius:"9px",backgroundColor: this.backgroundColor()}}>
            <GridContainer>
                <GridItem xs={1} sm={1} >
                    <div style={{marginTop:"10px",display:this.showPlayButton()}}>
                        <Tooltip id="tooltip-top" title="Play Sentence" placement="top" classes={{ tooltip: classes.tooltip}} >
                            <IconButton aria-label="Play" onClick={() => this.playAudio()}>
                                <Play className={ classes.tableActionButtonIcon + " " + classes.edit } style={{fontSize:"34px"}} />  
                            </IconButton>
                        </Tooltip>
                    </div>
                </GridItem>
                <GridItem xs={1} sm={1} style={{display:"none"}}>
                    <Tooltip id="tooltip-top" title="See More Details" placement="top" classes={{ tooltip: classes.tooltip }} >
                        <IconButton aria-label="More" onClick={() => this.handleClickOpen("modal")}>
                            <Detail className={ classes.tableActionButtonIcon + " " + classes.edit } />
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem xs={10} style={{paddingBottom:"10px"}}>
                    <div style={{fontSize:"21px", marginTop:"13px", position:"relative"}}>
                        {this.props.sentence.Definitions.map((value,index) => (
                            <ChineseWord key={index} vocabulary={value} showPinyin={this.state.showTranslation} highlight={this.highlighted()} />
                        ))}
                    </div>
                    <div style={{fontSize:"13px", fontWeight:400, marginTop:"0px", marginLeft:"6px", display:this.translation(), cursor:"pointer", color:this.englishColor()}} 
                        onClick={() => this.hideTranslation()}>
                        {this.props.sentence.English}
                    </div>
                    <div style={{fontSize:"12px", fontWeight:400, marginLeft: "6px", color:this.promptColor(), marginTop:"0px", position:"relative", cursor:"pointer", display:this.translationPrompt()}} 
                            onClick={() => this.showTranslation()} >
                        Show Translation
                    </div>
                </GridItem>
            </GridContainer>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.modalTitle}><div id={this.props.sentence.Simplified} ></div></h2>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
                <GridContainer>
                    <GridItem xs={12}>
                        {/*}
                        <h3>Word by Word</h3>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell>
                                        Pinyin
                                    </TableCell>
                                    <TableCell>
                                        English
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.vocabulary.Definitions.map(value => (
                                <TableRow key={value.id} className={classes.tableRow}>
                                    <TableCell className={classes.tableActions}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Play Vocabulary"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <IconButton
                                            aria-label="Play"
                                            onClick={() => this.playAudio()}>
                                            <Play className={ classes.tableActionButtonIcon + " " + classes.edit } />
                                        </IconButton>
                                    </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {value.Pinyin}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {value.English}
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        */}
                    </GridItem>
                </GridContainer>
            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                  <Button
                    onClick={() => {
                        // ?
                        this.handleClose("modal");
                      }}
                    color="success">
                    OK
                  </Button>
            </DialogActions>
          </Dialog>
          
        </div>
      );
    }
  }
 
export default withStyles(modalStyle)(Sentence);