//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserLoggedIn, OrgLoaded, LessonsLoaded, VideosLoaded } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

//icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Camera from "@material-ui/icons/PhotoCamera";

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
//import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

import image from "assets/img/bg3.jpg";
import logo from "assets/img/logo.png";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.needHelp = this.needHelp.bind(this);
    this.cancelHelp = this.cancelHelp.bind(this);
    this.sendPasswordReset = this.sendPasswordReset.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password:"",
      error:null,
      loggedIn: false,
      user: null,
      showHelp: false
    };
  }

  emailChange(e) {
    this.setState({email: e.target.value});
    
  }
  passwordChange(e) {
    this.setState({password: e.target.value});
  }
  
  onUserLoggedIn(id, email) {
    
    db.getUser(id).then(doc => {

      var user = doc.data();
      this.setState({user:user}, () => {
        this.props.onUserLoggedIn({id:id, email: email, firstName: user.FirstName, lastName: user.LastName, classID: user.ClassID, schoolID: user.SchoolID, orgID: user.orgId });
        db.logActivity(id, user.orgId, user.SchoolID, user.ClassID, "Login-App");
        
        db.getOrganization(user.orgId).then(doc => {
          var org = doc.data();
  
          this.props.onOrgLoaded({
            id: doc.id,
            name: org.Name,
            description: org.Description
          });

          this.setState({loggedIn: true});
        /*
          db.getLessons(user.ClassID).then(snapshot => {
             
            var lessons = [];
            snapshot.forEach((doc) => {
      
                var lesson = doc.data();
                lesson.id = doc.id;
                if(!lesson.Photos)
                  lesson.Photos = [];
                lessons.push(lesson);
      
                this.props.onLessonsLoaded(lessons);
  
                db.getVideos().then(snapshot => {
             
                  var videos = [];
                  snapshot.forEach((doc) => {
            
                      var video = doc.data();
                      video.id = doc.id;
                      videos.push(video);
              
                  });
  
                  this.props.onVideosLoaded(videos);
  
                  // now run!
                  
                });
            });
          });
          */
        });
      });
    });
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    document.getElementById('divErrors').style.display='none';

    auth.doSignInWithEmailAndPassword(email, password)
      .then(authUser => {

        this.onUserLoggedIn(authUser.user.uid, authUser.user.email);
    
      })
      .catch(error => {

        if(error.code === "auth/user-not-found") {
          this.setState({ error: "This email and password combination was not found." });
        }
        else {
          this.setState({ error: error.message });  
        }
        document.getElementById('divErrors').style.display='block';
      });

    event.preventDefault();
  }

  sendPasswordReset() {
    auth.sendPasswordReset(this.state.email);
    console.log('sending password reset to ' + this.state.email);
    this.cancelHelp();
  }

  cancelHelp() {
    this.setState({showHelp: false});
  }

  needHelp() {
    this.setState({showHelp: true});
  }

  showHelp() {
    if(this.state.showHelp) {
      return "flex";
    } else {
      return "none";
    }
  }

  showLogin() {
    if(!this.state.showHelp) {
      return "flex";
    } else {
      return "none";
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    if (this.state.loggedIn === true) {
      if(this.state.user.ClassID)
        return <Redirect to='/lessons' />
      else 
        return <Redirect to='/courses' />
    }

    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={4}>
                <Card className={classes[this.state.cardAnimaton]} style={{display:this.showLogin(), minHeight:"389px"}}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Login</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                      />
                      <CustomInput
                        labelText="Password"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.passwordChange}
                      />
                    </CardBody>
                    <div id='divErrors' className={classes.divider} style={{display: 'none'}}>
                      {this.state.error}
                    </div>
                    <CardFooter className={classes.cardFooter}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <br />
                          <Button color="primary"  size="lg" type="submit" fullWidth>
                            Login
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <Button color="primary" simple size="lg" onClick={this.needHelp} fullWidth>
                            Need Help?
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </form>
                </Card>
                <Card className={classes[this.state.cardAnimaton]} style={{display:this.showHelp(), minHeight:"389px"}}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Help</h4>
                    </CardHeader>
                    <CardBody>
                      <br />
                      If you forgot your password we can send and email
                      to the address on file so you can reset it.
                      <br />
                      <CustomInput
                        labelText="Email"
                        id="email"                    
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                        value={this.state.email}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter} >
                      <GridContainer>
                        <GridItem xs={12}>
                          <br />
                          <Button color="primary" size="lg" fullWidth onClick={this.sendPasswordReset}>
                            Send Password Reset Email
                          </Button>
                        </GridItem>
                        <GridItem xs={12}>
                          <Button color="primary" simple size="lg" onClick={this.cancelHelp} fullWidth>
                            Go Back
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} style={{textAlign:"center"}}>
                <img src={logo} style={{width:"100px"}} alt="logo" />
                <div style={{position:"fixed", right:12, bottom: 3}} ><Tooltip placement='left' title="This photo of the Long Corridor at Beijing's Summer Palace was taken by Alberto Carrasco Casado, CC-BY-2.0."><Camera style={{color:"#cdcdcd"}} /></Tooltip></div> 
              </GridItem>
            </GridContainer>
          </div>
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn, onOrgLoaded: OrgLoaded, 
    onLessonsLoaded: LessonsLoaded, onVideosLoaded: VideosLoaded }, dispatch);  
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(loginPageStyle)(LoginPage);
