//react
import React from "react";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import IconButton from "@material-ui/core/IconButton";

//hanyu
import Sentence from "components/Sentence/Sentence.jsx";

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";

//icons
import Play from "@material-ui/icons/PlayCircleFilled";
import Pause from "@material-ui/icons/PauseCircleFilled";

//video player
import ReactPlayer from 'react-player'

class GlimpsePlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            glimpse: props.glimpse,
            glimpsePlaying: false,
            
            currentSentence: props.glimpse.Sentences[0],
            stopIndex: 0,
            minIndex: props.glimpse.Sentences[0].AudioIndex,
            maxIndex: props.glimpse.Sentences[props.glimpse.Sentences.length-1].AudioCompletionIndex,
            currentIndex: 0,
            width: 0,
            height: 0,
        };


        this.glimpsePlayer = React.createRef();

        this.glimpseStart = this.glimpseStart.bind(this);
        this.glimpsePlay = this.glimpsePlay.bind(this);
        this.glimpsePlaySentence = this.glimpsePlaySentence.bind(this);
        this.glimpseProgress = this.glimpseProgress.bind(this);
        this.glimpsePause = this.glimpsePause.bind(this);
        this.glimpseVideoClick = this.glimpseVideoClick.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        console.log('maxIndex:' + this.state.maxIndex);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    alternateShading(index) {
        if(index % 2 === 0)
        return "#eeeeee";
        else
        return "#ffffff";
    }

    glimpseReady(status) {
        console.log("onReady");
        //console.log(status);
    }

    glimpseStart() {
        db.logActivity(this.props.user.id, this.props.org.id, this.props.user.schoolID, this.props.user.classID, "Glimpse-Played");
    }

    glimpsePlay() {
        if(this.state.currentIndex < this.state.minIndex)
            this.glimpsePlayer.current.seekTo(this.state.minIndex);

        this.setState({glimpsePlaying: true});
    }

    glimpsePause() {
        this.setState({glimpsePlaying: false});
    }

    glimpsePlaySentence(sentence) {
        this.glimpsePlayer.current.seekTo(sentence.AudioIndex);
        this.setState({glimpsePlaying: true});
        
        if(sentence.AudioCompletionIndex > 0)
            this.setState({stopIndex: sentence.AudioCompletionIndex});
        else {
            for(var i = 0; i < this.state.glimpse.Sentences.length; i++) {
                if(this.state.glimpse.Sentences[i].id === sentence.id) {
                    
                    if(this.state.glimpse.Sentences.length > i) { // there are more sentences
                        this.setState({stopIndex: this.state.glimpse.Sentences[i+1].AudioIndex})
                    }
                }
            }
        }
    }

    glimpseProgress(status) {
        var index = status.playedSeconds;

        // stop at end if only playing one sentence
        if((this.state.stopIndex > 0 && index >= this.state.stopIndex) || (this.state.maxIndex > 0 & index > this.state.maxIndex) ) {
            this.setState({glimpsePlaying: false, stopIndex: 0});
            this.glimpsePlayer.current.seekTo(this.state.minIndex);
        }
            
        var glimpse = this.state.glimpse;
        for (var i = 0; i < glimpse.Sentences.length; i++) {
            if (index > glimpse.Sentences[i].AudioIndex && i === glimpse.Sentences.length - 1) {  //i.e. the last sentence
                glimpse.Sentences[i].highlight = true;            
                this.setState({currentSentence: glimpse.Sentences[i]});
            }
             else if (index > glimpse.Sentences[i].AudioIndex && index < glimpse.Sentences[i + 1].AudioIndex) {
                glimpse.Sentences[i].highlight = true;            
                this.setState({currentSentence: glimpse.Sentences[i]});
             }  
             else 
                glimpse.Sentences[i].highlight = false;
        }

        this.setState({glimpse: glimpse, currentIndex: index});
    }

    glimpseVideoClick() {
        if(this.state.glimpsePlaying) {
            this.setState({glimpsePlaying: false});
        } else 
            this.glimpsePlay();
    }

    render() {
        const { classes } = this.props;

        let PlayerControls;
        if(this.state.glimpsePlaying) 
            PlayerControls = <Pause onClick={this.glimpsePause} style={{color:"white", fontSize:"43px", cursor:"pointer"}} />
        else 
            PlayerControls = <Play onClick={this.glimpsePlay} style={{color: "white", fontSize:"43px", cursor:"pointer"}} />
        

        return (
            <GridContainer direction="row">
                <GridItem xs={12} style={{minHeight:this.state.width * .37, marginBottom:"0px", position:"relative" }} onClick={this.glimpseVideoClick}>
                    
                    <ReactPlayer url={this.state.glimpse.url} width='100%' height='100%'
                        onProgress={this.glimpseProgress}
                        onReady={this.glimpseReady}
                        onStart={this.glimpseStart}
                        progressInterval={23}
                        controls={false}
                        playing={this.state.glimpsePlaying}
                        ref={this.glimpsePlayer}
                        style={{pointerEvents:"none"}}
                    />
                    
                </GridItem>
                <GridItem xs={12} >
                    <GridContainer style={{padding:"10px", backgroundColor:"#898989", borderBottomRightRadius:"6px", borderBottomLeftRadius:"6px", margin:"0px" }}>
                        <GridItem xs={1} >
                            <IconButton aria-label="Play/Pause" >
                                {PlayerControls}
                            </IconButton>
                        </GridItem>
                        <GridItem xs={11}>
                            <Sentence sentence={this.state.currentSentence} audioURL={this.state.currentSentence.AudioURL} 
                                    onPlay={this.glimpsePlaySentence} alwaysHighlight={true} hidePlayButton={true}
                                    backgroundColor="#898989" />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{minHeight:"489px",marginBottom:"17px" }}>
                    <h3>Transcript</h3>
                    <GridContainer className={classes.black} > 
                    
                        {this.state.glimpse.Sentences.map((value, index) => (
                            <GridItem xs={12} key={index} >
                                <Sentence sentence={value} audioURL={value.AudioURL} 
                                    onPlay={this.glimpsePlaySentence}
                                    backgroundColor={this.alternateShading(index)} />
                            </GridItem>
                        ))}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.org,
        user: state.user
    }
  }
  
GlimpsePlayer = connect(mapStateToProps)(GlimpsePlayer);
export default withStyles(cardStyle)(GlimpsePlayer);
