//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserLoggedIn, OrgLoaded } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import People from "@material-ui/icons/People";
import Camera from "@material-ui/icons/PhotoCamera";

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

//images
import image from "assets/img/bg3.jpg";
import logo from "assets/img/logo.png";

class SignupPage extends React.Component {
  constructor(props) {
    super(props);

    this.nameChange = this.nameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.password1Change = this.password1Change.bind(this);
    this.password2Change = this.password2Change.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      name: "",
      email: "",
      password1:"",
      password2:"",
      error:null,
      loggedIn: false
    };
  }

  nameChange(e) {
    this.setState({name: e.target.value});
  }
  emailChange(e) {
    this.setState({email: e.target.value});
  }
  password1Change(e) {
    this.setState({password1: e.target.value});
  }
  password2Change(e) {
    this.setState({password2: e.target.value});
  }

  onUserLoggedIn(id, email) {
    
    db.getUser(id).then(doc => {

      var user = doc.data();

      this.props.onUserLoggedIn({id:id, email: email, firstName: user.FirstName, lastName: user.LastName, classID: user.ClassID, schoolID: user.SchoolID, orgID: user.orgId });
      db.logActivity(id, user.orgId, user.SchoolID, user.ClassID, "Login-App");
      
      db.getOrganization(user.orgId).then(doc => {
        var org = doc.data();

        this.props.onOrgLoaded({
          id: doc.id,
          name: org.Name,
          description: org.Description
        });

        // now run!
        this.setState({loggedIn: true});
      });
    });
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  onSubmit = (event) => {
    event.preventDefault();

    const {
      name,
      email,
      password1,
      password2,
    } = this.state;

    this.setState({error: ''});

    if(name.length < 1) {
        this.setState({error: 'Sorry, what is your name?'});
    } else if (email.length < 1) { 
        this.setState({error: 'An email address is required.'});
    } else if(password1.length < 1) {
        this.setState({error: 'Sorry, you will need a password!'});
    } else if(password1 !== password2) {
        this.setState({error: 'Your passwords do not match.'});
    } else {

        auth.createUserWithEmailAndPassword(email, password1).then(authUser => {
            var user = { "FirstName": name, "EmailAddress": email, Photos: [], orgId: "3RTIq8CvHQ7zfnz1P9no" }
            db.updateUser(authUser.user.uid, user).then((doc) => {
                this.onUserLoggedIn(authUser.user.uid, authUser.user.email);
            });

        }).catch(error => {
            this.setState({error: error.message});
            //console.log(error);
        });
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    if (this.state.loggedIn === true) {
      return <Redirect to='/courses' />
    }

    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Get Started</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="First Name..."
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          endAdornment: (
                            <InputAdornment position="end">
                              <People className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.nameChange}
                      />
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                      />
                      <CustomInput
                        labelText="Password"
                        id="pass1"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.password1Change}
                      />
                      <CustomInput
                        labelText="Verify Password"
                        id="pass2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.password2Change}
                      />
                    </CardBody>
                    <div id='divErrors' className={classes.divider} style={{color:"maroon", padding:"0px", paddingLeft:"10px", paddingRight:"10px", fontSize:"12px"}} >
                      {this.state.error}
                    </div>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" type="submit">
                        Signup
                      </Button>
                    </CardFooter>
                    
                  </form>
                </Card>
              </GridItem>
              <GridItem xs={12} style={{textAlign:"center"}}>
                <img src={logo} style={{width:"100px"}} alt="logo" />
                <div style={{position:"fixed", right:12, bottom: 3}} ><Tooltip placement='left' title="This photo of the Long Corridor at Beijing's Summer Palace was taken by Alberto Carrasco Casado, CC-BY-2.0."><Camera style={{color:"#cdcdcd"}} /></Tooltip></div> 
              </GridItem>
            </GridContainer>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn, onOrgLoaded: OrgLoaded }, dispatch);  
}

SignupPage = connect(mapStateToProps, mapDispatchToProps)(SignupPage);
export default withStyles(loginPageStyle)(SignupPage);
