import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

class TermsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
    
  }

  render() {
    const { classes, ...rest } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax  image={require("assets/img/printingBlock.jpg")}>
          
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            
            <div className={classes.section} >

                <div className={classes.photoDesc}>
                Carving a Chinese printing block. Photo by Jonathan Kos-Read, CC-BY-ND.
                </div>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        
                        <br /><br />

                        <h2 classes={[classes.title, classes.black]}>Terms and Conditions</h2>

                        <br />
                        
Welcome to the HANYU.CO website (the “Site”). HANYU.CO is owned and developed by Spiffy Ventures, L.L.C., an Arizona limited liability company (“Spiffy”). This page explains the terms by which you may use our software, website and online services provided on or in connection with HANYU.CO (collectively the “Services”). 

By visiting our Site and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy ( “Terms and Conditions”), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy available at <a href='/privacy'>https://www.hanyu.co/privacy</a>). If you do not agree to these terms, do not use this Site. 
<br/><br/>
We reserve the right to change these Terms and Conditions from time to time without notice. You acknowledge and agree that it is your responsibility to review these Terms and Conditions periodically to familiarize yourself with any modifications. Your continued use of this Site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions. 
<br/><br/>
The material provided on this Site is protected by law, including, but not limited to, United States copyright law and international treaties. This Site is controlled and operated by Spiffy from its offices within the United States. Spiffy makes no representation that materials in the Site are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access this Site from other locations do so on their own initiative and are responsible for compliance with all applicable local laws. 
<br/><br/>
Information on this Site may be changed or updated without notice. Spiffy may also make improvements and/or changes in the products and/or services described on the Site or herein at any time without notice. 
<br/><br/>
<h5>Responsible Use and Conduct </h5>
If you use the Services you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Spiffy is not responsible for third party access to your account that results from theft or misappropriation of your account. Spiffy and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion. 
<br/><br/>
By visiting our Site and accessing the information, resources, services, products, and tools we provide for you, either directly or indirectly (hereafter referred to as ‘Resources’), you agree to use these Resources only for the purposes intended as permitted by (a) the terms of these Terms and Conditions, and (b) applicable laws, regulations and generally accepted online practices or guidelines. 
<br/><br/>
You understand that:
<br/>
<ol>
<li>In order to access our Resources, you may be required to provide certain information about yourself (such as identification, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.</li>
<li>You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account(s).</li>
<li>Accessing (or attempting to access) any of our Resources by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or unconventional means.</li>
<li>Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.</li>
<li>Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources is strictly prohibited.</li>
<li>You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.</li>
</ol>
<br/>
<h5>Links to Third Party Sites</h5>
This Site may contain links to other sites that are not owned or controlled by Spiffy. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave our Site and to read the privacy statements of each and every website that collects personally identifiable information. This Privacy Policy applies only to information collected by this Site. 
<br/><br/>
<h5>Use of Third Party Sites</h5>
Certain services made available via the Services are delivered by third party sites and organizations. By using any product, service or functionality originating from the Services, you hereby acknowledge and consent that Spiffy may share such information and data with any third party with whom Spiffy has a contractual relationship to provide the requested product, service or functionality on behalf of Services users and customers. 
<br/><br/>
<h5>No Unlawful or Prohibited Use</h5>
You are granted a non-exclusive, non-transferable, revocable license to access and use the Services strictly in accordance with these Terms and Conditions. As a condition of your use of the Services, you warrant to Spiffy that you will not use the Services for any purpose that is unlawful or prohibited by these Terms and Conditions. You may not use the Services in any manner which could damage, disable, overburden, or impair the Services or interfere with any other party's use of the Services. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Services. 
<br/><br/>
<h5>Intellectual Property</h5>
All content contained on the Site or included as part of the Services, such as text, graphics, logos, images, as well as the compilation thereof, and any software used for the Services is the property of Spiffy or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright, trademark and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto. 
<br/><br/>
You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit the software and services that makeup the Services. You will not upload, post or otherwise make available on this Site any material protected by copyright, trademark or other proprietary right without the express permission of the owner of the copyright, trademark or other proprietary right. You will indemnify Spiffy for any claims by third parties of infringement of copyrights, trademarks, proprietary rights or any other harm resulting from such a submission of material by you. 
<br/><br/>
The Services are not for resale. Your use of the Services does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. We do not grant you any licenses, express or implied, to the intellectual property of Spiffy or our licensors except as expressly authorized by these Terms and Conditions. No Spiffy service or product available through this Site, or any part of any Spiffy service or product, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Spiffy. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Spiffy without express written consent. You may not use any meta tags or any other “hidden text” utilizing Spiffy’ name or trademarks without the express written consent of Spiffy. You may not misuse the Spiffy services and products. You may use the Spiffy services and products only as permitted by law. The licenses granted by Spiffy terminate if you do not comply with this Agreement. 
<br/><br/>
<h5>Use of Communication Services</h5>
The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Services. Any material, information, or idea you transmit to or post on this Site by any means will be treated as non-confidential and non-proprietary, and may be disseminated or used by Spiffy or its affiliates for any purpose whatsoever, including, but not limited to, developing and marketing its products and services. 
<br /><br />
By way of example, and not as a limitation, you agree that when using the Communication Services, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Services specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Services that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Services; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations. 
<br /><br />
Spiffy has no obligation to monitor the Communication Services. However, Spiffy reserves the right to review materials posted to a Communication Services and to remove any materials in its sole discretion. Spiffy reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever. 
<br /><br />
We do not assume any liability for any content posted by you or any other third party users of our website. However, any content posted by you using any open communication tools on our Site, provided that it doesn’t violate or infringe on any third party copyrights or trademarks, becomes the property of Spiffy, and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described, and does not refer to information that is provided as part of the registration process, necessary in order to use our Resources. All information provided as part of our registration process is covered by our Privacy Policy. 
<br /><br />
We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with these Terms and Conditions , along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any third party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, and consent to waive any claim against us. 
<br /><br />
Spiffy reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Spiffy's sole discretion. 
<br /><br />
Spiffy does not control or endorse the content, messages or information found in any Communication Services and, therefore, Spiffy specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Services. 
<br /><br />
Materials uploaded to a Communication Services may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials. 
<br /><br />
<h5>Indemnification</h5>
You agree to indemnify, defend and hold harmless Spiffy, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of your violation of these Terms and Conditions , use of or inability to use the Services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Spiffy reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Spiffy in asserting any available defenses. 
<br /><br />
<h5>Liability Disclaimer, Warranties</h5>
THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. 
<br /><br />
WITHOUT LIMITING THE FOREGOING, SPIFFY, ITS SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE. 
<br /><br />
IN CONJUNCTION WITH THE LIMITATION OF WARRANTIES AS EXPLAINED ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT ANY CLAIM AGAINST US SHALL BE LIMITED TO THE AMOUNT YOU PAID, IF ANY, FOR USE OF PRODUCTS AND/OR SERVICES. SPIFFY WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY LOSS OR DAMAGES WHICH MAY BE INCURRED BY YOU AS A RESULT OF USING OUR RESOURCES, OR AS A RESULT OF ANY CHANGES, DATA LOSS OR CORRUPTION, CANCELLATION, LOSS OF ACCESS, OR DOWNTIME TO THE FULL EXTENT THAT APPLICABLE LIMITATION OF LIABILITY LAWS APPLY. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS AND CONDITIONS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE. 
<br /><br />
<h5>Governing Law, Miscellaneous</h5>
To the maximum extent permitted by law, these Terms and Conditions is governed by the laws of the state of Arizona and you hereby consent to the exclusive jurisdiction and venue of courts in Arizona in all disputes arising out of or relating to the use of the Services. Use of the Services is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms and Conditions, including, without limitation, this section. 
<br /><br />
You agree that no joint venture, partnership, employment, or agency relationship exists between you and Spiffy as a result of this agreement or use of the Services. If any provision of this Agreement is determined to be invalid, all other provisions shall remain in full force and effect. No waiver of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall not be given any legal import. These Terms and Conditions constitute the entire and only agreements between you and Spiffy respect to the Services and products and services provided by Spiffy, and supersede all other communications and agreements with respect to the subject matter thereof. 
<br /><br />
<h5>Privacy</h5>
Your privacy is very important to us, which is why we’ve created a Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of these Terms and Conditions. To read our privacy policy in its entirety, visit www.hanyu.co/privacy.aspx. 
<br /><br />
<h5>International Users </h5>
The Services is controlled, operated and administered by Spiffy from facilities within the United States of America. Spiffy makes no representations that the Services is appropriate for use in other locations. If you access the Services from a location outside the United States of America you are responsible for compliance with all local laws. You agree that you will not use the service in any country or in any manner prohibited by any applicable laws, restrictions or regulations. 
<br /><br />
<h5>Electronic Communications</h5>
Visiting the site or sending emails to Spiffy constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing. 
<br /><br />
<h5>Digital Millennium Copyright Act (DMCA)</h5>
In the event materials are made available to this Site by third parties not within our control, we are under no obligation to, and do not, scan such content for the inclusion of illegal or impermissible content. However, we respect the copyright interests of others. It is our policy not to permit materials known by us to infringe another party’s copyright to remain on the Site. If you believe any materials on the Site infringe a copyright, you should provide us with written notice that at a minimum contains: 
<br />
<ol>
<li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
<li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
<li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
<li>Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
<li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
<li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
</ol>
<br />
All DMCA notices should be sent to our designated agent as follows: 
<br />
<br />Garland Brown 
<br />Weiss Brown 
<br />6263 North Scottsdale Road, Suite 340 
<br />Scottsdale, AZ 85250 
<br /><br />
It is our policy to terminate relationships regarding content with third parties who repeatedly infringe the copyrights of others. 
<br />
<h5>Contact Us</h5>
<br />Spiffy Ventures, L.L.C.
<br />info@SpiffyVentures.com 
<br /><br />
These Terms and Conditions were published on August 31, 2018. 


                        <br /><br /><br /><br />
                    </GridItem>
                </GridContainer>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(TermsPage);
