//react
import React from "react";

//router
import { Link } from "react-router-dom";

//redux
import { connect } from 'react-redux'

//components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";


class LessonCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toLessonPage: false,
            imageURL: null
        };

        if(this.props.lesson.Photo) {
            this.state.imageURL = this.props.lesson.Photo.URL;
        }
        else if(this.props.lesson.Photos) {
            for(var i = 0; i < this.props.lesson.Photos.length; i++) {
                if(this.props.lesson.Photos[i].isThumb) { 
                    this.state.imageURL = this.props.lesson.Photos[i].URL;
                    break;
                }
            }
        }
      }


trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
}

showFooter() {
    if(this.props.lesson.Quarter) {
        return "block";
    } else {
        return "none";
    }
}

lessonPage() {
    return "/lesson/" + this.props.lesson.id;
}

render() {
    const { classes } = this.props;

    return (
        <GridItem xs={12} sm={6} md={4} >
            <Link to={this.lessonPage()}>
            <Card style={{cursor:"pointer"}} >
                
                <div style={{backgroundSize: "cover", height:"200px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                    backgroundImage:`url("${this.state.imageURL}")`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}>
                </div>
                
                <CardBody>
                    <h4 className={classes.cardTitle}>
                        {this.trim(this.props.lesson.Name,31)}
                    </h4>
                    <p className={classes.description} style={{minHeight: "123px" }}>
                        {this.trim(this.props.lesson.Description,500)}
                    </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter} style={{fontSize:"12px",color:"#898989",display:this.showFooter()}}>
                    Quarter: {this.props.lesson.Quarter}, Week: {this.props.lesson.Week}
                </CardFooter>
            </Card>
            </Link>
        </GridItem>
    )}
}

const mapStateToProps = state => {
    return state;
}

LessonCard = connect(mapStateToProps)(LessonCard);
export default withStyles(cardStyle)(LessonCard);
