//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import LessonCard from "components/LessonCard/LessonCard.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
import coverPhoto from "assets/img/coverPhoto.jpg"
import logo from "assets/img/logo.png"

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const blackLogo = require("assets/img/blackLogo.png");
const dashboardRoutes = [];

class LessonsPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        opacity: 1
    };
  }

  render() {
    const { classes, ...rest } = this.props;
    
    return (
      <div>
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',left:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity, transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "dark"
            }}
            {...rest}
          />
          <Parallax filter image={coverPhoto}>
            <div className={classes.container} style={{position: "static", marginBottom:"89px"}}>
              <GridContainer style={{textAlign: "center"}}>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={logo} style={{width:"143px"}} alt="logo" />
                  <h4 style={{color:"#efefef", fontFamily:"Roboto Slab", fontSize:"20px"}}>
                  Learn a new language and <br />discover an entirely new world.
                  </h4>
                  <br />
                  
                </GridItem>
                <br /><br />
              </GridContainer>
            </div>
          </Parallax>
          <div style={{backgroundImage:"linear-gradient(black, white)"}}>
          <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#efefef", marginTop:"-150px"}}>
            <div className={classes.container}>
              
              <div className={classes.section} >

                  <div className={classes.photoDesc}>
                      The Forbidden City. Photo by <a href='https://www.flickr.com/photos/yan-long/12251146054' target='_new'>龙颜大悦</a>.
                  </div>

                  <GridContainer>
                      
                      <GridItem xs={12} sm={12} md={12}>
                          
                          <br />

                          <h3 classes={[classes.title, classes.black]}>Lessons</h3>

                          <GridContainer>
                          {this.props.lessons.map(lesson => (
                              <LessonCard key={lesson.id} lesson={lesson} />
                          ))}
                          </GridContainer>
                          <br /><br /><br /><br />
                      </GridItem>
                      
                  </GridContainer>
              </div>
            </div>
          </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

LessonsPage = connect(mapStateToProps)(LessonsPage);
export default withStyles(landingPageStyle)(LessonsPage);
