//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

// nodejs library that concatenates classes
import classNames from "classnames";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';

import BlogPostCard from "components/BlogPostCard/BlogPostCard.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
import coverPhoto from "assets/img/writing.jpg"
import logo from "assets/img/logo.png";
import blackLogo from "assets/img/blackLogo.png";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

class BlogPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        posts: [],
        opacity: 0
    };

    this.loadCourses = this.loadCourses.bind(this);
    this.loadCourses();
  }

  loadCourses() {
    db.getBlogPosts().then(blogPosts => {
      this.setState({posts: blogPosts, opacity: 1}); 
    });
  }

  render() {
    const { classes, ...rest } = this.props;
    
    return (
      <div>
        <CircularProgress color="inherit" style={{position:'fixed',top:0,bottom:0,left:0,right:0,width:'54px',height:'54px',opacity:0.5,margin:'auto'}} />
        <img src={blackLogo} style={{position:'fixed',bottom:'50px',right:'50px',width:'76px',opacity:0.5}} alt="logo" />
        <div style={{opacity:this.state.opacity,transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "dark"
            }}
            {...rest}
          />
          <Parallax filter image={coverPhoto}>
            <div className={classes.container} style={{position: "static", marginBottom:"89px"}}>
              <GridContainer style={{textAlign: "center"}}>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={logo} style={{width:"169px"}} alt="logo" />
                  <h4 style={{color:"#efefef", fontFamily:"Roboto Slab", fontSize:"20px"}}>
                  Learn a new language and <br />discover an entirely new world.
                  </h4>
                  <br />
                  
                </GridItem>
                <br /><br />
              </GridContainer>
            </div>
          </Parallax>
          
          <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#efefef", marginTop:"-150px"}}>
            <div className={classes.container}>
              
              <div className={classes.section} >

                  <div className={classes.photoDesc}>
                      The Forbidden City. Photo by Alberto Carrasco Casado CC-BY-20.
                  </div>

                  <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                          
                          <br />

                          <h3 classes={[classes.title, classes.black]}>Blog</h3>

                          <GridContainer>
                          {this.state.posts.map((post,index) => (
                              <BlogPostCard key={index} blogPost={post} />
                          ))}
                          </GridContainer>
                          <br /><br /><br /><br />
                      </GridItem>
                  </GridContainer>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

BlogPage = connect(mapStateToProps)(BlogPage);
export default withStyles(landingPageStyle)(BlogPage);
