import React from 'react';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ChartistGraph from 'react-chartist';
import { activityChart } from "../charts.jsx";

class Flashcards extends React.Component {
  render() {

    return (
      <Card style={{marginTop:"20px"}}>
        <CardHeader color="success">
            <ChartistGraph
                className="ct-chart"
                data={this.props.data}
                type="Bar"
                options={{
                    axisX: {
                        showGrid: "false"
                    },
                    low: 0,
                    high: this.props.highValue,
                    chartPadding: {
                        top: 0,
                        right: 25,
                        bottom: 0,
                        left: 0
                    }
                }}
                responsiveOptions={activityChart.responsiveOptions}
                listener={activityChart.animation}
            />
        </CardHeader>
        <CardBody>
            <h4>Practice Deck Exercises</h4>
            <p>
                {this.props.thisWeek} practice deck exercises last 7 days
            </p>
        </CardBody>
    </Card>
    )
  }
}

export default Flashcards;