// ##############################
// // // javascript library for creating charts
// #############################
//var Chartist = require("chartist");

var delays = 80,
durations = 500;

const activityChart = {
    responsiveOptions: [
        [
        "screen and (max-width: 640px)",
        {
            seriesBarDistance: 5,
            axisX: {
                labelInterpolationFnc: function(value) {
                    return value[0];
                }
            }
        }
        ]
    ],
    animation: {
        draw: function(data) {
            if (data.type === "bar" || data.type === 'line') {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    }
};

module.exports = {
    activityChart
};
  