import React from "react";

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import shanghai from "assets/img/beihai.jpg"

class BenefitsSection extends React.Component {
  render() {

    const { classes } = this.props;

    return (
      <div className={classes.section}  >
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 style={{color:"#232323", fontWeight:"600", marginBottom:"34px"}}>Related Posts</h3>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader style={{backgroundImage: `url(${shanghai})`, height:"217px", borderRadius:"10px", backgroundRepeat:"no-repeat", backgroundSize:"cover",boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"}}>

                    </CardHeader>
                    <CardBody style={{textAlign:'justify'}}>
                        <div style={{color:"#498e30",fontSize:"13px",fontWeight:"400",marginTop:"17px"}}>DEVELOPMENT</div>
                        <div style={{fontFamily:"Roboto Slab", fontWeight:"500", fontSize: "21px", marginTop:"17px", marginBottom:"17px"}}>Engineering Excellence</div>
                        <div style={{color:"#696969", fontWeight:"300"}}>
                        It's important to understand the decisions 
                        you are making with regards to engineering excellence, 
                        i.e. the technical quality of the product you're building.
                        &nbsp;<span style={{color:"green"}}>Read More.</span>
                        </div>
                        <br />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader style={{backgroundImage: `url(${shanghai})`, height:"217px", borderRadius:"10px", backgroundRepeat:"no-repeat", backgroundSize:"cover",boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"}}>

                    </CardHeader>
                    <CardBody style={{textAlign:'justify'}}>
                        <div style={{color:"#568bc4",fontSize:"13px",fontWeight:"400",marginTop:"17px"}}>DESIGN</div>
                        <div style={{fontFamily:"Roboto Slab", fontWeight:"500", fontSize: "21px", marginTop:"17px", marginBottom:"17px"}}>Engineering Excellence</div>
                        <div style={{color:"#696969", fontWeight:"300"}}>
                        It's important to understand the decisions 
                        you are making with regards to engineering excellence, 
                        i.e. the technical quality of the product you're building.
                        &nbsp;<span style={{color:"green"}}>Read More.</span>
                        </div>
                        <br />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader style={{backgroundImage: `url(${shanghai})`, height:"217px", borderRadius:"10px", backgroundRepeat:"no-repeat", backgroundSize:"cover",boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"}}>

                    </CardHeader>
                    <CardBody style={{textAlign:'justify'}}>
                        <div style={{color:"#8f3cbc",fontSize:"13px",fontWeight:"400",marginTop:"17px"}}>MARKETING</div>
                        <div style={{fontFamily:"Roboto Slab", fontWeight:"500", fontSize: "21px", marginTop:"17px", marginBottom:"17px"}}>Engineering Excellence</div>
                        <div style={{color:"#696969", fontWeight:"300"}}>
                        It's important to understand the decisions 
                        you are making with regards to engineering excellence, 
                        i.e. the technical quality of the product you're building.
                        &nbsp;<span style={{color:"green"}}>Read More.</span>
                        </div>
                        <br />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    </div>
    );
  }
}

export default withStyles(productStyle)(BenefitsSection);
