//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons


// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";


import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

function location(id, orgId, name, thumbnail, photo, lat, lon, distance, desc, products, directions, history, nature, alerts) {
    this.id = id;
    this.orgId = orgId;
    this.name = name;
    this.thumbnail = thumbnail;
    this.photo = photo;
    this.lat = lat;
    this.lon = lon;
    this.distance = distance;
    this.desc = desc;
    this.products = products;
    this.directions = directions;
    this.history = history;
    this.nature = nature;
    this.alerts = alerts;
  }

  function getDistance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit==="K") { dist = dist * 1.609344 }
    if (unit==="N") { dist = dist * 0.8684 }
    return parseFloat(dist.toFixed(1));
  }

  function compare(a,b) {
    if (a.distance < b.distance)
      return -1;
    if (a.distance > b.distance)
      return 1;
    return 0;
  }
  

class SearchPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        latitude: 20.768151,
        longitude: -156.250125,
        locations: []
    };

    this.mapMoved = this.mapMoved.bind(this);
  }

  componentWillMount() {
    this.getLocations();
  }

  getLocations() {

    db.getLocations().then(snapshot => {
      
        var locations = [];
        
        snapshot.forEach((doc) => {
          
          var x = doc.data();
          var distance = getDistance(this.state.latitude, this.state.longitude, x.Latitude, x.Longitude, 'M');

          var thumbNail;
          var coverPhoto;
          if(x.Photos) {
            for(var i = 0; i < x.Photos.length; i++) {
              if(x.Photos[i].isThumb) {
                thumbNail = x.Photos[i].URL;
              } else if (x.Photos[i].isCover) {
                coverPhoto = x.Photos[i].URL;
              }
            }
          }

          if(!thumbNail)
            thumbNail = x.ThumbnailURL;
          if(!coverPhoto)
            coverPhoto = x.PhotoURL;

          locations.push(new location(doc.id, x.orgId, x.Name, thumbNail, coverPhoto, x.Latitude, x.Longitude, distance, x.Description, x.products, x.Directions, x.History, x.Nature, x.Alerts));
        });
        
        locations.sort(compare);
        this.setState({locations: locations});
      });
  }

  mapMoved(latitude, longitude) {
    
    var locations = [];
    for(var i = 0; i < this.state.locations.length; i++) {
      var location = this.state.locations[i];

      location.distance = getDistance(location.lat, location.lon, latitude, longitude, 'M');
      locations.push(location);
    }

    locations.sort(compare);
    this.setState({locations:locations});
  }

  render() {
    const { classes, ...rest } = this.props;

    //ReactGA.pageview(window.location.pathname + window.location.search);
    
    return (

      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          fixed
          
          {...rest}
        />
        <div className={classNames(classes.main, classes.mainRaisedHigh)} style={{backgroundColor:"#efefef", marginTop:"30%"}}>
          <div className={classes.container}>
            
            <div className={classes.section} >

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        
                        <br />

                        <h3 classes={[classes.title, classes.black]}>Parks Nearby</h3>

                        <GridContainer justify="center">
                        
                        </GridContainer>
                        <br /><br /><br /><br />
                    </GridItem>
                </GridContainer>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

SearchPage = connect(mapStateToProps)(SearchPage);
export default withStyles(landingPageStyle)(SearchPage);
