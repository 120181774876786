//react
import React from "react";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux'

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from '@material-ui/core/Hidden';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//icons
import Bug from "@material-ui/icons/BugReport";
import Feature from "@material-ui/icons/Feedback";
import Recommend from "@material-ui/icons/ThumbUp";
import Other from "@material-ui/icons/RecordVoiceOver";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}
  
class Feedback extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            bug:"",
            feature:"",
            other:""
        };
   
        this.featureChange = this.featureChange.bind(this);
        this.recommendationChange = this.recommendationChange.bind(this);
        this.bugChange = this.bugChange.bind(this);
        this.otherChange = this.otherChange.bind(this);

        this.submitFeedback = this.submitFeedback.bind(this);
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }
    
    submitFeedback() {
        var feedback = {
            bug: this.state.bug,
            feature: this.state.feature,
            recommendation: this.state.recommendation,
            other: this.state.other
        }
        db.updateFeedback(feedback.id, feedback).then(() => {
            this.handleClose("modal");
        });
    }

    bugChange(e) {
        this.setState({bug: e.target.value});
    }

    featureChange(e) {
        this.setState({feature: e.target.value});
    }

    recommendationChange(e) {
        this.setState({recommendation: e.target.value});
    }

    otherChange(e) {
        this.setState({other: e.target.value});
    }

    sendEmail() {
        window.open('mailto:michael@hanyu.co');
    }

    render() {

        const { classes } = this.props;
        
        return (
            <div>
                <div aria-label="Profile" 
                    style={{cursor:"pointer"}}
                    onClick={() => this.handleClickOpen("modal")}>
                    Feedback
                </div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.state.modal}
                    keepMounted
                    fullWidth
                    maxWidth="md"
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    
                    
                    <DialogContent style={{backgroundColor:"#efefef"}}>
                        <GridContainer>
                            <GridItem xs={12} sm={12}  > 
                                <Card>
                                    <CardHeader color="success" >
                                        Feedback
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer style={{marginTop:"17px"}}>
                                            <GridItem xs={12}>
                                                
                                                <CustomInput labelText="Have you had any issues?"
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{ 
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Bug className={classes.inputIconsColor} />
                                                        </InputAdornment>
                                                    )
                                                    }}
                                                    onChange={this.bugChange}
                                                />
                                            </GridItem>
                                            <br />
                                            <GridItem xs={12}>
                                                
                                                <CustomInput labelText="How can we help you better?"
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{ 
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Feature className={classes.inputIconsColor} />
                                                        </InputAdornment>
                                                    )
                                                    }}
                                                    onChange={this.featureChange}
                                                />
                                            </GridItem>
                                            <br />
                                            <GridItem xs={12}>
                                                
                                                <CustomInput labelText="Would you recommend HANYU.CO? Why?"
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{ 
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Recommend className={classes.inputIconsColor} />
                                                        </InputAdornment>
                                                    )
                                                    }}
                                                    onChange={this.recommendationChange}
                                                />
                                            </GridItem>
                                            <br />
                                            <GridItem xs={12}>
                                                
                                                <CustomInput labelText="Any other feedback?"
                                                    formControlProps={{ fullWidth: true }}
                                                    inputProps={{ 
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Other className={classes.inputIconsColor} />
                                                        </InputAdornment>
                                                    )
                                                    }}
                                                    onChange={this.otherChange}
                                                />
                                            </GridItem>
                                        </GridContainer>

                                        <div style={{marginTop:"34px", minHeight:"150px", display:"none"}}>
                                            Thank you for taking time to submit feedback. 
                                            We're always looking for ways to make 汉语.CO better for you!
                                        </div>
                                    </CardBody>
                                    <CardFooter >
                                        
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <Hidden xsDown>
                                <GridItem sm={3} md={3}>
                                    <Button onClick={this.sendEmail} color="transparent">Send Email Instead</Button>
                                    
                                </GridItem>
                            </Hidden>
                            <GridItem xs={12} sm={9} md={9} style={{textAlign: "right"}}>
                                <Button
                                onClick={() => {
                                    // ?
                                    this.handleClose("modal");
                                    }}
                                    color="transparent">
                                Cancel
                                </Button>&nbsp;
                                <Button
                                    onClick={this.submitFeedback}
                                    color="success">
                                Send Feedback
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

Feedback = connect(mapStateToProps)(Feedback);
export default withStyles([modalStyle])(Feedback);
