//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { UserLoggedIn, ClassroomLoaded, OrgLoaded, LessonsLoaded, VideosLoaded } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Face from "@material-ui/icons/Face";

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
//import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import image from "assets/img/bg3.jpg";
import logo from "assets/img/logo.png";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.userNameChange = this.userNameChange.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      userName: "",
      error:null,
      loggedIn: false
    };
  }

  userNameChange(e) {
    this.setState({userName: e.target.value.toLowerCase()});
  }
  
  onUserLoggedIn(id, email) {
    
    db.getUser(id).then(doc => {

      var user = doc.data();

      this.props.onUserLoggedIn({id:id, email: email, firstName: user.FirstName, lastName: user.LastName, classID: user.ClassID, schoolID: user.SchoolID, orgID: user.orgId });
      db.logActivity(id, user.orgId, user.SchoolID, user.ClassID, "Login-App-Alt");

      db.getClass(user.ClassID).then(doc => {

        var classroom = doc.data();
        classroom.id = doc.id;
        this.props.onClassroomLoaded(classroom);

        db.getOrganization(user.orgId).then(doc => {
          var org = doc.data();

          this.props.onOrgLoaded({
            id: doc.id,
            name: org.Name,
            description: org.Description
          });

          db.getLessons(user.ClassID).then(snapshot => {
            
            var lessons = [];
            snapshot.forEach((doc) => {
      
                var lesson = doc.data();
                lesson.id = doc.id;
                if(!lesson.Photos)
                  lesson.Photos = [];
                lessons.push(lesson);
      
                this.props.onLessonsLoaded(lessons);

                db.getVideos().then(snapshot => {
            
                  var videos = [];
                  snapshot.forEach((doc) => {
            
                      var video = doc.data();
                      video.id = doc.id;
                      videos.push(video);
            
                      this.props.onVideosLoaded(videos);

                      // now run!
                      this.setState({loggedIn: true});
                  });
                });
            });
          });
        });
      });
    });
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    auth.signOut().then(function() {
      console.log('signed out');
    });
  }

  onSubmit = (event) => {
    
    event.preventDefault();

    document.getElementById('divErrors').style.display='none';

    db.getUserByUserName(this.state.userName).then(user => {

        auth.doSignInWithEmailAndPassword(user.EmailAddress, user.Password)
        .then(authUser => {

            this.onUserLoggedIn(authUser.user.uid, authUser.user.email);
        
        })
        .catch(error => {

            if(error.code === "auth/user-not-found") {
              this.setState({ error: "This email and password combination was not found." });
            }
            else {
              this.setState({ error: error.message });  
            }
            document.getElementById('divErrors').style.display='block';
        });
    });
  }

  render() {
    const { classes, ...rest } = this.props;

    if (this.state.loggedIn === true) {
      return <Redirect to='/lessons' />
    }

    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Login</h4>{/*}
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <i className={"fab fa-twitter"} />
                        </Button>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <i className={"fab fa-facebook"} />
                        </Button>
                        <Button
                          justIcon
                          href="#pablo"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <i className={"fab fa-linkedin"} />
                        </Button>
                      </div>*/}
                    </CardHeader>
                    {/*<p className={classes.divider}>Or Be Classical</p>*/}
                    <CardBody>
                      {/*
                      <CustomInput
                        labelText="First Name..."
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          endAdornment: (
                            <InputAdornment position="end">
                              <People className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      */}
                      <CustomInput
                        labelText="User Name..."
                        id="userName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "userName",
                          
                          endAdornment: (
                            <InputAdornment position="end">
                              <Face className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.userNameChange}
                      />
                    </CardBody>
                    <div id='divErrors' className={classes.divider} style={{display: 'none'}}>
                      {this.state.error}
                    </div>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" type="submit">
                        Login
                      </Button>
                    </CardFooter>
                    
                  </form>
                </Card>
              </GridItem>
              <GridItem xs={12} style={{textAlign:"center"}}>
                <img src={logo} style={{width:"100px"}} alt="logo" />
              </GridItem>
            </GridContainer>
          </div>
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn, onClassroomLoaded: ClassroomLoaded, onOrgLoaded: OrgLoaded, 
    onLessonsLoaded: LessonsLoaded, onVideosLoaded: VideosLoaded }, dispatch);  
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(loginPageStyle)(LoginPage);
