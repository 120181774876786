import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

//
import Parser from 'html-react-parser';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";


import team1 from "assets/img/oconchobhair.jpg";


class BodyTextSection extends React.Component {

  BodyText() {
    if(this.props.blogPost.Body)
      return Parser(this.props.blogPost.Body);
    else 
      return "";
  }


  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRounded,
      classes.imgFluid
    );

    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={false} sm={false} md={2}>&nbsp;</GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <div style={{color:"black", textAlign:"left"}}>
                <h3 style={{fontFamily:'Roboto Slab',fontWeight:800}}>{this.props.blogPost.Title}</h3>
                <i>Published on {this.props.blogPost.PublishDate}</i>
                <div style={{marginTop:"20px", marginBottom:"43px", fontWeight:300}}>
                  {this.BodyText()}
                </div>
                <hr />
              </div>
            </GridItem>
            <GridItem xs={false} sm={false} md={2}>&nbsp;</GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={false} sm={false} md={2}>&nbsp;</GridItem>
            <GridItem xs={4} sm={4} md={2} style={{textAlign:"right"}}>
                <img src={team1} alt="..." className={imageClasses}  />
            </GridItem>
            <GridItem xs={8} sm={8} md={6}>
            <div style={{color:"black", textAlign:"left"}}>
                <div style={{fontFamily:'Roboto Slab',fontSize:"19px",fontWeight:800}}>Michael O'Connor</div>
                <div style={{color:"#696969", fontSize:"13px", lineHeight:"19px", marginTop:"4px"}}>
                    Founder of HANYU.CO<br />
                    <span style={{color:"maroon"}}>michael@hanyu.co</span>
                </div>
            </div>
            </GridItem>
            <GridItem xs={false} sm={false} md={2}>&nbsp;</GridItem>
        </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(BodyTextSection);
