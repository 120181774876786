//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
//import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Play from "@material-ui/icons/PlayCircleFilled";
import Detail from "@material-ui/icons/MoreHoriz";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

  class VocabularyCell extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        vocabulary: props.vocabulary,
        radicals: [],
        decomp: []
      };
      
      this.playAudio = this.playAudio.bind(this);
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);

      //this.setState({})

      this.setState({radicals:[]}, () => {
        if(this.props.vocabulary.Radicals)
            this.setState({radicals: this.props.vocabulary.Radicals});
      });

      this.setState({decomp:[]}, () => {
        if(this.props.decomp)
          this.setState({decomp: this.props.decomp});
      })
      
      this.getStrokeAnimation();
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    playAudio(url) {
        if(!url)
            url = this.props.audioURL;

        this.audio = new Audio(url);
        this.audio.play();
    }

    showRadicals() {
        if (this.state.radicals.length > 0) 
          return "block";
        else
          return "none"; 
    }

    showWordDecomp() {
        if (this.state.decomp.length > 0) 
            return "block";
        else
            return "none";
    }

    getStrokeAnimation() {

        console.log('getStrokeAnimation()');

        const script = document.createElement("script");

        var js = "var target = document.getElementById('" + this.props.vocabulary.id + "'); ";
        js = js + "target.innerHTML = '';";

        for(var i = 0; i < this.props.vocabulary.Simplified.length; i++) {
            js = js + "var d" + i + " = document.createElement('span');";
            js = js + "var w" + i + " = HanziWriter.create(d" + i + ", '" + this.props.vocabulary.Simplified[i] + "', { width: 89, height: 89, padding: 0, showCharacter: false });";    
            //js = js + "d" + i + ".hideCharacter();"
            js = js + "target.appendChild(d" + i + ");";    
        }
        
        if(this.props.vocabulary.Simplified.length === 1) 
            js = js + "w0.loopCharacterAnimation();"; 
        else if(this.props.vocabulary.Simplified.length === 2) 
            js = js + "w0.animateCharacter({ onComplete: function() {w1.animateCharacter()}});";
        else if(this.props.vocabulary.Simplified.length === 3)  
            js = js + "w0.animateCharacter({ onComplete: function() {w1.animateCharacter( { onComplete: function() {w2.animateCharacter()} } )}});";
        else if(this.props.vocabulary.Simplified.length === 4)  
            js = js + "w0.animateCharacter({ onComplete: function() {w1.animateCharacter({ onComplete: function() {w2.animateCharacter({ function() {onComplete: w3.animateCharacter() }}}) }) });";

        script.innerHTML = js;
        document.body.appendChild(script);
    }

    getDefinitionsTable() {
        
        if(this.state.modal) {

            const { classes } = this.props;

            return <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>

                    </TableCell>
                    <TableCell>
                        Pinyin
                    </TableCell>
                    <TableCell>
                        English
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.vocabulary.Definitions.map((value,index) => (
                <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableActions}>
                        <Tooltip
                            id="tooltip-top"
                            title="Play Vocabulary"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton
                                aria-label="Play"
                                onClick={() => this.playAudio()}>
                                <Play className={ classes.tableActionButtonIcon + " " + classes.edit } />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {value.Pinyin}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {value.English}
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
        }
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          
          <IconButton
            aria-label="Play"
            onClick={() => this.playAudio()}>
            <Play
                className={
                    classes.tableActionButtonIcon + " " + classes.edit
                }
            />
          </IconButton>
          <IconButton
            aria-label="More" 
            onClick={() => this.handleClickOpen("modal")}>
            <Detail className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.modalTitle}><div id={this.props.vocabulary.id} ></div></h2>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
                <GridContainer>
                    <GridItem xs={12}>
                        <h3>Definitions</h3>
                        {this.getDefinitionsTable()}
                    </GridItem>

                    <GridItem xs={12} style={{display:this.showRadicals()}}>
                        <br />
                        <h3>Radicals</h3>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Simplified
                                    </TableCell>
                                    <TableCell>
                                        Pinyin
                                    </TableCell>
                                    <TableCell>
                                        English
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.radicals.map(value => (
                                <TableRow key={value.KangxiNo} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>
                                    {value.Simplified}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                    {value.Pinyin}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                    {value.English}
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </GridItem>
                    
                    <GridItem xs={12} style={{display:this.showWordDecomp()}}>
                        <br />
                        <h3>Word Decomposition</h3>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell>
                                        Pinyin
                                    </TableCell>
                                    <TableCell>
                                        English
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.decomp.map((value,index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableActions}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Play Vocabulary"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <IconButton
                                            aria-label="Play"
                                            onClick={() => this.playAudio(value.AudioURL)}>
                                            <Play className={ classes.tableActionButtonIcon + " " + classes.edit } />
                                        </IconButton>
                                    </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {value.Pinyin}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {value.English}
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </GridItem>

                </GridContainer>
            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                  <Button
                    onClick={() => {
                        // ?
                        this.handleClose("modal");
                      }}
                    color="success">
                    OK
                  </Button>
            </DialogActions>
          </Dialog>
          
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

VocabularyCell = connect(mapStateToProps)(VocabularyCell);
export default withStyles(modalStyle)(VocabularyCell);