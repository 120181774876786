//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

//components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden from '@material-ui/core/Hidden';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//icons
import Play from "@material-ui/icons/PlayCircleFilled";
import Close from "@material-ui/icons/Close";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbsDown from "@material-ui/icons/ThumbDown";
import Translate from "@material-ui/icons/Translate";
import Hearing from "@material-ui/icons/Hearing";
import VolumeOff from "@material-ui/icons/VolumeOff";
import VolumeOn from "@material-ui/icons/VolumeUp";
import Total from "@material-ui/icons/ShowChart";

//hanyu
import Worksheet from "components/Worksheet/Worksheet.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

function Progress(userID, vocabularyID, definitions) {
  this.UserID = userID;
  this.VocabularyID = vocabularyID;
  this.Definitions = definitions;
  this.modified = Math.round((new Date()).getTime() / 1000);
}

  
class Flashcards extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      vocabulary: [],
      radicals: [],
      userID: 'anon',
      orgID: 'anon',
      schoolID: 'anon',
      classID: 'anon',
      remainingFlashcards: [],
      completedFlashcards: [],
      currentFlashcard: {
        Simplified: "",
        Pinyin: "",
        English: "",
        Radicals:[],
        OtherDefs:[],
        WordDecomp:[],
        SoundsLike:[],
        sessionComplete: false
      },
      previousFlashcard: null,
      previousIndex: 0,
      cardFlipped: false,
      exerciseType: 'reading',
      muteVolume: false,
      baselinePoints: 0,
      pointsDifference: 0,
      incorrect: [],
      wrong: 0,
      unsure: 0,
      right: 0
    };

    if(this.props.user.id)
      this.state.userID = this.props.user.id;
    if(this.props.user.orgId)
      this.state.orgID = this.props.user.orgId;
    if(this.props.user.schoolID)
      this.state.schoolID = this.props.user.schoolID;
    if(this.props.user.classID)
      this.state.classID = this.props.user.classID;
    
    this.playAudio = this.playAudio.bind(this);
    this.getNextFlashcard = this.getNextFlashcard.bind(this);
    this.saveResults = this.saveResults.bind(this);
    this.getTotalConfidence = this.getTotalConfidence.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    this.setState({sessionComplete: false});

    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  openReading(modal) {

    this.setState({
      exerciseType: 'reading', 
      wrong: 0, unsure: 0, right: 0, 
      pointsDifference: 0, baselinePoints: 0,
      vocabulary: [],
      remainingFlashcards: [],
      completedFlashcards: [], 
      incorrect: [], 
      sessionComplete: false, 
      muteVolume: false
    }, () => {

      this.getVocabulary().then(vocabulary => {
        
        this.setState({vocabulary: vocabulary, remainingFlashcards: vocabulary}, () => {

          this.setState({baselinePoints: this.getTotalConfidence(vocabulary)});

          this.getNextFlashcard();
          this.handleClickOpen(modal);

          db.logActivity(this.state.userID, this.state.orgID, this.state.schoolID, this.state.classID, "Flashcards-Reading");

          ReactGA.event({
            category: 'Flashcards',
            action: 'Flashcards-Reading'
          });
        });
      });
    });
  }

  openListening(modal) {


    this.setState({
      exerciseType: 'listening', 
      wrong: 0, unsure: 0, right: 0, 
      pointsDifference: 0, baselinePoints: 0,
      vocabulary: [],
      remainingFlashcards: [],
      completedFlashcards: [], 
      incorrect: [], 
      sessionComplete: false, 
      muteVolume: false
    }, () => {

      this.getVocabulary().then(vocabulary => {

        this.setState({vocabulary: vocabulary, remainingFlashcards: vocabulary }, () => {
  
          this.setState({baselinePoints: this.getTotalConfidence(vocabulary)});
  
          this.getNextFlashcard();
          this.handleClickOpen(modal);
  
          db.logActivity(this.state.userID, this.state.orgID, this.state.schoolID, this.state.classID, "Flashcards-Listening");
  
          ReactGA.event({
            category: 'Flashcards',
            action: 'Flashcards-Listening'
          });
        });
      });    
    })
  }

  compareLastTouched(a,b) {

    var x = a.LastTouched;
    var y = b.LastTouched;

    if(!x) x=0;
    if(!y) y=0;
  
    if (x > y)
      return -1;
    if (x < y)
      return 1;
    return 0;
  }

  getVocabulary() {

    return new Promise((resolve, reject) => {

      //var vocabulary = this.props.vocabulary; //ARGH!

      var vocabulary = [];
      this.props.vocabulary.forEach(item => vocabulary.push(item));

      // come up with a random set
      if(vocabulary.length > 10) {

        var pool = [];
        var flashcards = [];

        // all low confidence vocab goes in the pool
        for(var w = vocabulary.length - 1; w > -1; w--) {
          if(this.state.exerciseType === 'reading') {
            if(vocabulary[w].Progress.ConfidenceReading < 2) {
              pool.push(vocabulary[w]);
              vocabulary.splice(w,1);
            }
          } else if (this.state.exerciseType === 'listening') {
            if(vocabulary[w].Progress.ConfidenceListening < 2) {
              pool.push(vocabulary[w]);
              vocabulary.splice(w,1);
            }
          }
        }

        // if the pool is large then reduce it to the top 50 seen most recently 
        // so as to allow reinforcement

        if(pool.length > 50) {
          pool.sort(this.compareLastTouched);
          pool = pool.splice(0,50);
        }

        // grab a few from what's left
        while(pool.length < 14 && vocabulary.length > 0) {
          var rn1 = Math.floor(Math.random() * vocabulary.length);
          pool.push(vocabulary[rn1]);
          vocabulary.splice(rn1,1);
        }

        // now randomly pull from the weighted pool
        while(flashcards.length < 10 && pool.length > 0) {
          var rn2 = Math.floor(Math.random() * pool.length);
          if(!pool[rn2].WordDecomp) pool[rn2].WordDecomp = []; // make sure this property isn't undefined
          flashcards.push(pool[rn2]);
          pool.splice(rn2,1);  
        }

        vocabulary = flashcards;
      }
      
      // to handle async queries with algolia
      var promises = [];

      // add useful metadata to vocab
      for(var i = 0; i < vocabulary.length; i++) {

        // map radicals if they exist
        if(vocabulary[i].obj.Radicals)
          vocabulary[i].Radicals = vocabulary[i].obj.Radicals;
        else
          vocabulary[i].Radicals = [];

        //setup word decomp
        for(var d=0; d < vocabulary[i].obj.Definitions.length; d++) {
          if(vocabulary[i].obj.Definitions[d].id === vocabulary[i].DefinitionID) {
            if(vocabulary[i].obj.Definitions[d].WordDecomp)
              vocabulary[i].WordDecomp = vocabulary[i].obj.Definitions[d].WordDecomp;
            else
              vocabulary[i].WordDecomp = [];

            break;
          }
        }
        
        //setup to show sounds like
        vocabulary[i].SoundsLike = [];

        //setup to show other definitions 
        vocabulary[i].OtherDefs = [];
        for(var j = 0; j < vocabulary[i].obj.Definitions.length; j++) {
          if(vocabulary[i].obj.Definitions[j].id !== vocabulary[i].DefinitionID)
            vocabulary[i].OtherDefs.push(vocabulary[i].obj.Definitions[j]);

            // and fire queries to find words that sound identical
            var promise = algolia.searchVocabularyByPinyin(vocabulary[i].Pinyin, vocabulary[i].ID);
            promises.push(promise);
        }
      }
      
      // when all algolia query results for sounds-like come back
      Promise.all(promises).then((results) => {
      
        for(var i = 0; i < results.length; i++) {
          for(var j = 0; j < vocabulary.length; j++) {
            if(vocabulary[j].ID === results[i].reference) {

              for(var k = 0; k < results[i].vocabulary.length; k++) {
                
                // vocabulary id should not be the same
                if(vocabulary[j].ID !== results[i].vocabulary[k].ID) {
                  
                  //pinyin should be the same
                  if(vocabulary[j].Pinyin === results[i].vocabulary[k].Pinyin) {

                    var isFound = false; // make sure it's only added once
                    for(var m = 0; m < vocabulary[j].SoundsLike.length; m++) {
                      if(vocabulary[j].SoundsLike[m].DefinitionID === results[i].vocabulary[k].DefinitionID) {
                        isFound = true;
                        break;
                      }
                    }

                    if(!isFound) {
                      vocabulary[j].SoundsLike.push(results[i].vocabulary[k]);
                    }
                  }
                }
              }

              break;
            }
          } 
        }

        console.log(vocabulary)
        resolve( vocabulary );
      });
    });
  }

  getTotalConfidence(vocabulary) {

    var points = 0;
    for(var i = 0; i < vocabulary.length; i++) {
      if(this.state.exerciseType === 'listening') 
        points = points + vocabulary[i].Progress.ConfidenceListening;
      else if (this.state.exerciseType === 'reading')
        points = points + vocabulary[i].Progress.ConfidenceReading;
    }

    return points;
  }

  getNextFlashcard() {

    if(this.state.remainingFlashcards.length > 0) {
      var index = 0;
      if (this.state.remainingFlashcards.length > 1) {
        while (index === this.state.previousIndex )
            index = Math.floor(Math.random() * this.state.remainingFlashcards.length);
      }

      var nextFlashCard = this.state.remainingFlashcards[index];
      if(!nextFlashCard.WordDecomp)
          nextFlashCard.WordDecomp = [];

      this.setState({currentFlashcard: nextFlashCard, previousIndex: index, cardFlipped: false}, () => {
        if(this.state.exerciseType === 'listening') {
          this.playAudio();
        }
      });
      
    } else {
      this.summarizeResults();
      this.saveResults();
    }
  }

  flipCard() {
    if(this.state.cardFlipped) { // show the front

    } else { // show the back
      this.playAudio();
    }

    this.setState({cardFlipped: !this.state.cardFlipped});
  }

  getConfidence(previousScore, result) {

    var newScore = 0;

    if(result === 2) { // got it right
      newScore = previousScore + 1; // getting better
    }
    else if (result === 1) { // wasn't sure
      if(previousScore === 0)
        newScore = 1; 
      else if(previousScore === 3)
        newScore = 2;
    }
    else if (result === 0) { // got it wrong
      newScore = previousScore -1; // they lose a point
    }

    if(newScore < 0) newScore = 0;
    if(newScore > 3) newScore = 3;

    return newScore;
  }

  scoreCard(value) {

    var flashcard = this.state.currentFlashcard;
    var remaining = this.state.remainingFlashcards;
    var completed = this.state.completedFlashcards;

    var definition;
    for(var x = 0; x < flashcard.obj.Definitions.length; x++) {
      if(flashcard.obj.Definitions[x].id === flashcard.DefinitionID) {
        definition = flashcard.obj.Definitions[x];
      }
    }

    // update the confidence
    if(this.state.exerciseType === 'reading') 
      definition.Progress.ConfidenceReading = this.getConfidence(flashcard.Progress.ConfidenceReading, value);
    else 
      definition.Progress.ConfidenceListening = this.getConfidence(flashcard.Progress.ConfidenceListening, value);
    

    // update counters
    if(value === 2)
      this.setState({right: this.state.right + 1});
    else if(value === 1) 
      this.setState({unsure: this.state.unsure + 1});
    else
      this.setState({wrong: this.state.wrong + 1});

    if(value != 2) flashcard.incorrect = true;

    // add the card to completed 
    completed.push(flashcard);

    // remove the card from the deck
    for(var i = 0; i < remaining.length; i++) {
      if(remaining[i].DefinitionID === this.state.currentFlashcard.DefinitionID) {
        remaining.splice(i,1);
        break;
      }
    }

    // update with new state
    this.setState({remainingFlashcards:remaining, completedFlashcards: completed}, () => {
            
      this.getNextFlashcard();
    });
  }

  summarizeResults() {
    
    var points = 0;
    var incorrect = [];

    for(var i = 0; i < this.state.completedFlashcards.length; i++) {

      if(this.state.completedFlashcards[i].incorrect) 
        incorrect.push(this.state.completedFlashcards[i]);

      for(var j = 0; j < this.state.completedFlashcards[i].obj.Definitions.length; j++) {
        var d = this.state.completedFlashcards[i].obj.Definitions[j];
        if(!d.Progress) d.Progress = { ConfidenceListening: 0, ConfidenceReading: 0 };

        if(this.state.exerciseType === 'listening') 
          points = points + d.Progress.ConfidenceListening;
        else if (this.state.exerciseType === 'reading')
          points = points + d.Progress.ConfidenceReading;
      }
    }

    this.setState({sessionComplete: true, pointsDifference: points - this.state.baselinePoints, incorrect: incorrect}, () => {
      
    });
  }

  saveResults() {

    if(this.state.userID !== 'anon') {

      var promises = [];
      var updates = [];

      for(var i = 0; i < this.state.completedFlashcards.length; i++) {
        
        var definitions = [];
        for(var j = 0; j < this.state.completedFlashcards[i].obj.Definitions.length; j++) {
          var d = this.state.completedFlashcards[i].obj.Definitions[j];

          var listening = 0;
          if(d.Progress.ConfidenceListening)
            listening = d.Progress.ConfidenceListening;

          var reading = 0;
          if(d.Progress.ConfidenceReading)
            reading = d.Progress.ConfidenceReading;

          definitions.push({DefinitionID: d.id, ConfidenceReading: reading, ConfidenceListening: listening});
        }

        var progress = new Progress(this.props.user.id, this.state.completedFlashcards[i].ID, definitions);        
        if(this.state.completedFlashcards[i].Progress)
          if(this.state.completedFlashcards[i].Progress.id)
            progress.id = this.state.completedFlashcards[i].Progress.id;

        updates.push(progress);
        
          // wrt ... https://github.com/firebase/firebase-js-sdk/issues/311
        promises.push(db.updateProgress(progress.id, {...progress}));
      }

      Promise.all(promises).then((results) => {

        // so the vocab can reload
        this.props.onComplete(updates); 

        // reset to original in case run again
        this.setState({remainingFlashcards: [], 
          currentFlashcard: {
            Simplified: "",
            Pinyin: "",
            English: "",
            Radicals:[],
            OtherDefs:[],
            WordDecomp:[],
            SoundsLike:[]
          }
        });
      });
    } 
    else 
    {
      // so the vocab can reload
      this.props.onComplete(); 
      
      // reset to original in case run again
      this.setState({remainingFlashcards: [], 
        currentFlashcard: {
          Simplified: "",
          Pinyin: "",
          English: "",
          Radicals:[],
          OtherDefs:[],
          WordDecomp:[],
          SoundsLike:[]
        }
      });
    }
  }

  // all just display show / hide functions
  getVolumeButton() {
    if(this.state.exerciseType === "reading") {
      if(this.state.muteVolume) {
        return <VolumeOff />
      } else {
        return <VolumeOn />
      }
    } else {
      return <div></div>
    }
  }

  toggleMute() {
    this.setState({muteVolume: !this.state.muteVolume});
  }

  playAudio(url) {
    if(!url)
      url = this.state.currentFlashcard.AudioURL;

    this.audio = new Audio(url);
    if(!this.state.muteVolume) 
      this.audio.play();
  
  }

  showFlashcards() {
    if(!this.state.sessionComplete) 
      return "block";
    else
      return "none";
  }

  showChinese() {
    if (this.state.cardFlipped || this.state.exerciseType === 'reading')
      return "block";
    else
      return "none";
  }

  showForReading() {
    if (this.state.exerciseType === 'reading') {
        return "block";
    }
    else
      return "none";
  }

  showForListening() {
    if (this.state.exerciseType === 'listening')
      return "block";
    else
      return "none";
  }

  showFrontOfCard() {
    if (this.state.cardFlipped)
      return "none";
    else
      return "block";
  }

  showBackOfCard() {
    if (this.state.cardFlipped)
      return "block";
    else
      return "none";
  }

  showRadicals() {
    if (this.state.currentFlashcard.Radicals.length > 0 && this.state.cardFlipped) 
      return "block";
    else
      return "none"; 
  }

  showOtherDefs() {
    if (this.state.currentFlashcard.OtherDefs.length > 0 && this.state.cardFlipped)
      return "block";
    else
      return "none";
  }

  showWordDecomp() {
    if(!this.state.currentFlashcard.WordDecomp)
      return "none";

    if (this.state.currentFlashcard.WordDecomp.length > 0 && this.state.cardFlipped) 
      return "block";
    else
      return "none";
  }

  showSoundsLike() {
    if (this.state.currentFlashcard.SoundsLike.length > 0 && this.state.cardFlipped) 
      return "block";
    else
      return "none";
  }

  showSummary() {
    if(this.state.sessionComplete) 
      return "block";
    else
      return "none";
  }

  showWorksheetOffer() {
    if(this.state.incorrect.length > 0) {
      return "block";
    } else {
      return "none";
    }
  }

  render() {

    const { classes } = this.props;

    const pointsDifference = this.state.pointsDifference;
    function TotalConfidenceSummary () {
      
      if(pointsDifference === 1) {
        return <div>You gained 1 point.</div>
      } else if(pointsDifference > 1) {
        return <div>You gained {pointsDifference} points.</div>
      } else if (pointsDifference < 0) {
        return <div>You lost {pointsDifference} points.</div>
      } else if (pointsDifference === 0) {
        return <div>You did not gain or lose any points.</div>
      } else {
        return <div></div>
      }
    }

    return (
      <div>
        <Tooltip
          id="tooltip-top"
          title="Practice Reading"
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            aria-label="Practice"
            onClick={() => this.openReading("modal")}>
            <Translate className={ classes.tableActionButtonIcon + " " + classes.edit } />
            <div style={{fontSize:"12px"}}>Practice</div>
          </Button>
        </Tooltip>
        &nbsp;
        <Tooltip
          id="tooltip-top"
          title="Practice Listening"
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            aria-label="Practice"
            onClick={() => this.openListening("modal")}>
            <Hearing className={ classes.tableActionButtonIcon + " " + classes.edit }/>
            <div style={{fontSize:"12px"}}>Practice</div>
          </Button>
        </Tooltip>

        <Dialog
          classes={{ root: classes.center, paper: classes.modal }}
          open={this.state.modal}
          keepMounted
          fullScreen
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}}
            >

            <GridContainer direction="row">
              <GridItem xs={10} >
                <h3 className={classes.modalTitle}>Flashcards</h3>
              </GridItem>
              <GridItem xs={1} style={{textAlign:"right"}}>
                <Tooltip
                  id="tooltip-top"
                  title="Volume"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                  style={{display:this.showForReading()}}
                >
                  <IconButton  onClick={() => this.toggleMute()}>
                      {this.getVolumeButton()}
                  </IconButton>
                </Tooltip>
              </GridItem>
              <GridItem xs={1} style={{textAlign:"right"}}>
                <Tooltip
                  id="tooltip-top"
                  title="Close"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton aria-label="Close" onClick={() => this.handleClose("modal")}>
                      <Close className={ classes.tableActionButtonIcon + " " + classes.edit } />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef", textAlign:"center"}}>

              <div style={{display:this.showFlashcards()}}>
                <GridContainer>
                  <GridItem xs={12} sm={6} >
                    <Card>
                      <div style={{backgroundSize: "cover", height:"154px", backgroundColor:"#3fa31b", borderTopRightRadius:"6px", borderTopLeftRadius:"6px" }}>
                        <div style={{marginTop:"54px"}}>
                          <div style={{fontSize:"54px", color:"white", display:this.showChinese()}}>{this.state.currentFlashcard.Simplified}</div>

                          <div style={{display:this.showFrontOfCard()}}>
                            <div style={{width:"48px", margin:"auto", marginTop:"17px", display:this.showForListening()}}>
                            <IconButton aria-label="Play" onClick={() => this.playAudio()} >
                              <Play className={ classes.tableActionButtonIcon + " " + classes.edit } style={{fontSize:"43px", color:"white"}} />
                            </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>

                      <CardBody style={{minHeight:"206px"}}>
                        <div style={{display:this.showFrontOfCard()}}>
                            
                        </div>
                        <div style={{display:this.showBackOfCard()}}>
                          <div style={{marginTop:"54px", marginBottom:"17px", fontSize:"21px", color:"green"}}>{this.state.currentFlashcard.Pinyin}</div>
                          <div style={{marginBottom:"17px", fontSize:"21px", color:"black"}}>{this.state.currentFlashcard.English}</div>
                          <div style={{width:"48px", margin: "auto"}}>
                            <IconButton aria-label="Play" onClick={() => this.playAudio()}>
                              <Play className={ classes.tableActionButtonIcon + " " + classes.edit } style={{fontSize:"43px"}} />
                            </IconButton>
                          </div>
                        </div>
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>                      
                    
                    <GridContainer>
                      <GridItem sm={12} md={12} style={{display:this.showFrontOfCard()}}>
                        <Button
                            style={{minWidth:"200px"}}
                            onClick={() => this.flipCard()} >
                          <Translate
                                className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                }
                            />Flip 
                        </Button>
                      </GridItem>
                      <Hidden smUp>
                        <GridItem xs={12} style={{textAlign:"center", display:this.showBackOfCard()}}>
                          <Tooltip
                            id="tooltip-top"
                            title="Wrong"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Wrong"
                                onClick={() => this.scoreCard(0)}>
                                <ThumbsDown className={ classes.tableActionButtonIcon + " " + classes.edit }/>
                              </IconButton>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Not Sure"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            >
                            <IconButton
                              aria-label="Not Sure"
                              onClick={() => this.scoreCard(1)}>
                              <ThumbsUpDown className={ classes.tableActionButtonIcon + " " + classes.edit} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Correct"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            >
                            <IconButton
                              aria-label="Correct"
                              onClick={() => this.scoreCard(2)}>
                              <ThumbsUp className={ classes.tableActionButtonIcon + " " + classes.edit } />
                            </IconButton>  
                          </Tooltip>
                        </GridItem>
                      </Hidden>
                      <Hidden xsDown>
                        <GridItem sm={12} md={12} style={{display:this.showBackOfCard()}}>
                          <Button
                              aria-label="Wrong"
                              onClick={() => this.scoreCard(0)}>
                              <ThumbsDown
                                  className={
                                      classes.tableActionButtonIcon + " " + classes.edit
                                  }
                              />Wrong
                            </Button>
                            &nbsp;
                            <Button
                              aria-label="Not Sure"
                              onClick={() => this.scoreCard(1)}>
                              <ThumbsUpDown
                                  className={
                                      classes.tableActionButtonIcon + " " + classes.edit
                                  }
                              />Not Sure
                            </Button>
                            &nbsp;
                            <Button
                              aria-label="Correct"
                              color="success"
                              onClick={() => this.scoreCard(2)}>
                              <ThumbsUp
                                  className={
                                      classes.tableActionButtonIcon + " " + classes.edit
                                  }
                              />Correct
                            </Button>  
                        </GridItem>
                      </Hidden>
                    </GridContainer>
                  
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Card style={{display:this.showRadicals(), transition:"opacity 0.6s" }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{backgroundSize: "cover", backgroundColor:"gray", borderTopRightRadius:"6px", borderTopLeftRadius:"6px" }}>
                            <div style={{fontSize:"21px", color:"white", padding:"10px", marginLeft:"10px", textAlign:"left"}}>Radicals</div>
                          </div>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                  Simplified
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Pinyin
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    English
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.currentFlashcard.Radicals.map(value => (
                                <TableRow key={value.KangxiNo} className={classes.tableRow}>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", width:"23px", padding:"0px"}}>
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Play Vocabulary"
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <IconButton
                                        aria-label="Play"
                                        onClick={() => this.playAudio(value.AudioURL)}>
                                        <Play
                                            className={
                                                classes.tableActionButtonIcon + " " + classes.edit
                                            }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", width:"69px", padding:"3px"}}>
                                    {value.Simplified}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Pinyin}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"12px", padding:"3px", paddingLeft:"6px", lineHeight:"16px"}}>
                                    {value.English}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                        </GridItem>
                      </GridContainer>
                    </Card>

                    <Card style={{display:this.showWordDecomp(), transition:"opacity 0.6s"}}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div style={{backgroundSize: "cover", backgroundColor:"gray", borderTopRightRadius:"6px", borderTopLeftRadius:"6px" }}>
                          <div style={{fontSize:"21px", color:"white", padding:"10px", marginLeft:"10px", textAlign:"left"}}>Word Decomposition</div>
                        </div>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                    
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Simplified
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Pinyin
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    English
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.currentFlashcard.WordDecomp.map((value,index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", width:"23px", padding:"0px"}}>
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Play Vocabulary"
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <IconButton
                                        aria-label="Play"
                                        onClick={() => this.playAudio(value.AudioURL)}>
                                        <Play
                                            className={
                                                classes.tableActionButtonIcon + " " + classes.edit
                                            }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Simplified}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Pinyin}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"12px", padding:"3px", paddingLeft:"6px", lineHeight:"16px"}}>
                                    {value.English}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                        </GridItem>
                      </GridContainer>
                    </Card>

                    <Card style={{display:this.showOtherDefs(), transition:"opacity 0.6s"}}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div style={{backgroundSize: "cover", backgroundColor:"gray", borderTopRightRadius:"6px", borderTopLeftRadius:"6px" }}>
                          <div style={{fontSize:"21px", color:"white", padding:"10px", marginLeft:"10px", textAlign:"left"}}>Other Definitions</div>
                        </div>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Pinyin
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    English
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.currentFlashcard.OtherDefs.map(value => (
                                <TableRow key={value.id} className={classes.tableRow}>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", width:"23px", padding:"0px"}}>
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Play Vocabulary"
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <IconButton
                                        aria-label="Play"
                                        onClick={() => this.playAudio(value.AudioURL)}>
                                        <Play
                                            className={
                                                classes.tableActionButtonIcon + " " + classes.edit
                                            }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Pinyin}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"12px", padding:"3px", paddingLeft:"6px", lineHeight:"16px"}}>
                                    {value.English}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                        </GridItem>
                      </GridContainer>
                    </Card>

                    <Card style={{display:this.showSoundsLike(), transition:"opacity 0.6s"}}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div style={{backgroundSize: "cover", backgroundColor:"gray", borderTopRightRadius:"6px", borderTopLeftRadius:"6px" }}>
                          <div style={{fontSize:"21px", color:"white", padding:"10px", marginLeft:"10px", textAlign:"left"}}>Sounds Like</div>
                        </div>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                    
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Simplified
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    Pinyin
                                </TableCell>
                                <TableCell style={{textAlign:"left", padding:"3px"}}>
                                    English
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.currentFlashcard.SoundsLike.map((value,index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", width:"23px", padding:"0px"}}>
                                    <Tooltip
                                      id="tooltip-top"
                                      title="Play Vocabulary"
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <IconButton
                                        aria-label="Play"
                                        onClick={() => this.playAudio(value.AudioURL)}>
                                        <Play
                                            className={
                                                classes.tableActionButtonIcon + " " + classes.edit
                                            }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Simplified}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"13px", padding:"3px"}}>
                                    {value.Pinyin}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{fontSize:"12px", padding:"3px", paddingLeft:"6px", lineHeight:"16px"}}>
                                    {value.English}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          
                        </GridItem>
                      </GridContainer>
                    </Card>

                  </GridItem>           
                </GridContainer>

                <Hidden xsDown>
                  <div style={{fontSize:"13px", color:"#898989", position:"fixed", left:20, bottom:20}}>
                    Flashcards remaining: {this.state.remainingFlashcards.length}
                  </div>
                </Hidden>
              </div>
              <div style={{display:this.showSummary()}}>
                <h3>Session Summary</h3>
                <GridContainer >
                  <GridItem xs={12} sm={6} md={3} > 
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <ThumbsDown style={{color:"white"}} />
                            </CardIcon>
                            <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Wrong</p>
                            <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                {this.state.wrong}
                            </h3>
                        </CardHeader>
                        <CardFooter >
                            <div className={classes.stats}>
                                First response
                            </div>
                        </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3} > 
                      <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <ThumbsUpDown style={{color:"white"}} />
                            </CardIcon>
                            <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Unsure</p>
                            <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                {this.state.unsure}
                            </h3>
                        </CardHeader>
                        <CardFooter >
                            <div className={classes.stats}>
                              First response
                            </div>
                        </CardFooter>
                      </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3} > 
                      <Card>
                          <CardHeader color="success" stats icon>
                              <CardIcon color="success">
                                  <ThumbsUp style={{color:"white"}} />
                              </CardIcon>
                              <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Correct</p>
                              <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                  {this.state.right}
                              </h3>
                          </CardHeader>
                          <CardFooter >
                              <div className={classes.stats}>
                                First response
                              </div>
                          </CardFooter>
                      </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3} > 
                      <Card>
                          <CardHeader color="gray" stats icon>
                              <CardIcon color="gray">
                                  <Total style={{color:"white"}} />
                              </CardIcon>
                              <p className={classes.cardCategory} style={{color:"#545454", marginTop:"10px"}}>Points</p>
                              <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                  {this.state.pointsDifference}
                              </h3>
                          </CardHeader>
                          <CardFooter >
                              <div className={classes.stats}>
                                <TotalConfidenceSummary />
                              </div>
                          </CardFooter>
                      </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} style={{display:this.showWorksheetOffer()}}>
                    <Card>
                      <CardBody>
                        <br />
                        Would you like to print a practice worksheet with the 
                        vocabulary you weren't confident of? Practice writing 
                        characters can help you remember them.<br /><br />
                        <Worksheet vocabulary={this.state.incorrect} />
                        <br />
                      </CardBody>
                    </Card>
                  </GridItem >
                </GridContainer>
              </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
  
const mapStateToProps = state => {
  return state;
}

Flashcards = connect(mapStateToProps)(Flashcards);
export default withStyles(modalStyle)(Flashcards);