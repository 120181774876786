//react
import React from "react";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia/index.js';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx"
import classNames from "classnames";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomSelect from "components/Select/Select.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import VocabularyCell from "components/VocabularyRow/VocabularyRow.jsx";

//hanyu
import Flashcards from "components/Flashcards/Flashcards.jsx";

//icons
import Translate from "@material-ui/icons/Translate";
import Hearing from "@material-ui/icons/Hearing";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbsDown from "@material-ui/icons/ThumbDown";

//images
import image from "assets/img/coverPhoto.jpg";
import logo from "assets/img/blackLogo.png"

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

var startTime = new Date().getTime();
var totalVocabularyItems = 0;

class HSKPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        opacity: 1,
        filtered: [],
        hskLevel: 1,
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        level6: [],
        confidence: { level1: {}, level2: {}, level3: {}, level4: {}, level5: {}, },
        isLoading: true,
        sortBy: 'pinyin',
    };

    this.calculateProgress = this.calculateProgress.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.getVocabulary = this.getVocabulary.bind(this);
  }

  componentDidMount() {
    //this.dbUpdate();
    this.updateHSKLevel(1);  
    //this.cleanup();


    console.log('startTime: ' + startTime);
  }

  
  dbUpdate(index) {

    db.getVocabularyByPage().then(vocabulary => {
      
      
      totalVocabularyItems = totalVocabularyItems + vocabulary.length;

      console.log('reading: ' + totalVocabularyItems);

      var promises = [];
      vocabulary.forEach(item => {
        item.hskLevels = [];
        item.Definitions.forEach(definition => {

          if(definition.HSK) {
            if(definition.HSK > 0) {
     
              var isFound = false;
              for(var h = 0; h < item.hskLevels.length; h++) {
                if(definition.HSK === item.hskLevels[h]) {
                  isFound = true;
                  break;
                }
              }
              if(!isFound) item.hskLevels.push(definition.HSK);

            }
          }
        });

        if(item.hskLevels.length > 0) 
          promises.push(db.updateVocabulary(item.id, item));

        console.log(item);
      })

      Promise.all(promises).then(() => {

        if(vocabulary.length > 0 && totalVocabularyItems < 100) {
          this.dbUpdate(vocabulary[vocabulary.length-1].id);
        } else {
          console.log('done')
        }
      });
    });
  }

  updateHSKLevel(value) {

    this.setState({isLoading: true, hskLevel: value});

    this.getVocabulary(value).then(() => {

      var filtered;
      if( value === 1)
        filtered = this.state.level1;
      else if (value === 2)
        filtered = this.state.level2;
      else if (value === 3)
        filtered = this.state.level3;
      else if (value === 4)
        filtered = this.state.level4;
      else if (value === 5)
        filtered = this.state.level5;
      else if (value === 6)
        filtered = this.state.level6;
      
      this.setState({filtered: filtered, isLoading: false});

      this.calculateProgress();

    });
  }

  getVocabulary(level) {

    return new Promise((resolve, reject) => {

      if( (level === 1 && this.state.level2.length === 0) ||
          (level === 2 && this.state.level3.length === 0) ||
          (level === 3 && this.state.level4.length === 0) ||
          (level === 4 && this.state.level5.length === 0) ||
          (level === 5 && this.state.level6.length === 0) 
      ) {

        //db.getVocabularyByHSKLevel(level).then(vocabulary => {
        algolia.getVocabularyByHSK(level).then(vocabulary => {
        
          this.getProgress(vocabulary).then(vocabulary => {

            vocabulary.sort((x,y)=>x.Pinyin.localeCompare(y.Pinyin, 'zh-CN'))

            if(level === 1) {
              this.setState({level1: vocabulary}, resolve());
            } else if (level === 2) {
              this.setState({level2: vocabulary}, resolve());
            } else if (level === 3) {
              this.setState({level3: vocabulary}, resolve());
            } else if (level === 4) {
              this.setState({level4: vocabulary}, resolve());
            } else if (level === 5) {
              this.setState({level5: vocabulary}, resolve());
            }
          });
        })
      } else resolve();
    })
  }

  getProgress(vocabulary) {

    var uid = this.props.user.id;

    return new Promise(function(resolve, reject) {

      var promises = [];
      vocabulary.forEach(item => {
        promises.push(db.getProgressByUserVocabulary(uid, item.ID));
        item.LastTouched = 0;
      });

      Promise.all(promises).then((dbResults) => {
        
        dbResults.forEach(result => {
         
          if(result) {

            for(var i = 0; i < vocabulary.length; i++) {

              if(vocabulary[i].ID === result.VocabularyID) {
              
                //************* */
                vocabulary[i].LastTouched = result.modified;
                
                for(var j = 0; j < result.Definitions.length; j++) {

                  // display progress properties
                  if(vocabulary[i].DefinitionID === result.Definitions[j].DefinitionID) 
                    vocabulary[i].Progress = result.Definitions[j];   
                    
                  vocabulary[i].Progress.id = result.id;
                  
                  // obj properties for all defs in vocab so progress can be saved
                  for(var k = 0; k < vocabulary[i].obj.Definitions.length; k++) {
                    if(vocabulary[i].obj.Definitions[k].id === result.Definitions[j].DefinitionID) {
                      vocabulary[i].obj.Definitions[k].Progress = {
                        ConfidenceListening: result.Definitions[j].ConfidenceListening,
                        ConfidenceReading: result.Definitions[j].ConfidenceReading
                      }
                    }
                  }
                }
                //************* */

                //break; //i
              }
            }
          } 
        });
        
        resolve(vocabulary);
      });
    });
  }

  progressUpdated(progress) {

    // probably have to update vocab in hsk levels and the current view

    var vocabulary = this.state.filtered;
        
    progress.forEach(p => {

      for(var i = 0; i < vocabulary.length; i++) {

        if(vocabulary[i].ID === p.VocabularyID) {

          vocabulary[i].LastTouched = p.modified;
          
          for(var j = 0; j < p.Definitions.length; j++) {
  
            // display progress properties
            if(vocabulary[i].DefinitionID === p.Definitions[j].DefinitionID) 
              vocabulary[i].Progress = p.Definitions[j];   
              
            if(p.id)
              vocabulary[i].Progress.id = p.id;
            
            // obj properties for all defs in vocab so progress can be saved
            for(var k = 0; k < vocabulary[i].obj.Definitions.length; k++) {
              if(vocabulary[i].obj.Definitions[k].id === p.Definitions[j].DefinitionID) {

                vocabulary[i].obj.Definitions[k].Progress = {
                  ConfidenceListening: p.Definitions[j].ConfidenceListening,
                  ConfidenceReading: p.Definitions[j].ConfidenceReading
                }

              }
            }
          }
          
          //break; //i
        }
      }
    })

    this.setState({filtered: vocabulary}, () => { 
      var duration = new Date().getTime() - startTime;
      console.log('duration (progressUpdated): ' + duration);
      

      //this.calculateProgress() 

      clearTimeout(this.calculateProgress);
      setTimeout(this.calculateProgress,4300);
    
    });
  }

  updateConfidenceReading(value) {

    var definitions = [];

    for(var j = 0; j < value.obj.Definitions.length; j++) {
      
      var d = value.obj.Definitions[j];
      
      var listening = 0;
      if(d.Progress)
        if(d.Progress.ConfidenceListening)
          listening = d.Progress.ConfidenceListening;

      var reading = 0;
      if(d.Progress)
        if(d.Progress.ConfidenceReading)
          reading = d.Progress.ConfidenceReading;

      if(value.obj.Definitions[j].id === value.DefinitionID) {

        if(reading < 3)
          reading = reading + 1;
        else 
          reading = 0;
      }

      definitions.push({DefinitionID: d.id, ConfidenceReading: reading, ConfidenceListening: listening});
    }
    
    var progress = {
      UserID: this.props.user.id,
      VocabularyID: value.ID,
      Definitions: definitions,
      modified: Math.round((new Date()).getTime() / 1000)
    }

    if(value.Progress)
      if(value.Progress.id)
        progress.id = value.Progress.id;

    startTime = new Date().getTime();
    db.updateProgress(progress.id, progress).then(() => {
        
      var duration = new Date().getTime() - startTime;
      console.log('duration:  (db update)' + duration)

      startTime = new Date().getTime();
      this.progressUpdated([progress]);
    });
  }

  updateConfidenceListening(value) {
    var definitions = [];

    for(var j = 0; j < value.obj.Definitions.length; j++) {
      
      var d = value.obj.Definitions[j];

      var listening = 0;
      if(d.Progress)
        if(d.Progress.ConfidenceListening)
          listening = d.Progress.ConfidenceListening;

      var reading = 0;
      if(d.Progress)
        if(d.Progress.ConfidenceReading)
          reading = d.Progress.ConfidenceReading;

      if(value.obj.Definitions[j].id === value.DefinitionID) {

        if(listening < 3)
          listening = listening + 1;
        else 
          listening = 0;
      }

      definitions.push({DefinitionID: d.id, ConfidenceReading: reading, ConfidenceListening: listening});
    }

    var progress = {
      UserID: this.props.user.id,
      VocabularyID: value.ID,
      Definitions: definitions,
      modified: Math.round((new Date()).getTime() / 1000)
    }

    if(value.Progress)
      if(value.Progress.id)
        progress.id = value.Progress.id;

    startTime = new Date().getTime();
    db.updateProgress(progress.id, progress).then(() => {
      var duration = new Date().getTime() - startTime;
      console.log('duration:  (db update)' + duration)

      startTime = new Date().getTime();
      this.progressUpdated([progress]);
    });
  }

  getConfidenceIcon(confidence) {
    
    if(confidence === 3) {
      return <div style={{color:"#3fa31b", marginTop:"10px" }}><ThumbsUp style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 2) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.5 }}><ThumbsUpDown style={{fontSize: "21px"}} /></div>;
    } else if (confidence === 1) {
      return <div style={{color:"#3fa31b", marginTop:"10px", opacity:0.2 }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    } else {
      return <div style={{color:"#eeeeee", marginTop:"10px" }}><ThumbsDown style={{fontSize: "21px"}} /></div>;
    }
  }

  sortVocabulary(sortBy) {

    var vocabulary = this.state.filtered;

    if(sortBy === this.state.sortBy) {// then reverse sort
      
      var v = [];
      for(var i = 0; i < vocabulary.length; i++)
        v.unshift(vocabulary[i]);

      vocabulary = v;
    }
    else if(sortBy === 'english')
      vocabulary.sort(this.compareEnglish);
    else if(sortBy === 'pinyin') {
      //vocabulary.sort(this.comparePinyin);
      console.log('yo')
      vocabulary.sort((x,y)=>x.Pinyin.localeCompare(y.Pinyin, 'zh-CN'))
    }
    else if(sortBy === 'reading')
      vocabulary.sort(this.compareConfidenceReading);
    else if(sortBy === 'listening')
      vocabulary.sort(this.compareConfidenceListening);

    this.setState({filtered:vocabulary, sortBy: sortBy});
  }

  calculateProgress() {
   
    startTime = new Date().getTime();

    var confidence = {
      level1: {reading: 0, listening: 0, max: this.state.level1.length * 6},
      level2: {reading: 0, listening: 0, max: this.state.level2.length * 6},
      level3: {reading: 0, listening: 0, max: this.state.level3.length * 6},
      level4: {reading: 0, listening: 0, max: this.state.level4.length * 6},
      level5: {reading: 0, listening: 0, max: this.state.level5.length * 6}
    }

    this.state.level1.forEach(vocabItem => {
      confidence.level1.reading = confidence.level1.reading + vocabItem.Progress.ConfidenceReading;
      confidence.level1.listening = confidence.level1.listening + vocabItem.Progress.ConfidenceListening;
    })

    this.state.level2.forEach(vocabItem => {
      confidence.level2.reading = confidence.level2.reading + vocabItem.Progress.ConfidenceReading;
      confidence.level2.listening = confidence.level2.listening + vocabItem.Progress.ConfidenceListening;
    })

    this.state.level3.forEach(vocabItem => {
      confidence.level3.reading = confidence.level3.reading + vocabItem.Progress.ConfidenceReading;
      confidence.level3.listening = confidence.level3.listening + vocabItem.Progress.ConfidenceListening;
    })

    this.state.level4.forEach(vocabItem => {
      confidence.level4.reading = confidence.level4.reading + vocabItem.Progress.ConfidenceReading;
      confidence.level4.listening = confidence.level4.listening + vocabItem.Progress.ConfidenceListening;
    })

    this.state.level5.forEach(vocabItem => {
      confidence.level5.reading = confidence.level5.reading + vocabItem.Progress.ConfidenceReading;
      confidence.level5.listening = confidence.level5.listening + vocabItem.Progress.ConfidenceListening;
    })

    this.setState({confidence: confidence}, () => {
      var duration = new Date().getTime() - startTime;
      console.log('duration (calculateProgress): ' + duration);
    });
  }

  getReadingScore() {

    if(this.state.hskLevel === 1) 
      return this.state.confidence.level1.reading;
    else if(this.state.hskLevel === 2) 
      return this.state.confidence.level2.reading;
    else if(this.state.hskLevel === 3) 
      return this.state.confidence.level3.reading;
    else if(this.state.hskLevel === 4) 
      return this.state.confidence.level4.reading;
    else if(this.state.hskLevel === 5) 
      return this.state.confidence.level5.reading;
  }

  getReadingPercentage() {

    if(this.state.hskLevel === 1) 
      return <span>{(this.state.confidence.level1.reading / (this.state.confidence.level1.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 2) 
      return <span>{(this.state.confidence.level2.reading / (this.state.confidence.level2.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 3) 
      return <span>{(this.state.confidence.level3.reading / (this.state.confidence.level3.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 4) 
      return <span>{(this.state.confidence.level4.reading / (this.state.confidence.level4.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 5) 
      return <span>{(this.state.confidence.level5.reading / (this.state.confidence.level5.max / 2) * 100).toFixed(1)} %</span>;
  }

  getListeningScore() {

    if(this.state.hskLevel === 1) 
      return this.state.confidence.level1.listening;
    else if(this.state.hskLevel === 2) 
      return this.state.confidence.level2.listening;
    else if(this.state.hskLevel === 3) 
      return this.state.confidence.level3.listening;
    else if(this.state.hskLevel === 4) 
      return this.state.confidence.level4.listening;
    else if(this.state.hskLevel === 5) 
      return this.state.confidence.level5.listening;
  }

  getListeningPercentage() {

    if(this.state.hskLevel === 1) 
      return <span>{(this.state.confidence.level1.listening / (this.state.confidence.level1.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 2) 
      return <span>{(this.state.confidence.level2.listening / (this.state.confidence.level2.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 3) 
      return <span>{(this.state.confidence.level3.listening / (this.state.confidence.level3.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 4) 
      return <span>{(this.state.confidence.level4.listening / (this.state.confidence.level4.max / 2) * 100).toFixed(1)} %</span>;
    else if(this.state.hskLevel === 5) 
      return <span>{(this.state.confidence.level5.listening / (this.state.confidence.level5.max / 2) * 100).toFixed(1)} %</span>;
  }

  getMaxScore() {

    if(this.state.hskLevel === 1) 
      return this.state.confidence.level1.max;
    else if(this.state.hskLevel === 2) 
      return this.state.confidence.level2.max;
    else if(this.state.hskLevel === 3) 
      return this.state.confidence.level3.max;
    else if(this.state.hskLevel === 4) 
      return this.state.confidence.level4.max;
    else if(this.state.hskLevel === 5) 
      return this.state.confidence.level5.max;
  }

  compareConfidenceListening(a,b) {

    var aConfidence = a.Progress.ConfidenceListening;
    var bConfidence = b.Progress.ConfidenceListening;
  
    if (aConfidence < bConfidence)
      return -1;
    if (aConfidence > bConfidence)
      return 1;
    return 0;
  }

  compareConfidenceReading(a,b) {

    var aConfidence = a.Progress.ConfidenceReading;
    var bConfidence = b.Progress.ConfidenceReading;
  
    if (aConfidence < bConfidence)
      return -1;
    if (aConfidence > bConfidence)
      return 1;
    return 0;
  }

  compareEnglish(a,b) {

    var x = a.English.toLowerCase();
    var y = b.English.toLowerCase();
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  comparePinyin(a,b) {

    var aPinyin = a.Pinyin;
    var bPinyin = b.Pinyin;
  
    if (aPinyin < bPinyin)
      return -1;
    if (aPinyin > bPinyin)
      return 1;
    return 0;
  }

  showLoading() {
    if(this.state.isLoading)
      return "block";
    else 
      return "none";
  }

  showResults() {
    if(!this.state.isLoading)
      return "block";
    else 
      return "none";
  }

  trim(value, maxLength) {
    if(value) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    } 
  }

  render() {
    const { classes, ...rest } = this.props;
    
    return (
      <div>
        <img src={logo} style={{position:'fixed',bottom:'50px',right:'50px',width:'76px',opacity:0.5}} alt="logo" />

        <div style={{opacity:this.state.opacity, transition:"opacity 1s"}}>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand="HANYU.CO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "dark"
            }}
            {...rest}
          />
          
          <div
            className={classes.pageHeader}
            style={{
              backgroundImage: "url(" + image + ")",
              backgroundSize: "cover",
              backgroundPosition: "top center",
              position: 'fixed', top: 0, bottom: 0, left: 0, right: 0
            }}
          >
          </div>

          <div className={classNames(classes.main)} style={{backgroundColor:"#00000066"}}>
            <div className={classes.container}  style={{paddingTop:"100px"}} >
              <div className={classes.section} >
                  
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        
                        <Card style={{minHeight:"1000px"}}>
                          <CardBody>
                            <GridContainer>

                              <GridItem xs={12} sm={6}>
                                <h3 classes={[classes.title]} >HSK Vocabulary</h3>
                              </GridItem>

                              <GridItem xs={12} sm={6}>
                                <div style={{textAlign:"right", display:this.showResults()}}>
                                  <div style={{display:"inline-block"}}>
                                    <Translate style={{color:"maroon", fontSize:"17px"}} />
                                  </div>
                                  <div style={{display:"inline-block", marginLeft:"3px"}}>
                                    <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                      {this.getReadingPercentage()}
                                    </h3>
                                  </div>
                                  <div style={{display:"inline-block", marginLeft:"20px"}}>
                                    <Hearing style={{color:"maroon", fontSize:"16px"}} />
                                  </div>
                                  <div style={{display:"inline-block", marginLeft:"3px"}}>
                                    <h3 className={classes.cardTitle} style={{color:"#000000", fontSize:"24px"}}>
                                      {this.getListeningPercentage()}
                                    </h3>
                                  </div>
                                </div>
                              </GridItem>


                              <GridItem xs={6} sm={4} >
                                <CustomSelect
                                  labelText="HSK Level"
                                  value={this.state.hskLevel}
                                  inputProps={{
                                      placeholder: "HSK Level"
                                  }}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  onChange={(e) => this.updateHSKLevel(e.target.value)}
                                  options={[
                                    { key: 1, label: "Level 1"},
                                    { key: 2, label: "Level 2"},
                                    { key: 3, label: "Level 3"},
                                    { key: 4, label: "Level 4"},
                                    { key: 5, label: "Level 5"},
                                    { key: 6, label: "Level 6"},
                                  ]}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={8} style={{textAlign:"right", marginTop:"20px"}}>
                                <Flashcards className={classes.tableActionButtonIcon + " " + classes.edit}
                                  vocabulary={this.state.filtered} onComplete={ (e) => this.progressUpdated(e) }
                                />
                              </GridItem>

                            </GridContainer>

                            <br />

                            <div style={{display:this.showLoading(), textAlign:"center", marginBottom:"50px"}}>
                              <CircularProgress color="inherit" style={{width:'54px',height:'54px',opacity:0.5,margin:'auto'}} />
                            </div>


                            <div style={{display:this.showResults()}}>

                              <Table >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell style={{padding:"3px", cursor: "pointer"}} onClick={() => this.sortVocabulary('pinyin')} >
                                        Simplified
                                    </TableCell>
                                    <TableCell style={{padding:"3px", display:"none"}}>
                                        Traditional
                                    </TableCell>
                                    <TableCell style={{padding:"3px", cursor: "pointer"}} onClick={() => this.sortVocabulary('english')}>
                                        Pinyin / English
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      <Tooltip placement='left' title='Confidence Reading' >
                                        <Translate style={{fontSize:"17px", marginTop:"16px", cursor:"pointer"}} onClick={() => this.sortVocabulary('reading')}/>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell style={{width:"24px"}}>
                                      <Tooltip placement='left' title='Confidence Listening'>
                                        <Hearing style={{fontSize:"17px", marginTop:"16px", cursor:"pointer"}} onClick={() => this.sortVocabulary('listening')} />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                  <TableBody>
                                  {this.state.filtered.map((value,index) => (
                                      <TableRow key={index} className={classes.tableRow}>
                                          <TableCell className={classes.tableActions} style={{padding:"0px"}}>
                                            <Tooltip
                                                id="tooltip-top"
                                                title="Play Vocabulary"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                              <VocabularyCell vocabulary={value.obj} decomp={value.WordDecomp} audioURL={value.AudioURL} />
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"3px", fontSize:"24px"}}>
                                            {value.Simplified}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"3px", color:"#545454"}}>
                                        <div style={{color:"#3fa31b", marginBottom:"-6px"}}>
                                            {value.Pinyin}
                                        </div>
                                        {this.trim(value.English,76)}
                                        </TableCell>
                                        <TableCell style={{width:"24px"}}>
                                          <div onClick={() => this.updateConfidenceReading(value)} style={{cursor:"pointer"}}>
                                            {this.getConfidenceIcon(value.Progress.ConfidenceReading)}
                                          </div>
                                        </TableCell>
                                        <TableCell style={{width:"24px"}}>
                                          <div onClick={() => this.updateConfidenceListening(value)} style={{cursor:"pointer"}}>
                                          {this.getConfidenceIcon(value.Progress.ConfidenceListening)}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                  ))}
                                  </TableBody> 
                              </Table>
                            </div>
                          </CardBody>
                          <CardFooter>
                            <div className={classes.photoBlurb}>
                              The Forbidden City. Photo by <a href='https://www.flickr.com/photos/yan-long/12251146054' target='_new' style={{textDecoration:'none'}}>龙颜大悦</a>.
                            </div>
                          </CardFooter>
                        </Card>
                    </GridItem>
                  </GridContainer>
                  <br /><br />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

HSKPage = connect(mapStateToProps)(HSKPage);
export default withStyles(landingPageStyle)(HSKPage);
