
// course
export const CLASSROOM_LOADED = 'CLASSROOM_LOADED';

export function ClassroomLoaded(obj) {
  return {
    type: CLASSROOM_LOADED,
    classroom: obj
  }
}

// course
export const COURSE_SELECTED = 'COURSE_SELECTED';

export function CourseSelected(obj) {
  return {
    type: COURSE_SELECTED,
    course: obj
  }
}

// lesson
export const LESSONS_LOADED = 'LESSON_LOADED';

export function LessonsLoaded(lessons) {
  return {
    type: LESSONS_LOADED,
    lessons: lessons
  }
}

// org
export const ORG_LOADED = 'ORG_LOADED';

export function OrgLoaded(orgObj) {
  return {
    type: ORG_LOADED,
    org: orgObj
  }
}

// user
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';

export function UserLoggedIn(userObj) {
  return {
    type: USER_LOGGEDIN,
    user: userObj
  }
}

export function UserLoggedOut() {
  return {
    type: USER_LOGGEDOUT,
    user: null
  }
}

// videos
export const VIDEOS_LOADED = 'VIDEOS_LOADED';

export function VideosLoaded(videos) {
  return {
    type: VIDEOS_LOADED,
    videos: videos
  }
}