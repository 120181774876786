import React from "react";

//firebase
//import { auth } from 'assets/firebase';
import { db } from 'assets/firebase';

// styles
import withStyles from "@material-ui/core/styles/withStyles";
import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
import Person from "@material-ui/icons/Person";
import Camera from "@material-ui/icons/PhotoCamera";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";

//images
import dg2 from "assets/img/hongKongCityscape.jpg";

var fullName;
var emailAddress;

class WorkSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedUp: false
    }

    this.subscribe = this.subscribe.bind(this);
  }

  subscribe() {
    fullName = document.getElementById('fullName').value;
    emailAddress = document.getElementById('emailAddress').value;
    
    var id;
    db.updateLead(id, { 
      fullName: fullName,
      emailAddress: emailAddress,
      consumer: true
    }).then(doc => {
      this.setState({signedUp: true})
    }); 
  }

  displaySignup() {
    if(this.state.signedUp)
      return "none";
    else
      return "block";
  }

  displayThanks() {
    if(this.state.signedUp)
      return "block";
    else
      return "none";
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section} style={{ position: "relative", backgroundImage: `url(${dg2})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
        <div className={classes.container}>
            <GridContainer>
              <GridItem xs={false} sm={1} md={1}>
                &nbsp;
              </GridItem>
              <GridItem xs={12} sm={5} md={4}>
                <Card className={classes.card1}>
                  <CardHeader
                    
                    color="gray"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Learn More</h4>
                  </CardHeader>
                  <CardBody style={{display:this.displayThanks(), minHeight:"180px"}}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <br />
                        You are signed up! Thank you so much for giving
                        us the opportunity to tell you more about HANYU.CO!
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardBody style={{display:this.displaySignup()}}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <br />
                        Sign up to get language tips and 汉语.CO updates.
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          id="fullName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person className={classes.icon} />
                              </InputAdornment>
                            ),
                            placeholder: "Your Full Name..."
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          id="emailAddress"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Mail className={classes.icon} />
                              </InputAdornment>
                            ),
                            placeholder: "Your Email..."
                          }}
                          
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween} style={{display:this.displaySignup()}}>
                    <Button color="gray" className={classes.pullRight} onClick={this.subscribe}>
                      Sign Up
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
              
              <GridItem xs={12} sm={5} md={6}>
                {/*<h2 className={classes.title} style={{color:"white"}}>Getting Started</h2>*/}
                <h5 className={classes.description} style={{color:"white"}}>
                  
                  <div style={{textAlign:"left",marginLeft:"17px",marginTop:"17px"}}>
                    
                  </div> 
                </h5>
              </GridItem>
            </GridContainer>
        </div>
        <div style={{position:"absolute", right:12, bottom: 3}} ><Tooltip placement='left' title='This photo of Hong Kong was taken by David Iliff, CC-BY-3.0.'><Camera style={{color:"#898989"}} /></Tooltip></div>
      </div>
    );
  }
}

export default withStyles(workStyle)(WorkSection);
