//react
import React from "react";

//devTools
import PropTypes from "prop-types";

//styles
import classNames from "classnames";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";


class CustomSelect extends React.Component {


  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      success,
      onChange,
      value,
      options
    } = this.props;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });

    return (
      <FormControl
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
      >
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {inputProps.placeholder}
          </InputLabel>
        ) : null}
        
        <Select
          classes={{
              root: marginTop,
              disabled: classes.disabled
          }}
          id={id}
          value={value}
          inputProps={inputProps}
          onChange={onChange}
          >
          {options.map(value => (
              <MenuItem key={value.key} value={value.key}>{value.label}</MenuItem>
          ))}
        </Select>

      </FormControl>
    );
  }
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomSelect);
