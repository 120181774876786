import React from "react";

//react router
import { Redirect } from "react-router-dom";

//styles
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";
import BenefitsSection from "./Sections/BenefitsSection.jsx";
//import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
//import DashboardSection from "./Sections/DashboardSection.jsx";

//icons
import Camera from "@material-ui/icons/PhotoCamera";

import coverPhoto from "assets/img/greatWall2.jpg"
//import coverPhoto from "assets/img/library.jpg"
//import coverPhoto from "assets/img/hangzhou.jpg"
//import coverPhoto from "assets/img/forbiddenCity.jpg"
//import coverPhoto from "assets/img/forbiddenCity3.jpg"
//import coverPhoto from "assets/img/opera.jpg"
//import coverPhoto from "assets/img/beihai.jpg"
//import coverPhoto from "assets/img/hongKongTemple.jpg"
//import coverPhoto from "assets/img/boyAtDoor.jpg"
//import coverPhoto from "assets/img/templeOfHeaven.jpg"
//import coverPhoto from "assets/img/greatWall.jpg"
//import coverPhoto from "assets/img/bg.jpg"
//import coverPhoto from "assets/img/birdsNest.jpg"
//import coverPhoto from "assets/img/calligraphyStudent.jpg"
import whiteLogo from "assets/img/logo.png"
import blackLogo from "assets/img/logoBlackSmall.png"

import strip1 from "assets/img/landingStrip1.jpg"
import strip2 from "assets/img/landingStrip2.jpg"
import strip3 from "assets/img/landingStrip4.jpg"
import strip4 from "assets/img/landingStrip3.jpg"
import strip5 from "assets/img/landingStrip5.jpg"

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const dashboardRoutes = [];

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toSignupPage: false
    };
    this.getSignupPage = this.getSignupPage.bind(this);
  }

  getSignupPage() {
    this.setState({toSignupPage: true});
  }

  render() {
    const { classes, ...rest } = this.props;

    if (this.state.toSignupPage === true) {
      return <Redirect to='/signup' />
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="HANYU.CO"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 150,
            color: "white"
          }}
          {...rest}
        />
        <div style={{backgroundImage:"linear-gradient(black, white)"}}>
          <Parallax filter image={coverPhoto}>
            <div className={classes.container} >
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} style={{padding:"34px", marginTop:"-210px"}}>
                  <img src={whiteLogo} style={{width:"123px", marginBottom:"-17px", display:"none"}} alt="logo" />
                  <h2 className={classes.title} style={{fontFamily:"Roboto Slab", fontWeight:600}}>For Mandarin Chinese Language Learners</h2>
                  <h4 style={{color:"white"}}>
                    <Button color="success" onClick={this.getSignupPage}>GET STARTED</Button>
                  </h4>
                </GridItem>  
              </GridContainer>
            </div>
          </Parallax>
          
          <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:"-180px"}}>
            <div className={classes.container}>
              <div className={classes.section} >
                  <div className={classes.photoDesc}>
                  The Great Wall at Juyongguan. <a href="https://www.flickr.com/photos/seeminglee/14631682261/in/photolist-n1r6Sh-4nwMzB-4nACsY-4nww2i-4nAsvA-4nx4JM-4nBeXQ-4nBftW-4nB1SG-4nwzja-jtuhTa-o9qGh9-ohXcnP-ofLk6p-o32tv9-4ogTwS-c2mi15-4VGFCD-HTeQM-4nABrL-4nB3MU-4nB6m" target="_new" style={{textDecoration:"none", color:"#aaaaaa"}}>Photo by See-ming Lee., CC-BY-2.0</a>.
                  </div>

                  <ProductSection />
              </div>  
              
            </div>

            <div className={classes.section} style={{ position: "relative", backgroundImage: `url(${strip2})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover", minHeight:"400px", color:"white" }}>
              <GridContainer>
                <GridItem xs={2} sm={5} md={5} >
                  &nbsp;
                </GridItem>
                <GridItem xs={10} sm={7} md={7} style={{padding:"69px"}}>
                  <h2 style={{fontFamily:"Roboto Slab", fontWeight:600}}>Videos</h2>
                  Our videos not only help you learn Chinese but they bring China to life, allowing 
                  you a glimpse of Chinese people, places culture and daily life. Watch videos we've made 
                  - many of them on the streets of Beijing and Shanghai - as well as videos we've 
                  gathered and formatted to make learning easy. 
                </GridItem>
              </GridContainer>
              <div style={{position:"absolute", left:12, bottom: 3}} ><Tooltip placement='right' title='This photo from the Forbidden City was taken by Drolexandre, CC-BY-3.0.'><Camera style={{color:"#545454"}} /></Tooltip></div>
            </div>
            <div className={classes.section} style={{ position: "relative", backgroundImage: `url(${strip4})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover", minHeight:"400px" }}>
              <GridContainer>
                <GridItem xs={12} sm={9} md={7} style={{padding:"69px"}}>
                  <h2 style={{fontFamily:"Roboto Slab", fontWeight:600}}>Practice Decks & Worksheets</h2>
                  <h5>Customized to the vocabulary you're teaching!</h5>
                  We have practice exercises that help you both read and listen to new vocabulary. 
                  You can also print personalized worksheets to help practice writing!
                  <br /><br />
                  <a href="/sampleWorksheet.pdf" target="_new" style={{textDecoration:"none", color:"maroon"}}>Download a Sample Character Worksheet</a>
                </GridItem>
              </GridContainer>
              <div style={{position:"absolute", right:12, bottom: 3}} ><Tooltip placement='left' title='This photo of a gazebo at the Summer Palace was taken by See-ming Lee, CC-BY-2.0.'><Camera style={{color:"#eeeeee"}} /></Tooltip></div>
            </div>
            <div className={classes.section} style={{ position: "relative", backgroundImage: `url(${strip3})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover", minHeight:"400px", color:"white" }}>
              <GridContainer>
                <GridItem xs={2} sm={5} md={5} >
                  &nbsp;
                </GridItem>
                <GridItem xs={10} sm={7} md={7} style={{padding:"69px", marginTop:"34px"}}>
                  <h2 style={{fontFamily:"Roboto Slab", fontWeight:600}}>Progress Reporting</h2>
                  We keep track of your progress, so you don't have to. See how quickly you're 
                  picking up new vocabulary, how you're doing against HSK test standards and 
                  take advantage of exercises customized to where you're at. 
                </GridItem>
              </GridContainer>
              <div style={{position:"absolute", left:12, bottom: 3}} ><Tooltip placement='right' title='This photo of Hangzhou was taken by Jakob Montrasio, CC-BY-2.0.'><Camera style={{color:"#aaaaaa"}} /></Tooltip></div>
            </div>
            <div className={classes.section} style={{ position:'relative', backgroundImage: `url(${strip1})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover", minHeight:"400px", color:"white" }}>
              <GridContainer>
                <GridItem xs={false} sm={1} md={1} lg={2} >
                  &nbsp;
                </GridItem>
                <GridItem xs={12} sm={10} md={7} style={{padding:"69px"}}>
                  <h2 style={{fontFamily:"Roboto Slab", fontWeight:600}}>Practice Content</h2>
                  We have hundreds of sample dialogs and accompanying vocabulary you can use to practice
                  your reading and listening comprehension. It's all structured into different courses for beginners,
                  intermediate and advanced learners.
                  <br /><br />
                  <a href="/lesson/D2FB2E8F-9FD9-49D3-A971-AB1BDEA2272D" target="_new" style={{textDecoration:"none", color:"#99ddff"}}>See a Sample Lesson</a>
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.section} style={{ position: "relative", backgroundImage: `url(${strip5})`, backgroundRepeat: "no-repeat", backgroundPosition:"center", backgroundSize:"cover", minHeight:"400px", color:"white" }}>
              <GridContainer>
                <GridItem xs={2} sm={5} md={5} >
                  &nbsp;
                </GridItem>
                <GridItem xs={10} sm={7} md={7} style={{padding:"69px", marginTop:"17px"}}>
                  <h2 style={{fontFamily:"Roboto Slab", fontWeight:600}}>Classrooms</h2>
                  We've designed HANYU.CO to be used in schools. Teachers can customize lessons,
                  create practice decks for students, track student progress, share lessons, decks and videos with other teachers and much more.<br /><br />
                  <a href="https://teacher.hanyu.co" target="_new" style={{textDecoration:"none", color:"pink"}}>Learn More</a>
                </GridItem>
              </GridContainer>
              <div style={{position:"absolute", left:12, bottom: 3}} ><Tooltip placement='right' title='This photo of Hangzhou was taken by Jakob Montrasio, CC-BY-2.0.'><Camera style={{color:"#aaaaaa"}} /></Tooltip></div>
            </div>

            
            <div className={classes.container}>
              <BenefitsSection />  
            </div>

            <div style={{textAlign:"center", marginBottom:"54px"}}>
              <Button color="success" onClick={this.getSignupPage}>GET STARTED</Button>
            </div>
            
            <WorkSection />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center", marginTop:"54px", marginBottom:"54px"}}>
                  <img src={blackLogo} style={{width:"134px"}} alt="logo" />
                  <h4 style={{color:"#696969", fontFamily:"Roboto Slab", fontSize:"17px"}}>
                    Learn a new language,<br />discover an entirely new world.
                  </h4>
                </GridItem>
              </GridContainer>
          </div>

        </div>


        <Footer />
        
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
//<BenefitsSection />
//<GeoLocation onSuccess={this.locationFound} />
