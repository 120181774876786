import React from 'react';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ChartistGraph from 'react-chartist';
import { activityChart } from "../charts.jsx";

class HSKProgress extends React.Component {
  render() {


    //console.log('好',this.props.data.series[0]);

    return (
      <Card style={{marginTop:"20px"}}>
        <CardHeader color="gray">
            <ChartistGraph
                className="ct-chart"
                data={this.props.data}
                type="Line"
                options={{
                    axisX: {
                        showGrid: "false"
                    },
                    low: 0,
                    high: 100,
                    chartPadding: {
                        top: 0,
                        right: 25,
                        bottom: 0,
                        left: 0
                    }, 
                }}
                responsiveOptions={activityChart.responsiveOptions}
                listener={activityChart.animation}
            />
        </CardHeader>
        <CardBody>
            <h4>HSK Level {this.props.level}</h4>
            <p>
                {this.props.percentComplete} % complete, ~{this.props.estimates.daysToMaster} days to master
            </p>
        </CardBody>
    </Card>
    )
  }
}

export default HSKProgress;