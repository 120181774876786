
import { auth } from './firebase';

// Sign Up
export const createUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
auth.signInWithEmailAndPassword(email, password);


// Sign out
export const signOut = () => {
  return new Promise(function(resolve, reject) {
    auth.signOut().then(function() {
      console.log('Signed Out');
      resolve(true);
    }, function(error) {
      console.error('Sign Out Error', error);
      resolve(false);
    });
  });
}

// Password Reset
export const sendPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);


// Password Change
export const doPasswordUpdate = (password) =>
auth.currentUser.updatePassword(password);